import { useQuery } from '@tanstack/react-query';
import historicalRentalsApi from '../services/reports/historicalRentals';
import { HistoricalRentalsTracker } from '../modules/Reports/EquipmentUseAndPerformanceReports/utils/types';
import moment from 'moment';

export function useGetHistoricalRentalsQuery() {
  return useQuery<HistoricalRentalsTracker[]>(['historicalRentals'], () => historicalRentalsApi.getHistoricalRentals(), {
    onError(err) {
      console.log(err);
    },
  });
}

export const useExportHistoricalRentals = (data: HistoricalRentalsTracker[]) => {
    const newHistoricalRentalsList = data.map(e => (
        {
            arrivalDate: moment(e.arrivalDate).format('MM/DD/yyyy'),
            capacity: e.capacity,
            eorrRequestedPUD: e.eorrRequestedPUD,
            equipmentId: e.equipmentId,
            equipmentNo: e. equipmentNo,
            erfDate:  moment(e.erfDate).format('MM/DD/yyyy'),
            erfNo: e.erfNo,
            id: e.id,
            locationId: e.locationId,
            make: e.make,
            model: e.model,
            ownershipType: e.ownershipType,
            pickupDate: moment(e.pickupDate).format('MM/DD/yyyy'),
            productFamily: e.productFamily,
            productFamilyId: e.productFamilyId,
            project: e.project
        }
    ));
    return newHistoricalRentalsList;
}