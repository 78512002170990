import PageContainer from '../../../../components/common/PageContainer';

function HEOperatorPage() {
  return (
    <>
      <PageContainer title='Personnel Databases' subTitle='HE Operator Master' showDate>
        HE Operator Master
      </PageContainer>
    </>
  );
}

export default HEOperatorPage;
