import { createColumnHelper } from '@tanstack/react-table';
import RoleActions from './RoleActions';
import RoleIconList from './RoleIconList';
import { Role } from './roles-utils';

const columnHelper = createColumnHelper<Role>();

const roleColumns = [
  columnHelper.accessor('name', {
    id: 'name',
    header: 'Role',
    cell: (row) => row.getValue(),
    enableGlobalFilter: true,
    enableColumnFilter: false,
  }),
  columnHelper.accessor('description', {
    id: 'description',
    header: 'Role Description',
    cell: (row) => row.getValue(),
    enableGlobalFilter: false,
  }),
  columnHelper.accessor('permissions', {
    id: 'permissions',
    header: 'Permissions',
    cell: ({ row }) => <RoleIconList role={row.original} />,
  }),

  columnHelper.display({
    id: 'actions',
    header: 'Action',
    cell: ({ row }) => <RoleActions role={row.original} />,
  }),
];

export default roleColumns;
