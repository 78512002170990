import PageContainer from '../../../../components/common/PageContainer';

function HEOperatorCertificateTypePage() {
  return (
    <>
      <PageContainer
        title='Personnel Databases'
        subTitle='HE Operator Certificate Type Master'
        showDate
      >
        HE Operator Certificate Type Master
      </PageContainer>
    </>
  );
}

export default HEOperatorCertificateTypePage;
