import TextField from '@mui/material/TextField';
import IconButton from '@mui/material/IconButton';
import SearchIcon from '@mui/icons-material/Search';
import ClearIcon from '@mui/icons-material/ClearOutlined';
import InputAdornment from '@mui/material/InputAdornment';
import { Box } from '@mui/system';

type AppSearchFieldProps = {
  searchKeyword: string;
  onSearchKeywordChange: (value: string) => void;
  placeholder?: string;
  id?: string;
  flex?: number;
};

const AppSearchField = ({
  searchKeyword,
  onSearchKeywordChange,
  placeholder = 'Search',
  flex,
  id,
}: AppSearchFieldProps) => {
  return (
    <Box sx={{ flex: flex }}>
      <TextField
        aria-label='search'
        sx={{
          bgcolor: 'white',
          minWidth: '250px',
          // borderColor: 'white',
          // '& fieldset': { borderColor: 'white' },
        }}
        size='small'
        id={id}
        placeholder={placeholder}
        value={searchKeyword}
        onChange={(e) => onSearchKeywordChange(e.target.value)}
        InputProps={{
          sx: { fontSize: '14px' },
          startAdornment: (
            <InputAdornment position='start'>
              <SearchIcon fontSize='small' sx={{ color: '#ccc' }} />
            </InputAdornment>
          ),
          endAdornment: (
            <InputAdornment position='end'>
              {searchKeyword && (
                <IconButton
                  edge='end'
                  onClick={() => onSearchKeywordChange('')}
                  aria-label='clear search query'
                >
                  <ClearIcon fontSize='small' />
                </IconButton>
              )}
            </InputAdornment>
          ),
        }}
      />
    </Box>
  );
};

export default AppSearchField;
