import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import Stack from '@mui/material/Stack';
import Paper from '@mui/material/Paper';
import Box from '@mui/material/Box';
import { ExportButton } from '../../../../components/common/primebmd-ui/DataButtons';
import TablePagination from '@mui/material/TablePagination';
import Typography from '@mui/material/Typography';
import { flexRender } from '@tanstack/react-table';
import {
  AppTable,
  AppTableBody,
  AppTableCell,
  AppTableContainer,
  AppTableHead,
  AppTableRow,
  AppTableSort,
} from '../../../../components/common/app-table/ui';
import AppDateRange from '../../../../components/common/AppDateRange';
import useDefaultTable from '../../../../components/common/app-table/useDefaultTable';
import LoadingIndicator from '../../../../components/common/LoadingIndicator';
import DAIUColumns from './Columns';
import { DAIU } from '../utils/types';
import { useGetDAIUQuery, useFormatGetDAIUQuery } from '../../../../hooks/useDAIU';
import AppSearchField from '../../../../components/common/forms/AppSearchField';
import moment from 'moment';
import { exportDAIUToExcel } from '../utils/exporting';

const DAIUTable = () => {
  const [startDate, setStartDate] = useState<string>('');
  const [endDate, setEndDate] = useState<string>('');
  const daiu = useGetDAIUQuery(startDate, endDate);
  const data: DAIU[] = daiu?.data === undefined ? [] : daiu?.data;
  const formattedDAIUData: DAIU[] = useFormatGetDAIUQuery(data);
  
  const { table, dataCount, searchFilter, setSearchFilter, pageIndex, pageSize } =
  useDefaultTable<Partial<DAIU>>(data, DAIUColumns);

  const emptyText = searchFilter ? 'No data found' : 'No data yet';

  if (daiu?.isLoading) return <LoadingIndicator show />;

  const handleExport = () => {
    exportDAIUToExcel(formattedDAIUData, 'DAIU Report');
  }

  return (
    <>
      <Stack direction="row" justifyContent="space-between" alignItems="center" width="100%">
        <Stack direction='column' mb={4} gap={.75} width="100%">
          <Typography fontSize={14} mt={2} fontWeight={700}>
            Date
          </Typography>
          <Stack direction="row" justifyContent="space-between" width="35%">
            <AppDateRange
              id="date-range"
              startDate={startDate}
              endDate={endDate}
              onDateChange={(s, e) => {
                setStartDate(moment(s).format("YYYY-MM-DD"))
                setEndDate(moment(e).format("YYYY-MM-DD"))
              }}
            />
          </Stack>
      
        </Stack>
        <Stack direction="row" justifyContent="flex-end" mb={2} gap={2}>
          <AppSearchField
            onSearchKeywordChange={setSearchFilter}
            searchKeyword={searchFilter ?? ''}
          />
        <Stack direction="row" gap={.75}>
          <ExportButton onClick={handleExport} />
        </Stack>
      </Stack>
      </Stack>
      <Paper elevation={3}>
        <AppTableContainer>
          <AppTable stickyHeader sx={{ minWidth: '1650px'}}>
            <AppTableHead>
              {table.getHeaderGroups().map((headerGroup) => (
                <AppTableRow key={headerGroup.id}>
                  {headerGroup.headers.map((header) => (
                    <AppTableCell key={header.id} align='center'>
                      <AppTableSort
                        canSort={header.column.getCanSort()}
                        currentSort={header.column.getIsSorted()}
                        onSort={header.column.getToggleSortingHandler()}
                      >
                        {flexRender(header.column.columnDef.header, header.getContext())}
                      </AppTableSort>
                    </AppTableCell>
                  ))}
                </AppTableRow>
              ))}
            </AppTableHead>
            <AppTableBody
              dataCount={dataCount}
              emptyText={emptyText}
            >
              {table.getRowModel().rows.map((row) => (
                     <AppTableRow key={row.id} sx={{ height: '45px' }}>
                  {row.getVisibleCells().map((cell) => (
                    <AppTableCell key={cell.id} align='center'>
                      {flexRender(cell.column.columnDef.cell, cell.getContext())}
                    </AppTableCell>
                  ))}
                </AppTableRow>
              ))}
            </AppTableBody>
          </AppTable>
        </AppTableContainer>
        <Box position='relative'>
          <TablePagination
            sx={{ bgcolor: 'white', borderTop: '1px solid #eee' }}
            component='div'
            count={dataCount}
            page={pageIndex}
            onPageChange={(e, page) => table.setPageIndex(page)}
            rowsPerPage={pageSize}
            onRowsPerPageChange={(e) => table.setPageSize(Number(e.target.value))}
            rowsPerPageOptions={[10, 20, 50, 100]}
          />
        </Box>
      </Paper>
    </>
  );
}

export default DAIUTable;