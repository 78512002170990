import { useEffect } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { Controller, useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import Stack from '@mui/material/Stack';
import Button from '@mui/material/Button';
import AppTimePicker from '../../../../components/common/forms/AppTimePicker';
import AppTextField from '../../../../components/common/forms/AppTextField';
import AppDialog from '../../../../components/common/AppDialog';
import LoadingIndicator from '../../../../components/common/LoadingIndicator';
import { EURActivitylInitialValues, EURActivitySchema } from '../utils/schema';
import { EURActivityFields } from '../utils/types';
import { useCreateEURActivity, useGetEURActivitiesById, useUpdateEURActivity } from '../../../../hooks/useEURActivities';

const EURActivityForm = () => {
  const { id, activityId } = useParams();
  const createEURActivity = useCreateEURActivity();
  const updateEURActivity = useUpdateEURActivity();
  const activityToEdit = useGetEURActivitiesById();
  const pageLink = `/eur/${id}/activity`;
  const navigate = useNavigate();

  const handleClose = () => navigate(pageLink, { replace: true });

  const {
    register,
    handleSubmit,
    control,
    setValue,
    formState: { errors, isValid },
  } = useForm<EURActivityFields>({
    defaultValues: activityToEdit.data! ? activityToEdit.data : EURActivitylInitialValues,
    mode: 'onChange',
    resolver: yupResolver(EURActivitySchema),
  });

  useEffect(() => {
    if (!activityToEdit.data) return;

    const { activity, startTime, endTime } = activityToEdit.data;
    setValue("activity", activity);
    setValue("startTime", startTime);
    setValue("endTime", endTime);

  }, [activityToEdit.data, setValue]);

  const onSubmit = (formData: EURActivityFields) => {
    const newActivity = { ...formData, eurSMRId: id }
    
    if (activityToEdit.data) {
      updateEURActivity.mutate(newActivity);
    } else {
      createEURActivity.mutate(newActivity);
    }
  };

  if (activityId !== undefined) {
    if (updateEURActivity.isLoading || activityToEdit.isLoading) {
      return <LoadingIndicator show />;
    }
  }

  if (createEURActivity.isLoading) {
    return <LoadingIndicator show />;
  }
  
  return (
    <AppDialog 
      open 
      onDialogClose={handleClose} 
      title={activityId ? 'Update Activity' : 'Add Activity'} 
      maxWidth='sm' 
      sx={{textAlign: 'center'}}
    >
      <Stack component='form' height='100%' bgcolor='white' py={3} borderRadius={1}>
        <Stack height='100%' overflow='auto' gap={5}>
          <Stack direction='column' gap={3} width={550} justifyContent='center' margin='auto'>
            <AppTextField
              label='Activity Log'
              required={true}
              error={!!errors.activity}
              helperText={errors.activity?.message}
              {...register('activity')}
            />
            <Controller
              control={control}
              name='startTime'
              render={({ field: { value, onChange } }) => (
                <AppTimePicker
                  name='startTime'
                  value={value}
                  onChange={onChange}
                  required
                  label='Start Time'
                  error={!!errors.startTime}
                  helperText={errors.startTime?.message}
                />
              )}
            />
            <Controller
              control={control}
              name='endTime'
              render={({ field: { value, onChange } }) => (
                <AppTimePicker
                  name='endTime'
                  value={value}
                  onChange={onChange}
                  required
                  label='End Time'
                  error={!!errors.endTime}
                  helperText={errors.endTime?.message}
                />
              )}
            />
            <Stack direction="row" justifyContent="center">
              <Button disabled={!isValid} onClick={handleSubmit(onSubmit)} sx={{ width: 150 }}>
                Save
              </Button>
            </Stack>
          </Stack>
        </Stack>
      </Stack>
    </AppDialog>
  )
}

export default EURActivityForm;