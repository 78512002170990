import PageContainer from '../../../../components/common/PageContainer';

function PMSCodeTaskPartsPage() {
  return (
    <>
      <PageContainer title='Servicing Databases' subTitle='PMS Code + Task + Parts Master' showDate>
        PMS Code + Task + Parts Master
      </PageContainer>
    </>
  );
}

export default PMSCodeTaskPartsPage;
