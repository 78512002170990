import { useQuery } from '@tanstack/react-query';
import { ICPC } from '../modules/Reports/EquipmentCostReports/utils/types';
import cpcAPI from '../services/reports/cpc';

export function useGetCPCQuery() {
  return useQuery<ICPC[]>(['CPCs'], () => cpcAPI.getAllCPC(), {
    onError(err) {
      console.log(err);
    }
  });
}

export const useExportCPC = (data: ICPC[]) => {
  const newCPCList = data.map(c => {
    const dateCompleted = c.status === 'Approved' 
                                ? c.dateApproved 
                                : ( c.status === 'Started' 
                                    ? c.dateStarted 
                                    : ''
                                  );

    return {
      equipmentNo: c.equipmentNo,
      category: c.category,
      productFamily: c.productFamily,
      description: c.description,
      make: c.make,
      model: c.model,
      capacity: c.capacity,
      project: c.project,
      esrNumber: c.esrNumber,
      dateCompleted: dateCompleted,
      warranty: c.warranty === true ? 'Y' : 'N',
      partNumber: c.partNumber,
      system: c.system,
      subSystem: c.subSystem,
      quantity: c.quantity,
      unitCost: c.unitCost,
      totalPartsCost: c.totalPartsCost
    }
  });

  return newCPCList;
}