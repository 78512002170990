import { IEquipmentDeploymentStatus, IEquipmentStatusData } from '../modules/Dashboard/db-utils';
import { apiClient, apiEndpoints } from './apiClient';

export type GetManyReturnType = {
  status: string;
  data: IEquipmentDeploymentStatus[];
};

export type GetManyByStatusReturnType = {
  status: string;
  data: IEquipmentStatusData[];
};

const getEquipmentStatus = async () => {
  const response = await apiClient.get<GetManyReturnType>(apiEndpoints.equipmentStatus);
  return response.data.data;
};

const getOnRentEquipmentStatus = async () => {
  const response = await apiClient.get<GetManyByStatusReturnType>(
    `${apiEndpoints.equipmentStatus}/on-rent`
  );
  return response.data.data;
};

const getAvailableEquipmentStatus = async () => {
  const response = await apiClient.get<GetManyByStatusReturnType>(
    `${apiEndpoints.equipmentStatus}/available`
  );
  return response.data.data;
};

const getOffRentEquipmentStatus = async () => {
  const response = await apiClient.get<GetManyByStatusReturnType>(
    `${apiEndpoints.equipmentStatus}/off-rent`
  );
  return response.data.data;
};

const getInTransitEquipmentStatus = async () => {
  const response = await apiClient.get<GetManyByStatusReturnType>(
    `${apiEndpoints.equipmentStatus}/in-transit`
  );
  return response.data.data;
};

const homeApi = {
  getEquipmentStatus,
  getOnRentEquipmentStatus,
  getAvailableEquipmentStatus,
  getOffRentEquipmentStatus,
  getInTransitEquipmentStatus
};

export default homeApi;