import * as yup from 'yup';
import { 
  ForgotPasswordField,
  ResetPasswordField 
} from './types';

export const ForgotPasswordInitialValues: ForgotPasswordField = {
  email : ''
};

export const ForgotPasswordSchema = yup.object({
  email: yup
    .string()
    .required('Email is required')
    .trim()
    .email('Must be a valid email'),
});

export const ResetPasswordInitialValues: ResetPasswordField = {
  password : '',
  confirmPassword : ''
};

export const ResetPasswordSchema = yup.object({
  password: yup
    .string()
    .required("This field is required.")
    .trim()
    .matches(
      /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.{8,36}$)/,
      "Must follow the guidelines on the right."
    ),
  confirmPassword: yup
    .string()
    .trim()
    .required("This field is required")
    .oneOf([yup.ref("password"), null], "Passwords do not match."),
});