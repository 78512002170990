import { useLocation, useParams } from 'react-router-dom';

export const useGetURLParams = () => {
  const params = useParams();

  return params;
}

export const useGetLocation = () => {
  const location = useLocation();

  return location;
}

export const useEURView = () => {
  const location = useLocation();

  return location.pathname.includes('view');
}