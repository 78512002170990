import { useMutation, useQuery } from '@tanstack/react-query';
import { queryClient } from '../react-query/queryClient';
import { AxiosError } from 'axios';
import { useNavigate, useParams } from 'react-router';
import { useSnackBar } from '../components/ToastProvider';
import { IEUR } from '../modules/MyDashboardsPlusTasks/EUR/utils/types';
import eurSMRApi from '../services/dashboardPlusTasks/eurSMR';

export function useGetEURSMRQuery(startDate?: string, endDate?: string) {
  return useQuery<IEUR[]>(['eurSMRs', startDate, endDate], () => eurSMRApi.getEURSMR(startDate, endDate), {
    onError(err) {
      console.log(err);
    },
  });
}

// export function useGetEURSMRQueryOrderByDate() {
//   return useQuery<IEUR[]>(['eurSMRs'], () => eurSMRApi.getEURSMROrderByDate(), {
//     onError(err) {
//       console.log(err);
//     },
//   });
// }

export function useGetEURSMRByEquipmentAndOperator(equipmentId: string, operatorId: string, date: Date | string) {
  return useQuery<IEUR[]>(['filteredEurSMRs', equipmentId, operatorId], () => eurSMRApi.getEURSMRByEquipmentIdAndOperator(equipmentId, operatorId), {
    enabled: equipmentId.length !== 0 && operatorId.length !== 0 ? true : false,
  });
}

export function useGetEURSMRById() {
  const { id } = useParams<{ id: string}>();

  return useQuery<IEUR>([`eurSMR-${id}`, id], () => eurSMRApi.getEURSMRById(id!), {
    enabled: id ? true : false
  });
}


// export function useGetEURSMRByEURNo(eurNo: string, enabled: boolean) {
//   return useQuery([`eurSMR-${eurNo}`, eurNo], () => eurSMRApi.getEURSMRById(eurNo!), {
//     enabled: enabled
//   });
// }

export function useCreateEURSMR() {
  const { showSnackBar } = useSnackBar();
  const navigate = useNavigate();
  return useMutation((newEURSMRData: Partial<IEUR>) => {
    const formattedEURSMRData = {
      ...newEURSMRData,
      isAcknowledged: false
    }
    return eurSMRApi.createEURSMR(formattedEURSMRData)
  }, {
    onSuccess(data) {
      queryClient.setQueryData<IEUR[]>(['eurSMRs'], oldQueryData => oldQueryData && [
        ...oldQueryData,
        data,
      ]);

      showSnackBar('Successfully Created EUR', 'success');
      navigate('/eur', { replace: true });
    },
    onError(err) {
      const errorData = (err as AxiosError<{ error: string }>).response?.data.error;

      if (err && errorData) {
        showSnackBar(errorData, 'error');
      }
    }
  }) 
}

export function useUpdateEURSMR() {
  const { showSnackBar } = useSnackBar();
  const { id } = useParams<{ id: string }>();
  const navigate = useNavigate();

  return useMutation((newEURSMRData: Partial<IEUR>) => eurSMRApi.updateEURSMR(id!, newEURSMRData), {
    onSuccess() {
      queryClient.invalidateQueries({ queryKey: ['eurSMRs'] }).then(() => {
        showSnackBar('Successfully Updated EUR', 'success');
        navigate('/eur', { replace: true });
      });
    },
    onError(err) {
      const errorData = (err as AxiosError<{ error: string }>).response?.data.error;

      if (err && errorData) {
        showSnackBar(errorData, 'error');
      }
    },
  });
}