import React from 'react';
import { Navigate, Outlet } from 'react-router-dom';
import PageContainer from '../../../components/common/PageContainer';
import { usePermission } from '../../../hooks/usePermissions';

const RolesPage = () => {
  const permission = usePermission('Role');
  if (permission !== undefined && !permission) return <Navigate to='/home' />;

  return (
    <>
      <PageContainer
        title='User Management'
        subTitle='Roles & Permission'
        showDate
      >
        <Outlet />
      </PageContainer>
    </>
  );
};

export default RolesPage;
