import { Button } from '@mui/material';
interface AppDatePickerProps {
  onClick: () => void;
  disabled?: boolean;
}

const AddActivityButton = (props: AppDatePickerProps) => {
  const { onClick, disabled } = props;

  return (
    <Button
      disableElevation
      sx={{
        textTransform: 'capitalize',
        fontSize: '9pt',
        bgcolor: 'primary',
        color: 'white',
        padding: 0.5,
        paddingX: 2.5,
        minWidth: 'auto',
        borderRadius: '3px',
        '&:hover': {
          bgcolor: 'primary',
        },
      }}
      onClick={onClick}
      disabled={disabled}
    >
      Add Activity
    </Button>
  );
};

export default AddActivityButton;