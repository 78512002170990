import { useNavigate, useParams } from 'react-router';
import { useQuery, useMutation } from '@tanstack/react-query';
import { queryClient } from '../react-query/queryClient';
import { useSnackBar } from '../components/ToastProvider';
import { AxiosError } from 'axios';
import { ICertificateTracker, logCertificateFields, IEmailForm } from '../modules/MyDashboardsPlusTasks/CertificateTracker/ct-utils';
import { Equipment } from '../modules/MyDashboardsPlusTasks/EUR/utils/types';
import certificateTrackerApi from '../services/dashboardPlusTasks/equipmentCertificates';
import equipmentApi from '../services/equipments';

export function useGetCertificateTracker() {
  return useQuery<ICertificateTracker[]>(['certificates'], () => certificateTrackerApi.getCertificateStatus(), {
    onError(err) {
      console.log(err);
    }
  }); 
}

export const useGetCertificateTrackerById = () => {
  const { id } = useParams<{ id: string}>();

  return useQuery<ICertificateTracker>([`certificates-${id}`, id], () => certificateTrackerApi.getCertificateStatusById(id!));
}

export function useCreateCertificateTracker() {
  const { showSnackBar } = useSnackBar();
  const navigate = useNavigate();
  
  return useMutation((newCertificateTrackerData: Partial<logCertificateFields>) => certificateTrackerApi.createCertificateStatus(newCertificateTrackerData), {
    onSuccess() {
      queryClient.invalidateQueries({ queryKey: ['certificates'] }).then(() => {
        showSnackBar('Successfully Created Certificate', 'success');
        navigate('/equipment-certificates', { replace: true });
      });
    },
    onError(err) {
      const errorData = (err as AxiosError<{ error: string }>).response?.data.error;

      if (err && errorData) {
        showSnackBar(errorData, 'error');
      }
      // const errorData = (err as AxiosError<{ error: ApiError }>).response?.data.error;

      // if (err && errorData) {
      //   showSnackBar(errorData.message.split(':').slice(1).join(' '), 'error');
      // }
    },
  });
}

export function useUpdateCertificateTracker() {
  const { showSnackBar } = useSnackBar();
  const { id } = useParams<{ id: string }>();
  const navigate = useNavigate();

  return useMutation((newCertificateTrackerData: Partial<logCertificateFields>) => certificateTrackerApi.updateCertificateStatus(id!, newCertificateTrackerData), {
    onSuccess() {
      queryClient.invalidateQueries({ queryKey: ['certificates'] }).then(() => {
        showSnackBar('Successfully Updated Certificate', 'success');
        navigate('/equipment-certificates', { replace: true });
      });
    },
    onError(err) {
      const errorData = (err as AxiosError<{ error: string }>).response?.data.error;

      if (err && errorData) {
        showSnackBar(errorData, 'error');
      }
      // const errorData = (err as AxiosError<{ error: ApiError }>).response?.data.error;

      // if (err && errorData) {
      //   showSnackBar(errorData.message.split(':').slice(1).join(' '), 'error');
      // }
    },
  });
}

export function useGetEquipmentById() {
  const { equipmentId } = useParams<{ equipmentId: string }>();
  return useQuery<Equipment>([`equipments-${equipmentId}`, equipmentId], () => equipmentApi.getEquipmentsById(equipmentId!));
}

export function useSendEmail() {
  const { showSnackBar } = useSnackBar();
  const navigate = useNavigate();
  
  return useMutation((emailForm: IEmailForm) => certificateTrackerApi.sendEmail(emailForm), {
    onSuccess() {
      queryClient.invalidateQueries({ queryKey: ['certificates'] }).then(() => {
        showSnackBar('Successfully Sent Email', 'success');
        navigate('/equipment-certificates', { replace: true });
      });
    },
    onError(err) {
      const errorData = (err as AxiosError<{ error: string }>).response?.data.error;

      if (err && errorData) {
        showSnackBar(errorData, 'error');
      }
      // const errorData = (err as AxiosError<{ error: ApiError }>).response?.data.error;

      // if (err && errorData) {
      //   showSnackBar(errorData.message.split(':').slice(1).join(' '), 'error');
      // }
    },
  });
}