import { parseISO } from 'date-fns';
import format from 'date-fns/format';
import moment from 'moment';
// for formatting dateCreated, dateUpdated, dateUploaded

export const getDateToday = () => format(Date.now(), 'MMM dd, yyyy');

export const toTitleCase = (str: string) => {
  if (str === '' || str === undefined || str === null) return;
  const result = str.replace(/([A-Z])/g, ' $1');
  const finalResult = result.charAt(0).toUpperCase() + result.slice(1);
  return finalResult.trim();
};

export const formatDate = (date: string | Date) => {
  if (!date) return;
  return format(parseISO(date.toString()), 'MMMM dd, yyy');
};

export const formatCurrency = (price: number | string, currency: string = '') =>
  Intl.NumberFormat('en-US', {
    style: 'currency',
    currency: 'PHP',
    minimumFractionDigits: 2,
    maximumFractionDigits: 2,
  })
    .format(typeof price === 'string' ? Number(price) : price)
    .replace('₱', currency + ' ');

export const dateDifference = (date1: string | Date, date2: string| Date) =>
  moment(date1).diff(formatDate(date2), 'days');
