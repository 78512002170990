import { useNavigate } from 'react-router-dom';
import { Controller, useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { useEXFApproval } from '../../../../hooks/useEPFERF';
import { IEXFApproveWtChangeForm } from '../utils/types';
import { EXFApproveWtChangeInitialValue, EXFApproveWtChangeSchema } from '../utils/schema';
import AppDatePicker from '../../../../components/common/forms/AppDatePicker';
import AppTextField from '../../../../components/common/forms/AppTextField';
import AppDialog from '../../../../components/common/AppDialog';
import LoadingIndicator from '../../../../components/common/LoadingIndicator';
import Stack from '@mui/material/Stack';
import Button from '@mui/material/Button';

const ApproveWithChangeForm = () => {
  const navigate = useNavigate();
  const approveEXFWtChange = useEXFApproval();

  const {
    register,
    handleSubmit,
    control,
    formState: { errors, isValid },
  } = useForm<IEXFApproveWtChangeForm>({
    defaultValues: EXFApproveWtChangeInitialValue,
    mode: 'onChange',
    resolver: yupResolver(EXFApproveWtChangeSchema),
  });

  const handleClose = () => navigate('/my-equipment-requests/my-exf', { replace: true });

  const onSubmit = (formData: IEXFApproveWtChangeForm) => {
    approveEXFWtChange.mutate(formData);
  };

  if(approveEXFWtChange.isLoading) return <LoadingIndicator show />;

  return (
    <AppDialog 
      open 
      onDialogClose={handleClose} 
      title='Approve EXF with Change' 
      maxWidth='sm' 
      titleAlignment="center"
    >
      <Stack component='form' height='100%' bgcolor='white' py={3} borderRadius={1}>
        <Stack px={3} height='100%' overflow='auto' gap={5}>
          <Stack direction='column' width="100%" justifyContent='center' margin='auto' gap={3}>
            <Controller
              control={control}
              name='exfEMEndDate'
              render={({ field: { value, onChange } }) => (
                <AppDatePicker
                  name="exfEMEndDate"
                  value={value}
                  onChange={onChange}
                  label="EXF EM End Date"
                  labelPosition="top"
                  labelAlignment="left"
                  labelWidth='100%'
                  fieldWidth= '100%'
                  fontWeight={400}
                  error={!!errors?.exfEMEndDate}
                  helperText={errors?.exfEMEndDate?.message}
                />
              )}
            />
            <AppTextField
              label="EXF EM Remarks"
              labelPosition="top"
              labelAlignment="left"
              labelWidth='100%'
              fieldWidth= '100%'
              fontWeight={400}
              multiline
              rows={3}
              error={!!errors.exfEMRemarks}
              helperText={errors.exfEMRemarks?.message}
              {...register('exfEMRemarks')}
            />
            <Stack direction="row" justifyContent="center" gap={1}>
              <Button 
                variant="contained"
                onClick={handleSubmit(onSubmit)}
                disabled={!isValid} 
                sx={{ width:"100px" }}
              >
                Save
              </Button>
            </Stack>
          </Stack>
        </Stack>
      </Stack>
    </AppDialog>
  );
}

export default ApproveWithChangeForm;