import { createColumnHelper } from '@tanstack/react-table';
import CtStatus from '../../../components/common/CtStatus';
import EmailActionButton from '../../../components/common/EmailActionButton';
import { LogActionButton } from '../../../components/common/primebmd-ui/EditActionButton';
import { ICertificateTracker } from './ct-utils';

const columnHelper = createColumnHelper<ICertificateTracker>();

const ctColumns = [
  columnHelper.accessor('equipmentNo', {
    id: 'equipmentNo',
    header: 'Equipment No.',
    cell: (row) => row.getValue(),
    enableGlobalFilter: true,
    enableColumnFilter: false,
    enableHiding: true,
  }),

  columnHelper.accessor('equipmentType', {
    id: 'equipmentType',
    header: 'Equipment Type',
    cell: (row) => row.getValue(),
    enableGlobalFilter: true,
    enableColumnFilter: false,
    enableHiding: true,
  }),
  // columnHelper.accessor('projectOrLocation', {
  //   id: 'projectOrLocation',
  //   header: 'Project/ Location',
  //   cell: (row) => row.getValue(),
  //   enableGlobalFilter: true,
  //   enableColumnFilter: true,
  //   enableHiding: true,
  // }),
  columnHelper.accessor('certificateName', {
    id: ' certificateName',
    header: 'Certificate Name',
    cell: (row) => row.getValue(),
    enableGlobalFilter: true,
    enableColumnFilter: true,
    enableHiding: false,
  }),
  columnHelper.accessor('validityDate', {
    id: 'daysToExpiration',
    header: 'Days To Expiration',
    cell: (row) => row.getValue() ? <CtStatus type={new Date(row.getValue())} /> : '',
    enableGlobalFilter: true,
    enableColumnFilter: true,
    enableHiding: false,
  }),
  columnHelper.display({
    id: 'actions',
    header: 'Action',
    cell: ({ row }) => {
      const url = row.original.id ? `/equipment-certificates/edit/${row.original.id}` : `/equipment-certificates/new/${row.original.equipmentId}`
      const emailUrl = row.original.id ? `/equipment-certificates/email/${row.original.equipmentId}` : `/equipment-certificates/email/${row.original.equipmentId}`
      return (
        <>
          <LogActionButton route={url} />
          <EmailActionButton editRoute={emailUrl} />
        </>
      )
    },
    enableHiding: false,
  }),
];

export default ctColumns;
