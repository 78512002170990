import axios from 'axios';

// CONSTANTS
export const apiEndpoints = {
  login: 'login',
  users: 'users',
  roles: 'roles',
  assets: 'assets',
  ejo: 'ejo',
  esr: 'esr',
  eurSmr: 'eur-smr',
  eurActivities: 'eur-activities',
  equipment: 'equipment',
  operator: 'operators',
  location: 'locations',
  epfErf: 'erf',
  equipmentStatus: 'equipment-status',
  clc: 'clc',
  cpc: 'cpc',
  resetPassword: 'change-password',
  forgotPassword: 'forgot-password',
  refreshToken: 'refresh',
  daiu: 'daiu',
  efcr: 'efcr',
  certificateTracker: 'equipment-certificate-status',
  operatorLicenses: 'operator-certificate',
  historicalRentals: 'historical-rentals',
  eet: 'eet'

};

// ENV VARIABLES/TOKENS
const API_URL = process.env.REACT_APP_API_URL;
const LOGIN_TOKEN = process.env.REACT_APP_LOGIN_TOKEN;
// const REGISTER_TOKEN = process.env.REACT_APP_REGISTER_TOKEN;
const RESET_PASSWORD_TOKEN = process.env.REACT_APP_CHANGE_PASSWORD_TOKEN;
const FORGOT_TOKEN = process.env.REACT_APP_FORGOT_PASSWORD_TOKEN;
// axios instance
export const apiClient = axios.create({
  baseURL: API_URL,
  headers: {
    Accept: 'application/json',
    'Content-Type': 'application/json',
  },
});

apiClient.interceptors.request.use(
  (config) => {
    const storedToken = localStorage.getItem('accessToken');

    let _config = { ...config };

    if (storedToken) {
      const token = JSON.parse(storedToken);
      _config = {
        ...config,
        headers: {
          Authorization: `Bearer ${token}`,
        },
      };
    }
    return _config;
  },
  async (error) => {
    Promise.reject(error);
  }
);

apiClient.interceptors.response.use(
  (response) => {
    return response;
  },
  async function (error) {
    const originalRequest = error.config;
    if (error.response.status === 403 && !originalRequest._retry) {
      originalRequest._retry = true;
      const access_token = await refreshAccessToken();
      const config = {
        ...error.config,
        headers: {
          Authorization: `Bearer ${access_token}`,
        },
      };

      return apiClient(config);
    }
    return Promise.reject(error);
  }
);

export const refreshAccessToken = async () => {
  const response = await apiClient.get(apiEndpoints.refreshToken);
  return response.data.accessToken;
};

export const loginApiClient = axios.create({
  baseURL: API_URL,
  headers: {
    Authorization: `Bearer ${LOGIN_TOKEN}`,
    Accept: 'application/json',
    'Content-Type': 'application/json',
  },
});

// export const registerApiClient = axios.create({
//   baseURL: API_URL,
//   headers: {
//     Authorization: `Bearer ${REGISTER_TOKEN}`,
//     Accept: 'application/json',
//     'Content-Type': 'application/json',
//   },
// });

export const changePasswordApiClient = axios.create({
  baseURL: API_URL,
  headers: {
    Authorization: `Bearer ${RESET_PASSWORD_TOKEN}`,

    Accept: 'application/json',
    'Content-Type': 'application/json',
  },
});

export const forgotPasswordApiClient = axios.create({
  baseURL: API_URL,
  headers: {
    Authorization: `Bearer ${FORGOT_TOKEN}`,
    Accept: 'application/json',
    'Content-Type': 'application/json',
  },
});