import PageContainer from '../../../../components/common/PageContainer';

function PartsPage() {
  return (
    <>
      <PageContainer title='Servicing Databases' subTitle='Parts Master' showDate>
        Parts Master
      </PageContainer>
    </>
  );
}

export default PartsPage;
