import Box from '@mui/material/Box';
import { Outlet } from 'react-router-dom';

import LayoutContextProvider from '../../components/layout-ui/layoutContext';
import Main from '../../components/layout-ui/Main';
import AppFooter from './AppFooter';
import AppHeader from './AppHeader';
import AppSidebar from './AppSidebar';

const AppContent = () => {
  return (
    <LayoutContextProvider sidebarWidth={270} collapseToWidth={52}>
      <Box display='flex'>
        <AppHeader />
        <AppSidebar />
        <Main>
          <Outlet />
        </Main>
        <AppFooter />
      </Box>
    </LayoutContextProvider>
  );
};

export default AppContent;
