import { Outlet } from 'react-router-dom';

import DeploymentStatusSection from './DeploymentStatusSection';
import DashboardTitle from './DashboardTitle';
import WorkStatusSection from './WorkStatusSection';

const DashboardPage = () => {
  return (
    <>
      <DashboardTitle />
      <DeploymentStatusSection />
      <WorkStatusSection />
      <Outlet />
    </>
  );
};

export default DashboardPage;
