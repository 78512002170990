import { Outlet } from 'react-router-dom';

function DatabaseManagementPage() {
  return (
    <>
      <Outlet />
    </>
  );
}

export default DatabaseManagementPage;
