import type { ReactNode } from 'react';
import {
  createContext,
  useState,
  useMemo,
  useCallback,
  useContext,
} from 'react';

interface LayoutContextValueType {
  sidebarOpen: boolean;
  setSidebarOpen: React.Dispatch<React.SetStateAction<boolean>>;
  toggleSidebar: () => void;
  sidebarWidth: number;
  collapseToWidth: number;
}

const LayoutContext = createContext<LayoutContextValueType | null>(null);

LayoutContext.displayName = 'LayoutContext';

interface LayoutContextProviderProps {
  children: ReactNode;
  sidebarWidth?: number;
  collapseToWidth?: number;
}

export default function LayoutContextProvider(
  props: LayoutContextProviderProps
) {
  const { sidebarWidth, collapseToWidth, children } = props;

  const [sidebarOpen, setSidebarOpen] = useState(true);

  const toggleSidebar = useCallback(() => setSidebarOpen((v) => !v), []);

  const value = useMemo(
    () => ({
      sidebarOpen,
      setSidebarOpen,
      toggleSidebar,
      sidebarWidth: sidebarWidth ? sidebarWidth : 240,
      collapseToWidth: collapseToWidth ? collapseToWidth : 0,
    }),
    [sidebarOpen, sidebarWidth, toggleSidebar, collapseToWidth]
  );

  return (
    <LayoutContext.Provider value={value}>{children}</LayoutContext.Provider>
  );
}

export function useLayout() {
  const context = useContext(LayoutContext);

  if (!context)
    throw new Error(
      `useLayout must only be used inside the LayoutContext Provider.`
    );

  return context;
}
