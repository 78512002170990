import PageContainer from '../../../../components/common/PageContainer';
import EJOPendingTable from './Table';

const EJOPendingPage = () => {
  return (
    <>
      <PageContainer 
        title='My Dashboard + Tasks' 
        subTitle='My EJOs & ESRs'
        submoduleTitle='EJOs Pending Approval'  
        showDate
      >
        <EJOPendingTable />
      </PageContainer>
    </>
  );
}

export default EJOPendingPage;