import PageContainer from '../../../../components/common/PageContainer';

function ServiceTaskPage() {
  return (
    <>
      <PageContainer title='Servicing Databases' subTitle='Service Task Master' showDate>
        Service Task Master
      </PageContainer>
    </>
  );
}

export default ServiceTaskPage;
