import { yupResolver } from '@hookform/resolvers/yup';
import { Button, Divider } from '@mui/material';
import { Stack } from '@mui/system';
import { useEffect } from 'react';
import { Controller, SubmitErrorHandler, SubmitHandler, useForm } from 'react-hook-form';
import { useNavigate, useParams } from 'react-router-dom';
import AppSelect from '../../../components/common/forms/AppSelect';
import AppTextField from '../../../components/common/forms/AppTextField';
import PageContainer from '../../../components/common/PageContainer';
import { appColors, appStyles } from '../../../theme';
import AccountActiveButton from './AccountActiveButton';
import { accountsSchema, accountsFields, userAccountsInitialValues } from './accounts-utils';
import { useCreateUser, useGetUserById, useUpdateUser } from '../../../hooks/useUser';
import LoadingIndicator from '../../../components/common/LoadingIndicator';
import { useSnackBar } from '../../../components/ToastProvider';
import { SelectOptionType } from '../../../components/common/forms/form-types';
import { Role } from '../Roles/roles-utils';
import { useGetRolesQuery } from '../../../hooks/useRoles';

const AccountsForm = () => {
  const { id } = useParams();
  const { showSnackBar } = useSnackBar();
  const roles = useGetRolesQuery();
  const currentUser = useGetUserById();
  const updateUser = useUpdateUser();
  const createUser = useCreateUser();
  const navigate = useNavigate();
  const {
    register,
    control,
    handleSubmit,
    setValue,
    formState: { errors },
  } = useForm<accountsFields>({
    defaultValues: currentUser.data! ? currentUser.data : userAccountsInitialValues,
    resolver: yupResolver(accountsSchema),
    mode: 'onChange',
  });

  const superadmin = currentUser?.data?.roleId! === 'ROLE::81a4a958-2a28-4a77-ab3c-6e4622793baf';
  const rolesData: Role[] = roles?.data === undefined ? [] : roles.data;

  const roleOptions: SelectOptionType[] = rolesData
    .filter((e) => (superadmin ? e : e.id !== 'ROLE::81a4a958-2a28-4a77-ab3c-6e4622793baf'))
    .map((e) => ({
      id: e.id,
      label: e.name,
      value: e.id,
    }));

  useEffect(() => {
    if (!currentUser.data) return;

    Object.keys(currentUser.data).forEach((key) => {
      const _key = key as keyof accountsFields;
      setValue(_key, currentUser.data[_key]);
    });
  }, [currentUser.data, setValue]);

  const onError: SubmitErrorHandler<accountsFields> = (err) => {
    console.error(err);
  };

  const onSubmit: SubmitHandler<accountsFields> = (userAccount) => {
    if (currentUser.data) {
      updateUser.mutate(userAccount);
    }
    if (!currentUser.data) {
      createUser.mutate(userAccount);
    }
  };

  const backTo = () => navigate('/users');

  if (id !== undefined) {
    if (updateUser.isLoading || currentUser.isLoading) {
      return <LoadingIndicator show />;
    }
  }
  if (createUser.isLoading || roles.isLoading) {
    return <LoadingIndicator show />;
  }

  return (
    <>
      <PageContainer
        title='User Management'
        subTitle='User'
        controls={
          <Stack direction='row' gap={2}>
            <Button sx={{ backgroundColor: appColors.lightGray, color: 'black' }} onClick={backTo}>
              Cancel
            </Button>
            <Button
              onClick={handleSubmit(onSubmit, onError)}
              disableElevation
              variant='contained'
              sx={{ textTransform: 'capitalize', borderRadius: 1.5 }}
            >
              Save
            </Button>
          </Stack>
        }
      >
        <Divider color='black' />
        <Stack
          component='form'
          height='100%'
          bgcolor='white'
          py={3}
          boxShadow={appStyles.shadow}
          borderRadius={1}
        >
          <Stack px={3} height='100%' overflow='auto' gap={1}>
            <Stack
              direction='column'
              gap={3}
              width={550}
              justifyContent='center'
              margin='auto'
              mt={1.5}
            >
              <AppTextField
                required
                label='First Name'
                placeholder='First Name'
                {...register('firstName')}
                error={!!errors.firstName}
                helperText={errors.firstName?.message}
              />
              <AppTextField
                required
                label='Last Name'
                placeholder='Last Name'
                {...register('lastName')}
                error={!!errors.lastName}
                helperText={errors.lastName?.message}
              />

              <AppTextField
                required
                label='Email'
                placeholder='Email'
                {...register('email')}
                error={!!errors.email}
                helperText={errors.email?.message}
              />
              <AppTextField
                required
                label='Contact No.'
                placeholder='Contact Number'
                {...register('contactNumber')}
                error={!!errors.contactNumber}
                helperText={errors.contactNumber?.message}
              />

              {currentUser.data ? (
                <Controller
                  control={control}
                  name='isActive'
                  render={({ field: { onChange, value } }) => (
                    <AccountActiveButton value={value} onChange={(e, v) => onChange(v)} />
                  )}
                />
              ) : null}

              <Controller
                control={control}
                name='roleId'
                render={({ field }) => (
                  <AppSelect
                    label='Role'
                    placeholder='Role'
                    error={!!errors.roleId}
                    helperText={errors.roleId?.message}
                    options={roleOptions}
                    {...field}
                  />
                )}
              />
            </Stack>
          </Stack>
        </Stack>
      </PageContainer>
      {id !== undefined
        ? (updateUser?.isError || createUser?.isError) && showSnackBar('Network Error', 'error')
        : createUser?.isError && showSnackBar('Network Error', 'error')}
    </>
  );
};

export default AccountsForm;
