import { Stack } from '@mui/material';
import Header from '../../components/layout-ui/Header';
import AccountMenu from './AccountMenu';

const AppHeader = () => {
  return (
    <Header
      elevation={0}
      sx={{
        bgcolor: 'white',
        borderBottom: '1px solid #eee',
        borderLeft: '1px solid #eee',
        color: (theme) => theme.palette.primary.main,
      }}
    >
      <Stack ml='auto'>
        <AccountMenu />
      </Stack>
    </Header>
  );
};
export default AppHeader;
