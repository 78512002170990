import PageContainer from '../../../../components/common/PageContainer';

function PMSCodePage() {
  return (
    <>
      <PageContainer title='Servicing Databases' subTitle='PMS Code Master' showDate>
        PMS Code Master
      </PageContainer>
    </>
  );
}

export default PMSCodePage;
