import { createColumnHelper } from '@tanstack/react-table';
import { EditActionButton } from '../../../../components/common/primebmd-ui/EditActionButton';
import { IEURActivity } from '../utils/types';
import { 
  useGetURLParams
} from './useGetLocationOrParams';
import moment from 'moment';

export const useCreateColumns = () => {
  const { id } = useGetURLParams();
  const columnHelper = createColumnHelper<IEURActivity>();

  const EURActivityColumns = [
    columnHelper.accessor('activity', {
      id: 'activity',
      header: 'Activity Log',
      cell: (row) => row.getValue(),
      enableGlobalFilter: true,
      enableColumnFilter: false,
      enableHiding: true
    }),
    columnHelper.accessor('startTime', {
      id: 'startTime',
      header: 'Start Time',
      cell: (row) => moment(row.getValue()).format('h:mm a'),
      enableGlobalFilter: true,
      enableColumnFilter: false,
      enableHiding: true
    }),
    columnHelper.accessor('endTime', {
      id: 'endTime',
      header: 'End Time',
      cell: (row) =>  moment(row.getValue()).format('h:mm a'),
      enableGlobalFilter: true,
      enableColumnFilter: false,
      enableHiding: true
    }),
    columnHelper.display({
      id: 'actions',
      header: 'Action',
      cell: ({ row }) => (
      <>
          <EditActionButton route={`/eur/${id}/activity/edit/${row.original.id}`} />
      </>
      ),
      enableHiding: false,
    })
  ];

  return EURActivityColumns;
};