import { Autocomplete, Chip, TextField, Typography } from '@mui/material';
import { Stack } from '@mui/system';
import { useState } from 'react';
import { appColors } from '../../theme';
import FormHelperText from '@mui/material/FormHelperText';

interface IProps {
  value: string[];
  setValue: (v: string[]) => void;
  isEmail?: boolean;
  id: string;
  label?: string;
  placeholder: string;
  helperText?: string;
}

const emailoptions: string[] = [
  'king.bernal@opsolutions.biz',
  'monique.angcaya@opsolutions.biz',
  'angcayamonique@gmail.com',
];

const MultiEmailForm = (props: IProps) => {
  const [selected, setSelected] = useState([]);
  const [inputValue, setInputValue] = useState('');
  const regex =
    /^(([^<>()[\].,;:\s@"]+(\.[^<>()[\].,;:\s@"]+)*)|(".+"))@((([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  const [error, setError] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');

  const {
    value,
    setValue,
    isEmail,
    id,
    label,
    placeholder,
    helperText,
    ...rest
  } = props;

  function onChange(e: any, value: any) {
    // error
    const errorEmail = value.find((email: any) => !regex.test(email));
    if (errorEmail && isEmail) {
      // set value displayed in the textbox
      setInputValue(errorEmail);
      setError(true);
      setErrorMessage('Invalid Email Format');
    } else {
      setError(false);
      setSelected(value.filter((email: any) => regex.test(email)));
      setValue(value);
    }
    if (!isEmail) {
      setSelected(value.filter((email: any) => email));
      setValue(value);
    }
  }

  function onDelete(value: any) {
    setSelected(selected.filter((e) => e !== value));
    setValue(selected.filter((e) => e !== value));
  }

  function onInputChange(e: any, newValue: any) {
    setInputValue(newValue);
  }
  return (
    <>
      <Stack direction='row'>
        {label && (
          <Typography
            color={appColors.formText}
            component='label'
            fontSize={15}
            width={95}
            fontWeight='600'
            align={'left'}
            htmlFor={label}
            mt={1.5}
            whiteSpace='nowrap'
          >
            {label}{' '}
          </Typography>
        )}
        <Stack width="100%">
          <Autocomplete
            multiple
            fullWidth={true}
            onChange={onChange}
            id={id}
            value={selected}
            inputValue={inputValue}
            filterSelectedOptions={true}
            onInputChange={onInputChange}
            options={emailoptions.map((option) => option)}
            freeSolo
            renderTags={(value: readonly string[], getTagProps) =>
              value.map((option: string, index: number) => (
                <Chip
                  variant='outlined'
                  label={option}
                  {...getTagProps({ index })}
                  onDelete={() => onDelete(option)}
                />
              ))
            }
            renderInput={(params) => (
              <TextField
                {...params}
                sx={{ width: '100%' }}
                variant='outlined'
                placeholder={placeholder}
              />
            )}
            {...rest}
          />
          {error && isEmail ? (
            <FormHelperText id="component-helper-text" sx={{ color: 'error.main' }}>
              {errorMessage}
            </FormHelperText>
          ) : null}{' '}
          {helperText && (
            <FormHelperText id="component-helper-text" sx={{ color: 'error.main' }}>
              {helperText}
            </FormHelperText>
          )}
        </Stack>
      </Stack>
    </>
  );
};

export default MultiEmailForm;
