import { IAccounts } from '../modules/Users/Accounts/accounts-utils';
import { apiClient, apiEndpoints } from './apiClient';

export type GetManyReturnType = {
  status: string;
  data: IAccounts[];
};

type GetOneReturnType = {
  status: string;
  data: IAccounts;
};

const getUser = async () => {
  const response = await apiClient.get<GetManyReturnType>(apiEndpoints.users);
  return response.data.data;
};

const getUserById = async (id: string) => {
  const response = await apiClient.get<GetOneReturnType>(
    `${apiEndpoints.users}/${id}`
  );
  return response.data.data;
};

const createUser = async (newUserData: Partial<IAccounts>) => {
  const response = await apiClient.post<GetOneReturnType>(
    apiEndpoints.users,
    newUserData
  );
  return response.data.data;
};

const updateUser = async (id: string, updateUserData: Partial<IAccounts>) => {
  const response = await apiClient.patch<GetOneReturnType>(
    `${apiEndpoints.users}/${id}`,
    updateUserData
  );

  return response.data.data;
};

const deleteUser = async (id: string) => {
  const response = await apiClient.delete(`${apiEndpoints.users}/${id}`);
  return response.data.data;
};

const userApi = {
  getUser,
  getUserById,
  createUser,
  updateUser,
  deleteUser,
};

export default userApi;
