import React from 'react';
import { Outlet } from 'react-router-dom';
import PageTitle from '../../../components/common/primebmd-ui/PageTitle';

const CtPage = () => {
  return (
    <>
      <PageTitle title='Certification Tracker' />
      <Outlet />
    </>
  );
};

export default CtPage;
