import { Location } from '../modules/MyDashboardsPlusTasks/EUR/utils/types';
import { apiClient, apiEndpoints } from './apiClient';

export type GetManyReturnType = {
  status: string;
  data: Location[];
};

const getLocations = async () => {
  const response = await apiClient.get<GetManyReturnType>(apiEndpoints.location);
  return response.data.data;
};

export default {
  getLocations
};