import PageContainer from '../../../../components/common/PageContainer';

function ProjectPage() {
  return (
    <>
      <PageContainer title='Project Databases' subTitle='Project Master' showDate>
        Project Master
      </PageContainer>
    </>
  );
}

export default ProjectPage;
