import { Outlet } from 'react-router-dom';
import PageContainer from '../../../../components/common/PageContainer';
import InTransitsTable from './Table';

const InTransitsPage = () => {
  return (
    <>
      <PageContainer 
        title='My Dashboard + Tasks' 
        subTitle='My Equipment Requests'
        submoduleTitle='My In Transits'  
        showDate
      >
        <InTransitsTable />
      </PageContainer>
      <Outlet />
    </>
  );
}

export default InTransitsPage;