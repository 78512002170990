import PageContainer from '../../../../components/common/PageContainer';

function FuelUpdatePage() {
  return (
    <>
      <PageContainer title='Other Databases' subTitle='Fuel (Per Liter) Update Master' showDate>
        Fuel Update Master
      </PageContainer>
    </>
  );
}

export default FuelUpdatePage;
