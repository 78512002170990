import { useEffect, useState } from 'react';

export default function useTableContainerWidth(elementId: string) {
  const [width, setWidth] = useState(0);

  useEffect(() => {
    const resizeCallback = () => {
      const element = document.getElementById(elementId);

      if (!element) return;
      const w = element.getBoundingClientRect().width;
      setWidth(w);
    };

    resizeCallback();

    window.addEventListener('resize', resizeCallback);

    return () => window.removeEventListener('resize', resizeCallback);
  }, [elementId]);

  return width;
}
