import { useQuery } from '@tanstack/react-query';
import { IEJO, IESR } from '../modules/MyDashboardsPlusTasks/EJO-ESR/utils/types';
import { dateDifference } from '../utils/helpers';
import ejoAPI from '../services/dashboardPlusTasks/ejo';

export function useGetEJOQuery(status?: string) {
  return useQuery<IEJO[]>(['ejos'], () => ejoAPI.getAllEJO(status), {
    onError(err) {
      console.log(err);
    }
  });
}

export const useFormatGetEJOQuery = (data: IEJO[], status: string) => {
  const newEJOList = [];
  
  const EJOList = data.map(e => e.serviceRequestList.map(sr => {
    const daysElapsedVariable = status === 'Approved' ? e.dateApproved : e.dateCreated

    return {
      ejoNumber: e.ejoNumber,
      equipmentNo: e.equipmentNo,
      projectLocation: e.projectLocation.name,
      serviceRequestNo: sr.serviceRequestNo,
      serviceRequestType: sr.serviceRequest,
      remarks: sr.remarks,
      daysElapsed: dateDifference(new Date(), daysElapsedVariable)
    }
  }));

  for (const n of EJOList) {
    for (const ne of n) {
      newEJOList.push(ne);
    }
  }

  return newEJOList;
}

export const useFormatGetEJOWithoutESRQuery = (ejoData: IEJO[], esrData: IESR[], status: string) => {
  const ejoWithoutESR = ejoData.filter(e => !esrData.map(esr => esr.ejoId).includes(e.id));

  console.log('EJO List: ', ejoData.map(ejo => {
    return {
      id: ejo.id,
      ejoNo: ejo.ejoNumber,
      sr: ejo.serviceRequestList,
      srCount: ejo.serviceRequestList.length
    }
  }))
  console.log('ESR List: ', esrData.map(esr => {
    return {
      id: esr.id,
      ejoId: esr.ejoId,
      ejoNo: esr.ejoNumber,
      sr: esr.serviceRequestId
    }
  }))
  console.log('EJO w/o ESR List: ', ejoWithoutESR.map(ejo => {
    return {
      id: ejo.id,
      ejoNo: ejo.ejoNumber,
      sr: ejo.serviceRequestList,
      srCount: ejo.serviceRequestList.length
    }
  }))

  const newEJOList = [];
  
  const EJOList = ejoWithoutESR.map(e => e.serviceRequestList.map(sr => {
    const daysElapsedVariable = status === 'Approved' ? e.dateApproved : e.dateCreated

    return {
      ejoNumber: e.ejoNumber,
      equipmentNo: e.equipmentNo,
      projectLocation: e.projectLocation.name,
      serviceRequestNo: sr.serviceRequestNo,
      serviceRequestType: sr.serviceRequest,
      remarks: sr.remarks,
      daysElapsed: dateDifference(new Date(), daysElapsedVariable)
    }
  }));
  
  for (const n of EJOList) {
    for (const ne of n) {
      newEJOList.push(ne);
    }
  }

  return newEJOList;
}