import { Chip } from '@mui/material';
import React from 'react';

interface IProps {
  label: string;
  onDelete?: () => void;
}

const MultiFileEmail = (props: IProps) => {
  return (
    <Chip
      variant='outlined'
      label={props.label}
      onDelete={props.onDelete}
      sx={{}}
    />
  );
};

export default MultiFileEmail;
