import Stack from '@mui/material/Stack';
import { appColors } from '../../theme';

interface AssetAlertProps {
  type: Date;
}

const CtStatus = ({ type }: AssetAlertProps) => {
  const dateNow = new Date(Date.now());
  const totalTime = type.getTime() - dateNow.getTime();
  const daysToExpire = Math.round(totalTime / (1000 * 3600 * 24));
  return (
    <Stack
      sx={{
        backgroundColor: `${
          daysToExpire <= 30
            ? appColors.chartRed
            : daysToExpire >= 31 && daysToExpire <= 60
            ? appColors.chartYellow
            : 'green'
        }`,
        height: 26,
        justifyContent: 'center',
        alignItems: 'center',
        paddingX: 1,
        color: 'white',

        '& > span': {
          width: 120,
          fontSize: 12,
          whiteSpace: 'nowrap',
          overflow: 'hidden',
          textOverflow: 'ellipsis',
        },
      }}
    >
      <span>{daysToExpire.toFixed(0)}</span>
    </Stack>
  );
};

export default CtStatus;
