import PageContainer from '../../../../components/common/PageContainer';
import EJOApprovedAndESRUncreatedTable from './Table';

const EJOApprovedAndESRUncreatedPage = () => {
  return (
    <>
      <PageContainer 
        title='My Dashboard + Tasks' 
        subTitle='My EJOs & ESRs'
        submoduleTitle='EJOs Approved, ESR Uncreated' 
        showDate
      >
        <EJOApprovedAndESRUncreatedTable/>
      </PageContainer>
    </>
  );
}

export default EJOApprovedAndESRUncreatedPage;