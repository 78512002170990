import { forwardRef } from 'react';
import { styled } from '@mui/material/styles';
import Stack from '@mui/material/Stack';
import AttachFileIcon from '@mui/icons-material/AttachFile';
interface MultipleUploadButtonProps
  extends React.DetailedHTMLProps<
    React.InputHTMLAttributes<HTMLInputElement>,
    HTMLInputElement
  > {
  label?: string;
}

const UploadButton = styled('span')(({ theme }) => ({
  color: theme.palette.primary.main,
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  justifyContent: 'center',
  fontSize: 12,
  fontWeight: 500,
  cursor: 'pointer',
  transition: 'all 0.3s',

  '&.disabled': {
    pointerEvents: 'none',
    color: '#ccc',

    '& > div': {
      backgroundColor: '#ccc',
    },
  },

  '&:hover': {
    filter: `brightness(85%)`,
  },

  '& > div': {
    width: 38,
    height: 38,
    backgroundColor: theme.palette.primary.main,
    color: 'white',
    borderRadius: '50%',
    alignItems: 'center',
    justifyContent: 'center',
  },
}));

const MultipleUploadButton = forwardRef<
  HTMLInputElement,
  MultipleUploadButtonProps
>(({ label, name, ...rest }, ref) => {
  return (
    <Stack>
      <label htmlFor={name} style={{ width: 'max-content' }}>
        <input
          ref={ref}
          type='file'
          hidden
          name={name}
          id={name}
          multiple
          {...rest}
        />
        <UploadButton className={rest.disabled ? 'disabled' : ''}>
          <Stack>
            <AttachFileIcon />
          </Stack>
        </UploadButton>
      </label>
    </Stack>
  );
});

export default MultipleUploadButton;
