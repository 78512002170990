import { useMutation, useQuery } from '@tanstack/react-query';
import { queryClient } from '../react-query/queryClient';
import { AxiosError } from 'axios';
import { useNavigate, useParams } from 'react-router';
import { useSnackBar } from '../components/ToastProvider';
import { 
  IOnRent,
  IEXFForm,
  IEORRForm 
} from '../modules/MyDashboardsPlusTasks/MyOnRentEquipments/utils/types';
import onRentApi from '../services/dashboardPlusTasks/onRentEquipments';

export const useGetEquipmentsOnRentQuery = () => {
  return useQuery<IOnRent[]>(['onRents'], () => onRentApi.getOnRentEquipments(), {
    onError(err) {
      console.log(err);
    }
  });
}

export function useCreateEXF() {
  const { showSnackBar } = useSnackBar();
  const { id } = useParams<{ id: string }>();
  const navigate = useNavigate();

  return useMutation((exfData: Partial<IEXFForm>) => onRentApi.createEXF(id!, exfData), {
    onSuccess() {
      queryClient.invalidateQueries({ queryKey: ['onRents'] }).then(() => {
        showSnackBar('Successfully Created an EXF', 'success');
        navigate('/my-on-rent-equipment', { replace: true });
      });
    },
    onError(err) {
      const errorData = (err as AxiosError<{ error: string }>).response?.data.error;

      if (err && errorData) {
        showSnackBar(errorData, 'error');
      }
    }
  });
}

export function useCreateEORR() {
  const { showSnackBar } = useSnackBar();
  const { id } = useParams<{ id: string }>();
  const navigate = useNavigate();

  return useMutation((eorrData: Partial<IEORRForm>) => onRentApi.createEORR(id!, eorrData), {
    onSuccess() {
      queryClient.invalidateQueries({ queryKey: ['onRents'] }).then(() => {
        showSnackBar('Successfully Created an EORR', 'success');
        navigate('/my-on-rent-equipment', { replace: true });
      });
    },
    onError(err) {
      const errorData = (err as AxiosError<{ error: string }>).response?.data.error;

      if (err && errorData) {
        showSnackBar(errorData, 'error');
      }
    }
  });
}