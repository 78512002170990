import { styled } from '@mui/system';
import { NavLink } from 'react-router-dom';

const AppNavLink = styled(NavLink)(({ theme }) => ({
  textDecoration: 'none',
  color: 'white',

  '& .sub-icon': {
    opacity: 0,
  },

  '&.active .sub-icon': {
    opacity: 1,
  },
}));

export default AppNavLink;
