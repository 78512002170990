import moment from 'moment';
import { createColumnHelper } from '@tanstack/react-table';
import { ViewActionButton, EditActionButton, ListActionButton } from '../../../../components/common/primebmd-ui/EditActionButton';
import { IEUR } from '../utils/types';

const columnHelper = createColumnHelper<Partial<IEUR>>();

const EURLogsColumns = [
  columnHelper.accessor('eurNo', {
    id: 'eurNo',
    header: 'EUR No.',
    cell: (row) => row.getValue(),
    enableGlobalFilter: true,
    enableColumnFilter: true,
    enableHiding: true
  }),
  columnHelper.accessor('date', {
    id: 'date',
    header: 'Date',
    cell: (row) => moment(row.getValue()).format('MM/DD/yyyy'),
    enableGlobalFilter: true,
    enableColumnFilter: false,
    enableHiding: true
  }),
  columnHelper.accessor('equipmentNo', {
    id: 'equipmentNo',
    header: 'Equipment No.',
    cell: (row) => row.getValue(),
    enableGlobalFilter: true,
    enableColumnFilter: false,
    enableHiding: true
  }),
  columnHelper.accessor('operator', {
    id: 'operator',
    header: 'Operator',
    cell: (row) => row.getValue(),
    enableGlobalFilter: true,
    enableColumnFilter: false,
    enableHiding: true
  }),
  columnHelper.accessor('location', {
    id: 'location',
    header: 'Location',
    cell: (row) => row.getValue(),
    enableGlobalFilter: true,
    enableColumnFilter: false,
    enableHiding: true
  }),
  columnHelper.display({
    id: 'actions',
    header: 'Action',
    cell: ({ row }) => (
      <>
        <ViewActionButton route={`/eur/view/${row.original.id}`} />
        <EditActionButton route={`/eur/edit/${row.original.id}`} />
        <ListActionButton route={`/eur/${row.original.id}/activity`} />
      </>
    ),
    enableHiding: false,
  }),
];

export default EURLogsColumns;