import { useNavigate } from 'react-router-dom';
import { usePickedUpFromSite } from '../../../../hooks/useEPFERF';
import AppDialog from '../../../../components/common/AppDialog';
import LoadingIndicator from '../../../../components/common/LoadingIndicator';
import Stack from '@mui/material/Stack';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';

const PickedUpFromSiteConfirmation = () => {
  const navigate = useNavigate();
  const pickedUpFromSite = usePickedUpFromSite();

  const handleClose = () => navigate('/my-equipment-requests/my-eorr', { replace: true });

  if(pickedUpFromSite.isLoading) return <LoadingIndicator show />;

  return (
    <AppDialog open onDialogClose={handleClose} title='Confirmation' maxWidth='sm' titleAlignment="center">
      <Stack component='form' height='100%' bgcolor='white' py={3} borderRadius={1}>
        <Stack px={3} height='100%' overflow='auto' gap={5}>
          <Stack direction='column' width="100%" justifyContent='center' margin='auto' gap={3}>
            <Typography textAlign="center" fontSize={14}>
              Marking this as <b>"Picked Up from Site"</b> will mark this request as <b>"Closed"</b>, do you want to continue?
            </Typography>
            <Stack direction="row" justifyContent="center" gap={1}>
              <Button 
                onClick={() => pickedUpFromSite.mutate()}
                sx={{ width:"100px" }}
              >
                Confirm
              </Button>
              <Button 
                variant="outlined" 
                sx={{ width:"100px" }}
                onClick={() => handleClose()}
              >
                Cancel
              </Button>
            </Stack>
          </Stack>
        </Stack>
      </Stack>
    </AppDialog>
  );
}

export default PickedUpFromSiteConfirmation;