import { useQuery } from '@tanstack/react-query';
import { IESR } from '../modules/MyDashboardsPlusTasks/EJO-ESR/utils/types';
import { dateDifference } from '../utils/helpers';
import esrAPI from '../services/dashboardPlusTasks/esr';

export function useGetESRQuery(status?: string) {
  return useQuery<IESR[]>(['esr'], () => esrAPI.getAllESR(status), {
    onError(err) {
      console.log(err);
    }
  });
}

export const useFormatGetESRQuery = (data: IESR[], status: string) => {
  const newESRList = data.map(d => {
    const daysElapsedVariable = status === 'Approved' 
                                ? d.dateApproved 
                                : ( status === 'Started' 
                                    ? d.dateStarted 
                                    : d.dateCreated
                                  );

    return {
      esrNumber : d.esrNumber,
      equipmentNo : d.equipmentNo,
      projectLocation : d.projectLocation,
      serviceRequestType : d.serviceRequestType,
      remarks : d.remarks,
      daysElapsed: dateDifference(new Date(), daysElapsedVariable)
    }
  });

  return newESRList;
}