import { Button, Stack, Typography } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import AppDialog from '../../../components/common/AppDialog';
import { useDeleteUser, useGetUserById } from '../../../hooks/useUser';
import { appColors } from '../../../theme';
const AccountDeleteDialog = () => {
  const currentUser = useGetUserById();
  const deleteUser = useDeleteUser();
  const navigate = useNavigate();

  const handleClose = () => navigate('/users', { replace: true });

  const handleDelete = () => {
    if (!currentUser.data) return null;
    deleteUser.mutate(currentUser.data.id);
  };

  return (
    <AppDialog
      open
      onDialogClose={handleClose}
      title={`Are you sure you want to delete?`}
      isFormDelete={true}
      maxWidth='xs'
    >
      <Typography textAlign='left' color={appColors.textGray}>
        {`If yes, ${currentUser.data?.firstName} ${currentUser.data?.lastName} will be deleted `}
      </Typography>
      <Stack direction='row' alignItems='center' justifyContent='flex-end' mt={4} mb={2} gap={2}>
        <Button
          variant='contained'
          disableElevation
          sx={{
            textTransform: 'capitalize',
            backgroundColor: appColors.offWhite,
            color: '#2C3078',
            width: 120,
            '&:hover': {
              background: '#E6E6E6',
            },
          }}
          onClick={handleClose}
        >
          Cancel
        </Button>
        <Button
          variant='contained'
          color='error'
          disableElevation
          sx={{
            textTransform: 'capitalize',
            pointerEvents: 'initial',
            width: 120,
          }}
          onClick={handleDelete}
        >
          Delete
        </Button>
      </Stack>
    </AppDialog>
  );
};

export default AccountDeleteDialog;
