import { createContext, useContext, useState } from 'react';
import AppToast from './common/AppToast';

type SnackTypes = 'success' | 'error' | 'info';

type SnackBarContextActions = {
  showSnackBar: (message: string, type: SnackTypes) => void;
};

const SnackBarContext = createContext({} as SnackBarContextActions);

interface SnackBarContextProviderProps {
  children: React.ReactNode;
}

const SnackBarProvider: React.FC<SnackBarContextProviderProps> = ({
  children,
}) => {
  const [open, setOpen] = useState<boolean>(false);
  const [message, setMessage] = useState<string>('');
  const [typeColor, setTypeColor] = useState<SnackTypes>('info');

  const showSnackBar = (message: string, type: SnackTypes) => {
    setMessage(message);
    setTypeColor(type);
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
    setTypeColor('info');
  };

  return (
    <SnackBarContext.Provider value={{ showSnackBar }}>
      <AppToast
        visible={open}
        message={message}
        type={typeColor}
        handleClose={handleClose}
      />
      {children}
    </SnackBarContext.Provider>
  );
};

const useSnackBar = (): SnackBarContextActions => {
  const context = useContext(SnackBarContext);

  if (!context) {
    throw new Error('useSnackBar must be used within an SnackBarProvider');
  }

  return context;
};

export { SnackBarProvider, useSnackBar };
