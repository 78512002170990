import PageContainer from '../../../../components/common/PageContainer';

function MeterTypePage() {
  return (
    <>
      <PageContainer title='Other Databases' subTitle='Meter Type + Interval Type Master' showDate>
        Meter Type + Interval Type Master
      </PageContainer>
    </>
  );
}

export default MeterTypePage;
