import { Outlet } from 'react-router-dom';
import PageContainer from '../../../../components/common/PageContainer';
import EURLogsTable from './Table';

const EURAcknowledgeList = () => {
  return (
    <>
      <PageContainer 
        title='My Dashboard + Tasks' 
        subTitle='My EURs'
        showDate
      >
        <EURLogsTable />
      </PageContainer>
      <Outlet />
    </>
  )
}

export default EURAcknowledgeList;