import { DAIU } from '../../modules/Reports/EquipmentUseAndPerformanceReports/utils/types';
import { apiClient, apiEndpoints } from '../apiClient';

export type GetManyReturnType = {
  status: string;
  data: DAIU[];
};

const getDAIU = async (startDate?: string, endDate?: string) => {
    let daiuDate = { startDate:  startDate, endDate: endDate }
  const response = await apiClient.post<GetManyReturnType>(`${apiEndpoints.daiu}`, daiuDate);

  return response.data.data;
};

const daiuApi = {
  getDAIU
};

export default daiuApi;