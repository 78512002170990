import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import Stack from '@mui/material/Stack';
import Paper from '@mui/material/Paper';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import TablePagination from '@mui/material/TablePagination';
import Typography from '@mui/material/Typography';
import { flexRender } from '@tanstack/react-table';
import {
  AppTable,
  AppTableBody,
  AppTableCell,
  AppTableContainer,
  AppTableHead,
  AppTableRow,
  AppTableSort,
} from '../../../../components/common/app-table/ui';
import AppDateRange from '../../../../components/common/AppDateRange';
import useDefaultTable from '../../../../components/common/app-table/useDefaultTable';
import LoadingIndicator from '../../../../components/common/LoadingIndicator';
import EURLogsColumns from './Columns';
import { IEUR } from '../utils/types';
import { useGetEURSMRQuery } from '../../../../hooks/useEURSMR';
import moment from 'moment';

const EURLogsTable = () => {
  const [startDate, setStartDate] = useState<string>('');
  const [endDate, setEndDate] = useState<string>('');
  const eur = useGetEURSMRQuery(startDate, endDate);
  const data: IEUR[] = eur?.data === undefined ? [] : eur?.data;
  const navigate = useNavigate();
  
  const goToAddEURSMR = () => navigate('/eur/new');
  
  const { table, dataCount, searchFilter, pageIndex, pageSize } =
  useDefaultTable<Partial<IEUR>>(data, EURLogsColumns);

  const emptyText = searchFilter ? 'No data found' : 'No data yet';

  if (eur?.isLoading) return <LoadingIndicator show />;

  return (
    <>
      <Stack direction="row" justifyContent="space-between" alignItems="center" width="100%">
        <Stack direction='column' mb={4} gap={.75} width="100%">
          <Typography fontSize={14} mt={2} fontWeight={700}>
            Date
          </Typography>
          <Stack direction="row" justifyContent="space-between" width="35%">
            <AppDateRange
              id="date-range"
              startDate={startDate}
              endDate={endDate}
              onDateChange={(s, e) => {
                setStartDate(moment(s).format("YYYY-MM-DD"))
                setEndDate(moment(e).format("YYYY-MM-DD"))
              }}
            />
          </Stack>
        </Stack>
        <Stack width="15%">
          <Button onClick={goToAddEURSMR}>
            Add EUR
          </Button>
        </Stack>
      </Stack>
      <Paper elevation={3}>
        <AppTableContainer>
          <AppTable stickyHeader>
            <AppTableHead>
              {table.getHeaderGroups().map((headerGroup) => (
                <AppTableRow key={headerGroup.id}>
                  {headerGroup.headers.map((header) => (
                    <AppTableCell key={header.id} align='center'>
                      <AppTableSort
                        canSort={header.column.getCanSort()}
                        currentSort={header.column.getIsSorted()}
                        onSort={header.column.getToggleSortingHandler()}
                      >
                        {flexRender(header.column.columnDef.header, header.getContext())}
                      </AppTableSort>
                    </AppTableCell>
                  ))}
                </AppTableRow>
              ))}
            </AppTableHead>
            <AppTableBody
              dataCount={dataCount}
              // loading={loading}
              emptyText={emptyText}
            >
              {table.getRowModel().rows.map((row) => (
                <AppTableRow key={row.id}>
                  {row.getVisibleCells().map((cell) => (
                    <AppTableCell key={cell.id} align='center'>
                      {flexRender(cell.column.columnDef.cell, cell.getContext())}
                    </AppTableCell>
                  ))}
                </AppTableRow>
              ))}
            </AppTableBody>
          </AppTable>
        </AppTableContainer>
        <Box position='relative'>
          <TablePagination
            sx={{ bgcolor: 'white', borderTop: '1px solid #eee' }}
            component='div'
            count={dataCount}
            page={pageIndex}
            onPageChange={(e, page) => table.setPageIndex(page)}
            rowsPerPage={pageSize}
            onRowsPerPageChange={(e) => table.setPageSize(Number(e.target.value))}
            rowsPerPageOptions={[10, 20, 50, 100]}
          />
        </Box>
      </Paper>
    </>
  );
}

export default EURLogsTable;