import { useEffect } from 'react';
import { yupResolver } from '@hookform/resolvers/yup';
import { Button, Stack } from '@mui/material';
import { useState } from 'react';
import { Controller, useFieldArray, useForm } from 'react-hook-form';
import { useNavigate } from 'react-router-dom';
import {
  logCertificateFields,
  logCertificateInitialValues,
  logCertificateSchema,
} from './ct-utils';
import AppDatePicker from '../../../components/common/forms/AppDatePicker';
import AppTextField from '../../../components/common/forms/AppTextField';
import AppDialog from '../../../components/common/AppDialog';
import LoadingIndicator from '../../../components/common/LoadingIndicator';
import AppPrimeUploadForm from '../../../components/common/forms/AppPrimeUploadForm';
import { 
  useGetCertificateTrackerById, 
  useUpdateCertificateTracker, 
  useCreateCertificateTracker,
  useGetEquipmentById 
} from '../../../hooks/useCertificateTracker';

const CtLogForm = () => {
  const equipment = useGetEquipmentById();
  const equipmentData = equipment.data;
  const certificateToUpdate = useGetCertificateTrackerById();
  const certificateToUpdateData = certificateToUpdate.data;
  const updateCertificateTracker = useUpdateCertificateTracker();
  const createCertificateTracker = useCreateCertificateTracker();
  const [fileName, setFileName] = useState('');
  const navigate = useNavigate();

  const handleClose = () => navigate('/equipment-certificates', { replace: true });

  const {
    register,
    handleSubmit,
    control,
    watch,
    setValue,
    formState: { errors, isValid },
  } = useForm<logCertificateFields>({
    defaultValues: certificateToUpdateData! 
      ? {
          equipmentNo: certificateToUpdateData.equipmentNo,
          equipmentType: certificateToUpdateData.equipmentType,
          certificateName: certificateToUpdateData.certificateName,
          certificateNo:  certificateToUpdateData.certificateNo,
          governmentBody: certificateToUpdateData.governmentBody,
          issuingAgency: certificateToUpdateData.issuingAgency,
          issuanceDate: certificateToUpdateData.issuanceDate,
          validityDate: certificateToUpdateData.validityDate,
          pic: certificateToUpdateData.pic,
          media: []
        } 
      : logCertificateInitialValues,
    mode: 'onChange',
    resolver: yupResolver(logCertificateSchema),
  });

  useEffect(() => {
    if (!certificateToUpdateData) return;

    const {
      equipmentNo,
      equipmentType,
      certificateName,
      certificateNo,
      governmentBody,
      issuingAgency,
      pic,
      issuanceDate,
      validityDate
    } = certificateToUpdateData;

    setValue("equipmentNo", equipmentNo);
    setValue("equipmentType", equipmentType);
    setValue("certificateName", certificateName);
    setValue("certificateNo", certificateNo);
    setValue("governmentBody", governmentBody);
    setValue("issuingAgency", issuingAgency);
    setValue("pic", pic);
    setValue("issuanceDate", issuanceDate);
    setValue("validityDate", validityDate);

  }, [certificateToUpdateData, setValue]);

  useEffect(() => {
    if (!equipmentData) return;

    const { equipmentNo, productFamily } = equipmentData

    setValue("equipmentNo", equipmentNo);
    setValue("equipmentType", productFamily.categoryName);

  }, [equipmentData, setValue]);

  const onSubmit = (logForm: logCertificateFields) => {
    const newFormData = {
      ...logForm,
      equipmentId: certificateToUpdateData ? certificateToUpdateData?.equipmentId : equipmentData?.id
    }

    if (certificateToUpdateData) {
      updateCertificateTracker.mutate(newFormData);
    }

    if (!certificateToUpdateData && equipmentData) {
      createCertificateTracker.mutate(newFormData);
    }

    navigate('/equipment-certificates', { replace: true });
  };

  const mediaFiles = watch('media');
  const { append } = useFieldArray({ control, name: 'media' });

  const handleMediaUpload = (files: FileList | null) => {
    if (!files) return;

    // check for possible duplicate files (lookup value is filename)
    const emailFilenames = mediaFiles.map((item) => item.fileName);

    for (let i = 0; i < files.length; i++) {
      if (emailFilenames.includes(files[i].name)) {
        return console.log(`${files[i].name} already exists`);
      }
    }

    Array.from(files).forEach((file) => {
      append({
        fileName: file.name,
        source: file,
      });
    });
    setFileName(files[0].name);
  };

  if (updateCertificateTracker.isLoading || createCertificateTracker.isLoading) {
    return <LoadingIndicator show />;
  }

  return (
    <AppDialog open onDialogClose={handleClose} title='Log Certification' maxWidth='md'>
      <Stack component='form' height='100%' bgcolor='white' py={3} borderRadius={1}>
        <Stack px={3} height='100%' overflow='auto' gap={5}>
          <Stack direction='column' gap={2} width={550} justifyContent='center' margin='auto'>
            <Stack direction="row" width="100%" gap={2}>
              <Controller
                control={control}
                name='issuanceDate'
                render={({ field: { value, onChange } }) => (
                  <AppDatePicker
                    name='issuanceDate'
                    value={value}
                    onChange={onChange}
                    required
                    label='Issuance Date'
                    labelPosition="top"
                    labelAlignment="left"
                    labelWidth='100%'
                    fieldWidth= '100%'
                    error={!!errors.issuanceDate}
                    helperText={errors.issuanceDate?.message}
                  />
                )}
              />
              <Controller
                control={control}
                name='validityDate'
                render={({ field: { value, onChange } }) => (
                  <AppDatePicker
                    name='validityDate'
                    value={value}
                    onChange={onChange}
                    required
                    label='Validity Date'
                    labelPosition="top"
                    labelAlignment="left"
                    labelWidth='100%'
                    fieldWidth= '100%'
                    error={!!errors.validityDate}
                    helperText={errors.validityDate?.message}
                  />
                )}
              />
            </Stack>
            <Stack direction="row" width="100%" gap={2}>
              <AppTextField
                label='Equipment No.'
                labelPosition="top"
                labelAlignment="left"
                labelWidth='100%'
                fieldWidth= '100%'
                required={true}
                value={certificateToUpdateData?.equipmentNo || equipmentData?.equipmentNo}
                disabled={true}
              />
              <AppTextField
                label='Equipment Name'
                labelPosition="top"
                labelAlignment="left"
                labelWidth='100%'
                fieldWidth= '100%'
                required={true}
                value={certificateToUpdateData?.equipmentType || equipmentData?.productFamily.categoryName}
                disabled={true}
              />
            </Stack>
            <Stack direction="row" width="100%" gap={2}>
              <AppTextField
                label='Certificate Name'
                labelPosition="top"
                labelAlignment="left"
                labelWidth='100%'
                fieldWidth= '100%'
                required={true}
                {...register('certificateName')}
              />
              <AppTextField
                label='Certificate No.'
                labelPosition="top"
                labelAlignment="left"
                labelWidth='100%'
                fieldWidth= '100%'
                required={true}
                {...register('certificateNo')}
              />
            </Stack>
            <Stack direction="row" width="100%" gap={2}>
              <AppTextField
                label='Government Body'
                labelPosition="top"
                labelAlignment="left"
                labelWidth='100%'
                fieldWidth= '100%'
                // required={true}
                {...register('governmentBody')}
              />
              <AppTextField
                label='Issuing Agency'
                labelPosition="top"
                labelAlignment="left"
                labelWidth='100%'
                fieldWidth= '100%'
                // required={true}
                {...register('issuingAgency')}
              />
            </Stack>
            <AppTextField
              label='Person in Charge'
              labelPosition="top"
              labelAlignment="left"
              labelWidth='100%'
              fieldWidth= '100%'
              // required={true}
              {...register('pic')}
            />
            <AppPrimeUploadForm
              label='Attached File'
              labelPosition="top"
              labelAlignment="left"
              labelWidth='0%'
              fieldWidth= '100%'
              mt='0px'
              fileName={fileName}
              key={mediaFiles.length}
              onChange={(e) => handleMediaUpload(e.currentTarget.files)}
            />
            <Stack direction="row" justifyContent="center">
              <Button 
                onClick={handleSubmit(onSubmit)} 
                disabled={!isValid}
                sx={{ width: 150 }}
              >
                Save
              </Button>
            </Stack>
          </Stack>
        </Stack>
      </Stack>
    </AppDialog>
  );
};

export default CtLogForm;
