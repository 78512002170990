import { IESR } from '../../modules/MyDashboardsPlusTasks/EJO-ESR/utils/types';
import { apiClient, apiEndpoints } from '../apiClient';

export type GetManyReturnType = {
  status: string;
  data: IESR[];
};

const getAllESR = async (status?: string) => {
  const url = status ? `${apiEndpoints.esr}/AMIList?status=${status}` : `${apiEndpoints.esr}/AMIList`;
  const response = await apiClient.get<GetManyReturnType>(url);
  return response.data.data;
};

const ejoApi = {
  getAllESR
};

export default ejoApi;