import { Outlet } from 'react-router-dom';
import CtTable from './CtTable';

const CtList = () => {
  return (
    <>
      <CtTable />
      <Outlet />
    </>
  );
};

export default CtList;
