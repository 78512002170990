import { ICLC } from '../../modules/Reports/EquipmentCostReports/utils/types';
import { apiClient, apiEndpoints } from '../apiClient';

export type GetManyReturnType = {
  status: string;
  data: ICLC[];
};

const getAllCLC = async () => {
  const response = await apiClient.get<GetManyReturnType>(apiEndpoints.clc);
  return response.data.data;
};

const clcApi = {
  getAllCLC
};

export default clcApi;