import { Outlet } from 'react-router-dom';
import RoleTable from './RoleTable';

const RolesList = () => {
  return (
    <>
      <RoleTable />
      <Outlet />
    </>
  );
};

export default RolesList;
