import Checkbox from '@mui/material/Checkbox';
import Chip from '@mui/material/Chip';
import HeaderCellCheckbox from '../../../components/common/primebmd-ui/HeaderCellCheckbox';
import { dateDifference } from '../../../utils/helpers';
import { createColumnHelper } from '@tanstack/react-table';
import { appColors } from '../../../theme';
import moment from 'moment';

export interface OnRentItem {
  id: string;
  project: string;
  equipmentNo: string;
  productFamily: string;
  make?: string;
  startDate: Date | string;
  endDate: Date | string;
  emEndDate: Date | string;
  exfStatus?: string;
  exfRequestedEndDate: Date | string;
  exfEMEndDate: Date | string;
  alert: string;
  alertDate: Date | string;
}

const columnHelper = createColumnHelper<OnRentItem>();

export const onRentColumns = [
  columnHelper.display({
    id: 'select',
    header: ({ table }) => (
      <HeaderCellCheckbox
        checked={table.getIsAllRowsSelected()}
        indeterminate={table.getIsSomeRowsSelected()}
        onChange={table.getToggleAllRowsSelectedHandler()}
      />
    ),
    cell: ({ row }) => (
      <Checkbox
        checked={row.getIsSelected()}
        indeterminate={row.getIsSomeSelected()}
        onChange={row.getToggleSelectedHandler()}
      />
    ),
  }),
  columnHelper.accessor('project', {
    id: 'project',
    header: 'Project',
    cell: (row) => row.getValue(),
    enableGlobalFilter: true,
    enableColumnFilter: false,
  }),
  columnHelper.accessor('equipmentNo', {
    id: 'equipmentNo',
    header: 'Equipment No.',
    cell: (row) => row.getValue(),
    enableGlobalFilter: true,
    enableColumnFilter: false,
  }),
  columnHelper.accessor('productFamily', {
    id: 'productFamily',
    header: 'Product Family',
    cell: (row) => row.getValue(),
    enableGlobalFilter: true,
    enableColumnFilter: false,
  }),
  columnHelper.accessor('make', {
    id: 'make',
    header: 'Make',
    cell: (row) => row.getValue(),
    enableGlobalFilter: true,
    enableColumnFilter: false,
  }),
  columnHelper.accessor('startDate', {
    id: 'startDate',
    header: 'Start Date',
    cell: (row) => moment(row.getValue()).format('MM/DD/yyyy'),
    enableGlobalFilter: true,
    enableColumnFilter: false,
  }),
  columnHelper.accessor('endDate', {
    id: 'endDate',
    header: 'End Date',
    cell: (row) => moment(row.getValue()).format('MM/DD/yyyy'),
    enableGlobalFilter: true,
    enableColumnFilter: false,
  }),
  columnHelper.display({
    id: 'daysToEndDate',
    header: 'Days to End Date',
    cell: ({ row }) => {
      let emEndDate = row.original.emEndDate ? row.original.endDate : row.original.emEndDate;

      if (row.original.exfStatus) {
        if (row.original.exfStatus === 'Approved') {
          emEndDate = row.original.exfRequestedEndDate;
        } else if (row.original.exfStatus === 'Approved with Change') {
          emEndDate = row.original.exfEMEndDate;
        }
      }

      return dateDifference(new Date(emEndDate), new Date().toISOString());
    },
    enableGlobalFilter: true,
    enableColumnFilter: false,
  }),
  columnHelper.display({
    id: 'readyToWorkStatus',
    header: 'Ready to Work Status',
    cell: ({ row }) => '-',
    enableGlobalFilter: true,
    enableColumnFilter: false,
  }),
  columnHelper.accessor('alert', {
    id: 'alert',
    header: 'Alerts',
    cell: (row) => {
      return row.getValue() 
              ? <Chip 
                  label={row.getValue() ? row.getValue() : '-'} 
                  sx={{ background: appColors.errorDark, color: "#fff" }}
                /> 
              : '-'
    },
    enableGlobalFilter: true,
    enableColumnFilter: false,
  }),
  columnHelper.accessor('alertDate', {
    id: 'daysSinceAlertStarted',
    header: '# of Days since Alert Started',
    cell: (row) => row.getValue() !== null ? dateDifference(new Date(), row.getValue()) : '-',
    enableGlobalFilter: true,
    enableColumnFilter: false,
  }),
];
