import type { ReactNode } from 'react';
import { styled } from '@mui/material/styles';
import { useLayout } from './layoutContext';

interface MainProps {
  open?: boolean;
  sidebarWidth?: number;
  collapseToWidth?: number;
  children?: ReactNode;
}

const StyledMain = styled('main', {
  shouldForwardProp: (prop) =>
    prop !== 'open' && prop !== 'sidebarWidth' && prop !== 'collapseToWidth',
})<MainProps>(({ theme, open, sidebarWidth, collapseToWidth }) => ({
  flexGrow: 1,
  padding: theme.spacing(2),
  paddingBottom: theme.spacing(5),
  transition: theme.transitions.create('margin', {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  width: `calc(100% - ${collapseToWidth}px)`,
  overflowY: 'auto',
  minHeight: '100vh',
  // height: '100vh',
  marginLeft: collapseToWidth ? 0 : `-${sidebarWidth}px`,
  ...(open && {
    transition: theme.transitions.create('margin', {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.enteringScreen,
    }),
    marginLeft: 0,
  }),
}));

const MainOffset = styled('div')(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  padding: theme.spacing(0, 1),
  // necessary for content to be below app bar
  ...theme.mixins.toolbar,
  justifyContent: 'flex-end',
}));

function Main({ children }: Pick<MainProps, 'children'>) {
  const { sidebarOpen, sidebarWidth, collapseToWidth } = useLayout();

  return (
    <StyledMain
      open={sidebarOpen}
      sidebarWidth={sidebarWidth}
      collapseToWidth={collapseToWidth}
    >
      <MainOffset />
      {children}
    </StyledMain>
  );
}

export default Main;
