import { createTheme, ThemeProvider } from '@mui/material/styles';
import CssBaseline from '@mui/material/CssBaseline';
import { ReactNode } from 'react';

export const appColors = {
  darkestGray: '#1A2225',
  darkGray: '#222D31',
  mediumGray: '#596164',
  lightGray: '#E1E1E1',
  titleGray: '#363637',
  coolWhite: '#EBEBEB',
  lightRed: '#F0DCE0',
  success: '#28A745',
  successDark: '#1E8449',
  errorDark: '#C94434',
  warning: '#F39C11',
  warningDark: '#DD8E0F',
  inputBorder: 'rgba(0, 0, 0, 0.23)',
  sidebarBg: '#121436',
  sidebarNavActiveBg: 'white',
  text: '#222',
  tableHeaderBg: '#B2B2B2',
  tableArrowInActive: '#8D8E96',
  tableArrowActive: '#121436',
  pbWarn: '#FF9500',
  pbCritical: '#C44343',
  titleColor: '#272A74',
  labelColor: '#272A74',
  valueColor: '#121436',
  lightViolet: '#5A5A73',
  chartBlue: '#4372C4',
  chartOrange: '#ED7D31',
  chartGray: '#A5A5A5',
  chartRed: '#C44343',
  primary: '#48C0EB',
  bluishWhite: '#F0F5FF',
  darkerBluishWhite: '#DDE8FF',
  offWhite: '#F8F9FA',
  dark: '#222529',
  mediumDark: '#707070',
  lightDark: '#D0D0D0',
  lightestDark: '#00000029',
  textGray: '#9D9D9D',
  error: '#E84040',
  invalid: '#fecaca',
  formText: '#4C4C4C',
  chartYellow: '#f5c242'
};

export const appStyles = {
  shadow: 'rgba(0, 0, 0, 0.04) 0px 3px 5px',
};
const theme = createTheme({
  palette: {
    primary: {
      main: '#48C0EB',
    },
    info: {
      main: '#121436',
    },
    secondary: {
      main: '#717171',
    },
    success: {
      main: '#28A745',
      dark: '#1E7E34',
    },
    error: {
      main: '#DD4C39',
    },
    text: {
      primary: '#222222',
      secondary: '#9a9a9a',
    },
  },
  components: {
    MuiButton: {
      defaultProps: {
        variant: 'contained',
        disableElevation: true,
      },
      styleOverrides: {
        containedPrimary: {
          textTransform: 'capitalize',
          color: 'white',
        },
      },
    },
    MuiTextField: {
      styleOverrides: {
        root: {
          '&.MuiTextField-root': {
            '& fieldset': {
              borderColor: appColors.primary,
            },
            '&:hover fieldset': {
              borderColor: appColors.primary,
            },
            '&.Mui-focused  fieldset': {
              borderColor: appColors.primary,
            },
            '&.app-search': {
              '& ~ fieldset': {
                borderColor: appColors.primary,
              },
              '&:hover ~ fieldset': {
                borderColor: appColors.primary,
              },
              '&.Mui-focused ~ fieldset': {
                borderColor: appColors.primary,
              },
            },
            '&.error': {
              '& fieldset': {
                borderColor: appColors.error,
              },
              '&:hover fieldset': {
                borderColor: appColors.error,
              },
              '&.Mui-focused fieldset': {
                borderColor: appColors.error,
              },
            },

            '&.success': {
              '& fieldset': {
                borderColor: appColors.success,
              },
              '&:hover fieldset': {
                borderColor: appColors.success,
              },
              '&.Mui-focused fieldset': {
                borderColor: appColors.success,
              },
            },

            '& .MuiOutlinedInput-input': {
              '&::-webkit-outer-spin-button': {
                WebkitAppearance: 'none',
                margin: 0,
              },
              '&::-webkit-inner-spin-button': {
                WebkitAppearance: 'none',
                margin: 0,
              },
              '&[type=number]': {
                MozAppearance: 'textfield',
              },
            },
            '& ~ fieldset': {
              borderColor: appColors.primary,
            },
            '&:hover ~ fieldset': {
              borderColor: appColors.primary,
            },
            '&.Mui-focused ~ fieldset': {
              borderColor: appColors.primary,
            },
          },
        },
      },
    },
    MuiOutlinedInput: {
      defaultProps: {
        size: 'small',
      },
      styleOverrides: {
        root: {
          '&.MuiOutlinedInput-root': {
            '& ~ fieldset': {
              borderColor: appColors.primary,
            },
            '&:hover ~ fieldset': {
              borderColor: appColors.primary,
            },
            '&.Mui-focused ~ fieldset': {
              borderColor: appColors.primary,
            },
          },
        },
        sizeSmall: {
          '&.input-thin': {
            height: 32,
            fontSize: 14,

            '& .MuiOutlinedInput-input': {
              padding: '4px 14px',
            },
          },
        },
      },
    },
    MuiSelect: {
      styleOverrides: {
        outlined: {
          // backgroundColor: "salmon",
          '& ~ fieldset': {
            borderColor: appColors.primary,
          },
          '&:hover fieldset': {
            borderColor: appColors.primary,
          },
          '&.Mui-focused  fieldset': {
            borderColor: appColors.primary,
          },
        },
      },
    },
  },
});
export default function AppThemeProvider({
  children,
}: {
  children: ReactNode;
}) {
  return (
    <ThemeProvider theme={theme}>
      <CssBaseline />
      {children}
    </ThemeProvider>
  );
}
