import { NavLink } from 'react-router-dom';

import { Stack, Typography } from '@mui/material';
import { styled } from '@mui/system';

type DeploymentStatus = 'On Rent' | 'Available' | 'Off Rent' | 'In Transit';

interface DeploymentStatusCardProps {
  status: DeploymentStatus;
  value: number;
  percentage: number;
  path: string;
}

const colorMap: Record<DeploymentStatus, string> = {
  'On Rent': '#FF9500',
  'Available': '#007AFF',
  'Off Rent': '#AF52DE',
  'In Transit': '#00C7BE',
};

const StyledLink = styled(NavLink, {
  shouldForwardProp: (prop) => prop !== 'color',
})<{ color: string }>(({ theme, color }) => ({
  textDecoration: 'none',
  color,
  flex: 1,
  borderRadius: theme.spacing(2),
  border: `3px solid ${color}`,

  '&:hover': {
    backgroundColor: color,
    color: 'white',
  },

  '&.active': {
    backgroundColor: color,
    color: 'white',
  },
}));

function DeploymentStatusCard(props: DeploymentStatusCardProps) {
  const { status, value, percentage, path } = props;

  const color = colorMap[status];

  return (
    <StyledLink to={path} color={color}>
      <Stack p={1.5} alignItems='center'>
        <Typography fontWeight='bold' fontSize={18} alignSelf='flex-start'>
          {status}
        </Typography>
        <Typography fontWeight='bold' fontSize={40}>
          {value}
        </Typography>
        <Typography fontWeight='bold'>{percentage} %</Typography>
      </Stack>
    </StyledLink>
  );
}

export default DeploymentStatusCard;
