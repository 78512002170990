import AppBar, { AppBarProps } from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import IconButton from '@mui/material/IconButton';
import MenuIcon from '@mui/icons-material/Menu';
import { styled } from '@mui/material/styles';
import { useLayout } from './layoutContext';

interface HeaderProps extends AppBarProps {
  open?: boolean;
  sidebarWidth?: number;
  collapseToWidth?: number;
}

const StyledHeader = styled(AppBar, {
  shouldForwardProp: (prop) =>
    prop !== 'open' && prop !== 'sidebarWidth' && prop !== 'collapseToWidth',
})<HeaderProps>(({ theme, open, sidebarWidth, collapseToWidth }) => ({
  transition: theme.transitions.create(['margin', 'width'], {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  width: `calc(100% - ${collapseToWidth}px)`,
  marginLeft: `${collapseToWidth}px`,
  ...(open && {
    width: `calc(100% - ${sidebarWidth}px)`,
    marginLeft: `${sidebarWidth}px`,
    transition: theme.transitions.create(['margin', 'width'], {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.enteringScreen,
    }),
  }),
}));

const Header = (props: AppBarProps) => {
  const { sidebarOpen, toggleSidebar, sidebarWidth, collapseToWidth } =
    useLayout();

  return (
    <StyledHeader
      position='fixed'
      open={sidebarOpen}
      sidebarWidth={sidebarWidth}
      collapseToWidth={collapseToWidth}
      {...props}
    >
      <Toolbar>
        <IconButton
          color='inherit'
          aria-label={`${sidebarOpen ? 'close sidebar' : 'open sidebar'}`}
          onClick={toggleSidebar}
          edge='start'
          sx={{ mr: 2 }}
        >
          <MenuIcon />
        </IconButton>
        {props.children}
      </Toolbar>
    </StyledHeader>
  );
};

export default Header;
