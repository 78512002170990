import { BaseSyntheticEvent, Dispatch, SetStateAction } from 'react';
import AppDialog from '../../../../components/common/AppDialog';
import Stack from '@mui/material/Stack';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';

const EURWarning = ({ setAlertToOpen, handleSubmit, openAlert } : { setAlertToOpen: Dispatch<SetStateAction<boolean>>, handleSubmit: (e?: BaseSyntheticEvent<object, any, any> | undefined) => Promise<void>, openAlert: boolean }) => {
  
  const handleClose = () => setAlertToOpen(false);

  return (
    <AppDialog open={openAlert} onDialogClose={handleClose} title='Warning' maxWidth='sm' titleAlignment="center">
      <Stack component='form' height='100%' bgcolor='white' py={3} borderRadius={1}>
        <Stack px={3} height='100%' overflow='auto' gap={5}>
          <Stack direction='column' width="100%" justifyContent='center' margin='auto' gap={3}>
            <Typography textAlign="center" fontSize={14}>
              The Date you have chosen is more than 1 day from the Previous EUR date, do you want to continue?
            </Typography>
            <Stack direction="row" justifyContent="center" gap={1}>
              <Button 
                onClick={handleSubmit}
                sx={{ width:"100px" }}
              >
                Yes
              </Button>
              <Button 
                variant="outlined" 
                sx={{ width:"100px" }}
                onClick={() => handleClose()}
              >
                No
              </Button>
            </Stack>
          </Stack>
        </Stack>
      </Stack>
    </AppDialog>
  );
}

export default EURWarning;