import { EET } from '../../modules/Reports/EquipmentCostReports/utils/types';
import { apiClient, apiEndpoints } from '../apiClient';

export type GetManyReturnType = {
  status: string;
  data: EET[];
};

const getAllEET = async () => {
  const response = await apiClient.get<GetManyReturnType>(apiEndpoints.eet);
  return response.data.data;
};

const eetApi = {
    getAllEET
};

export default eetApi;