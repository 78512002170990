import { RouteObject } from 'react-router-dom';
import AvailableListing from './Available/AvailableListing';
import DashboardPage from './DashboardPage';
import InTransitListing from './InTransit/InTransitListing';
import OffRentListing from './OffRent/OffRentListing';
import OnRentListing from './OnRent/OnRentListing';

const DashboardRoutes: RouteObject = {
  path: 'home',
  element: <DashboardPage />,
  children: [
    {
      path: 'on-rent',
      element: <OnRentListing />,
    },
    {
      path: 'available',
      element: <AvailableListing />,
    },
    {
      path: 'off-rent',
      element: <OffRentListing />,
    },
    {
      path: 'in-transit',
      element: <InTransitListing />,
    },
  ],
};

export default DashboardRoutes;
