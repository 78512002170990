import { useNavigate } from 'react-router-dom';
import { createColumnHelper } from '@tanstack/react-table';
import { IEORRColumns } from '../utils/types';
import { dateDifference } from '../../../../utils/helpers';
import { CreatePickUpActionButton } from '../../../../components/common/primebmd-ui/EditActionButton';
import Stack from '@mui/material/Stack';
import Switch from '@mui/material/Switch';
import moment from 'moment';

const columnHelper = createColumnHelper<IEORRColumns>();

export const useCreateEMEORRColumns = () => {
  const emEORRColumns = [
    columnHelper.accessor('eorrNo', {
      id: 'eorrNo',
      header: 'EORR No.',
      cell: (row) => row.getValue(),
      enableColumnFilter: false,
    }),
    columnHelper.accessor('eorrDate', {
      id: 'eorrDate',
      header: 'EORR Date',
      cell: (row) => moment(row.getValue()).format('MM/DD/yyyy'),
      enableGlobalFilter: true,
      enableColumnFilter: true,
    }),
    columnHelper.accessor('project', {
      id: 'project',
      header: 'Project',
      cell: (row) => row.renderValue(),
      enableGlobalFilter: true,
      enableColumnFilter: true,
    }),
    columnHelper.accessor('equipmentNo', {
      id: 'equipmentNo',
      header: 'Equipment No.',
      cell: (row) => row.renderValue(),
      enableGlobalFilter: true,
      enableColumnFilter: true,
    }),
    columnHelper.accessor('eorrRequestedPUD', {
      id: 'eorrRequestedPUD',
      header: 'Requested Pickup Date',
      cell: (row) => moment(row.getValue()).format('MM/DD/yyyy'),
      enableGlobalFilter: true,
      enableColumnFilter: true,
    }),
    columnHelper.display({
      id: 'daysSinceCreated',
      header: 'Days Since EORR Created',
      cell: ({ row }) => dateDifference(new Date(), row.original.eorrDate),
      enableHiding: false,
    }),
    columnHelper.accessor('eorrPickUpNo', {
      id: 'eorrPickUpNo',
      header: 'EORR Pick Up No.',
      cell: (row) => row.renderValue(),
      enableGlobalFilter: true,
      enableColumnFilter: true,
    }),
    columnHelper.accessor('eorrTruckNo', {
      id: 'eorrTruckNo',
      header: 'EORR Truck No.',
      cell: (row) => row.renderValue(),
      enableGlobalFilter: true,
      enableColumnFilter: true,
    }),
    columnHelper.accessor('eorrEPUD', {
      id: 'eorrEPUD',
      header: 'EORR Estimated Pick Up Date',
      cell:  (row) => row.getValue() ? moment(row.getValue()).format('MM/DD/yyyy'): '-',
      enableGlobalFilter: true,
      enableColumnFilter: true,
    }),
    columnHelper.display({
      id: 'actions',
      header: 'Action',
      cell: ({ row }) => (
        <Stack direction="column" gap={1} alignItems="center">
          <CreatePickUpActionButton 
            route={`/my-equipment-requests/my-eorr/create-pickup/${row.original.id}`} 
            disabled={row.original.eorrPickUpNo !== undefined}
            label={row.original.eorrPickUpNo !== undefined ? 'Pick Up Created' : 'Create Pick Up'}
          />
        </Stack>
      ),
      enableHiding: false,
    }),
  ];

  return emEORRColumns;
}

export const useCreatePMEORRColumns = () => {
  const navigate = useNavigate();
  const label = { inputProps: { 'aria-label': 'Picked up from site' } };

  const pmEORRColumns = [
    columnHelper.accessor('equipmentNo', {
      id: 'equipmentNo',
      header: 'Equipment No.',
      cell: (row) => row.renderValue(),
      enableGlobalFilter: true,
      enableColumnFilter: true,
    }),
    columnHelper.accessor('eorrNo', {
      id: 'eorrNo',
      header: 'EORR No.',
      cell: (row) => row.getValue(),
      enableColumnFilter: false,
    }),
    columnHelper.accessor('eorrDate', {
      id: 'eorrDate',
      header: 'EORR Date',
      cell: (row) => moment(row.getValue()).format('MM/DD/yyyy'),
      enableGlobalFilter: true,
      enableColumnFilter: true,
    }),
    columnHelper.accessor('eorrRequestedPUD', {
      id: 'eorrRequestedPUD',
      header: 'Requested Pickup Date',
      cell: (row) => moment(row.getValue()).format('MM/DD/yyyy'),
      enableGlobalFilter: true,
      enableColumnFilter: true,
    }),
    columnHelper.display({
      id: 'daysSinceCreated',
      header: 'Days Since EORR Created',
      cell: ({ row }) => dateDifference(new Date(), row.original.eorrDate),
      enableHiding: false,
    }),
    columnHelper.accessor('eorrPickUpNo', {
      id: 'eorrPickUpNo',
      header: 'EORR Pick Up No.',
      cell: (row) => row.renderValue(),
      enableGlobalFilter: true,
      enableColumnFilter: true,
    }),
    columnHelper.accessor('eorrTruckNo', {
      id: 'eorrTruckNo',
      header: 'EORR Truck No.',
      cell: (row) => row.renderValue(),
      enableGlobalFilter: true,
      enableColumnFilter: true,
    }),
    columnHelper.accessor('eorrEPUD', {
      id: 'eorrEPUD',
      header: 'EORR Estimated Pick Up Date',
      cell: (row) => row.getValue() ? moment(row.getValue()).format('MM/DD/yyyy'): '-',
      enableGlobalFilter: true,
      enableColumnFilter: true,
    }),
    columnHelper.display({
      id: 'pickedUpFromSite',
      header: 'Picked Up from Site?',
      cell: ({ row }) => (
        <>
          <Switch 
            {...label} 
            checked={row.original.pickedUpFromSite && row.original.pickedUpFromSite === true}
            onChange={() => navigate(`/my-equipment-requests/my-eorr/picked-up-from-site/${row.original.id}`)}  
            disabled={ row.original.eorrPickUpNo === undefined }
          />
        </>
      ),
      enableHiding: false,
    }),
  ];

  return pmEORRColumns;
}

