import { createColumnHelper } from '@tanstack/react-table';
import { formatDate } from '../../../../utils/helpers';
import { InTransitColumns } from '../utils/types';
import { dateDifference } from '../../../../utils/helpers';
import moment from 'moment';

const columnHelper = createColumnHelper<InTransitColumns>();

const inTransitColumns = [
  columnHelper.accessor('equipmentNo', {
    id: 'equipmentNo',
    header: 'Equipment No.',
    cell: (row ) => row.renderValue(),
    enableGlobalFilter: true,
    enableColumnFilter: true,
  }),
  columnHelper.accessor('allocationDeliveryNo', {
    id: 'allocationDeliveryNo',
    header: 'Allocation Delivery No.',
    cell: (row) => row.renderValue(),
    enableGlobalFilter: true,
    enableColumnFilter: true,
  }),
  columnHelper.accessor('allocationDeliveryDate', {
    id: 'allocationDeliveryDate',
    header: 'Allocation Delivery Creation Date',
    cell: ({ row }) => formatDate(row.original.allocationDeliveryDate),
    enableGlobalFilter: true,
    enableColumnFilter: true,
  }),
  columnHelper.accessor('allocationPickUpLocation', {
    id: 'allocationPickUpLocation',
    header: 'Allocation Pickup Location',
    cell: (row) => row.renderValue(),
    enableGlobalFilter: true,
    enableColumnFilter: true,
  }),
  columnHelper.accessor('allocationTruckNo', {
    id: 'allocationTruckNo',
    header: 'Allocation Truck No.',
    cell: (row) => row.renderValue(),
    enableGlobalFilter: true,
    enableColumnFilter: true,
  }),
  columnHelper.accessor('allocationEPUD', {
    id: 'allocationEPUD',
    header: 'Allocation Estimated Pickup Date',
    cell: (row) => moment(row.getValue()).format('MM/DD/yyyy'),
    enableGlobalFilter: true,
    enableColumnFilter: true,
  }),
  columnHelper.accessor('allocationDropOffLocation', {
    id: 'allocationDropOffLocation',
    header: 'Allocation Drop Off Location',
    cell:  (row) => row.renderValue(),
    enableGlobalFilter: true,
    enableColumnFilter: true,
  }),
  columnHelper.accessor('allocationETA', {
    id: 'allocationETA',
    header: 'Allocation ETA at Drop Off',
    cell: (row) => moment(row.getValue()).format('MM/DD/yyyy'),
    enableGlobalFilter: true,
    enableColumnFilter: true,
  }),
  columnHelper.display({
    id: 'daysOutstanding',
    header: 'Days Outstanding',
    cell: ({ row }) => dateDifference(row.original.allocationETA, new Date().toISOString()),
    enableHiding: false,
  }),
];

export default inTransitColumns;