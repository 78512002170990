import { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { Controller, useFieldArray, useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import Stack from '@mui/material/Stack';
import Button from '@mui/material/Button';
import {
  IOperatorLicenseForm,
  operatorLicenseInitialValues,
  operatorLicenseSchema,
} from './OperatorLicensesUtils';
import AppDatePicker from '../../../components/common/forms/AppDatePicker';
import AppTextField from '../../../components/common/forms/AppTextField';
import AppPrimeUploadForm from '../../../components/common/forms/AppPrimeUploadForm';
import AppDialog from '../../../components/common/AppDialog';
import LoadingIndicator from '../../../components/common/LoadingIndicator';
import { 
  useGetOperatorLicensesById,
  useCreateOperatorLicenses,
  useUpdateOperatorLicenses,
  useGetOperatorById
} from '../../../hooks/useOperatorLicenses';

const OperatorLicenseForm = () => {
  const operator = useGetOperatorById();
  const operatorData = operator.data;
  const licenseToUpdate = useGetOperatorLicensesById();
  const licenseToUpdateData = licenseToUpdate.data;
  const createOperatorLicense = useCreateOperatorLicenses();
  const updateOperatorLicense = useUpdateOperatorLicenses();
  const [fileName, setFileName] = useState('');
  const navigate = useNavigate();

  const handleClose = () => navigate('/operator-licenses', { replace: true });

  const {
    register,
    handleSubmit,
    control,
    watch,
    setValue,
    formState: { errors, isValid },
  } = useForm<IOperatorLicenseForm>({
    defaultValues: operatorLicenseInitialValues,
    mode: 'onChange',
    resolver: yupResolver(operatorLicenseSchema),
  });

  useEffect(() => {
    if (!licenseToUpdateData) return;

    const {
      operatorName,
      operatorType,
      licenseType,
      certificateName,
      certificateNo,
      governmentBody,
      issuingAgency,
      issuanceDate,
      validityDate
    } = licenseToUpdateData;

    setValue("operatorName", operatorName);
    setValue("operatorType", operatorType);
    setValue("licenseType", licenseType);
    setValue("certificateName", certificateName);
    setValue("certificateNo", certificateNo);
    setValue("governmentBody", governmentBody);
    setValue("issuingAgency", issuingAgency);
    setValue("issuanceDate", issuanceDate);
    setValue("validityDate", validityDate);

  }, [licenseToUpdateData, setValue]);

  useEffect(() => {
    if (!operatorData) return;

    const { operatorName, operatorType, licenseType } = operatorData

    setValue("operatorName", operatorName);
    setValue("operatorType", operatorType);
    setValue("licenseType", licenseType);

  }, [operatorData, setValue]);

  const onSubmit = (formData: IOperatorLicenseForm) => {
    const newFormData = {
      ...formData,
      operatorId: licenseToUpdateData ? licenseToUpdateData?.operatorId : operatorData?.id
    }

    if (licenseToUpdateData) {
      updateOperatorLicense.mutate(newFormData);
    }

    if (!licenseToUpdateData && operatorData) {
      createOperatorLicense.mutate(newFormData);
    }

    navigate('/operator-licenses', { replace: true });
  };

  const mediaFiles = watch('media');
  const { append } = useFieldArray({ control, name: 'media' });

  const handleMediaUpload = (files: FileList | null) => {
    if (!files) return;

    // check for possible duplicate files (lookup value is filename)
    const emailFilenames = mediaFiles.map((item) => item.fileName);

    for (let i = 0; i < files.length; i++) {
      if (emailFilenames.includes(files[i].name)) {
        return console.log(`${files[i].name} already exists`);
      }
    }

    Array.from(files).forEach((file) => {
      append({
        fileName: file.name,
        source: file,
      });
    });
    setFileName(files[0].name);
  };

  if (updateOperatorLicense.isLoading || createOperatorLicense.isLoading) {
    return <LoadingIndicator show />;
  }

  return (
    <AppDialog open onDialogClose={handleClose} title='Log License' maxWidth='lg'>
      <Stack component='form' height='100%' bgcolor='white' py={3} borderRadius={1}>
        <Stack px={3} height='100%' overflow='auto' gap={5}>
          <Stack direction='column' gap={3} width={550} justifyContent='center' margin='auto'>
            <Stack direction="row" width="100%" gap={2}>
              <Controller
                control={control}
                name='issuanceDate'
                render={({ field: { value, onChange } }) => (
                  <AppDatePicker
                    name='issuanceDate'
                    value={value}
                    onChange={onChange}
                    required
                    label='Issuance Date'
                    labelPosition="top"
                    labelAlignment="left"
                    labelWidth='100%'
                    fieldWidth= '100%'
                    error={!!errors.issuanceDate}
                    helperText={errors.issuanceDate?.message}
                  />
                )}
              />
              <Controller
                control={control}
                name='validityDate'
                render={({ field: { value, onChange } }) => (
                  <AppDatePicker
                    name='validityDate'
                    value={value}
                    onChange={onChange}
                    required
                    label='Validity Date'
                    labelPosition="top"
                    labelAlignment="left"
                    labelWidth='100%'
                    fieldWidth= '100%'
                    error={!!errors.validityDate}
                    helperText={errors.validityDate?.message}
                  />
                )}
              />
            </Stack>
            <Stack direction="row" width="100%" gap={2}>
              <AppTextField
                label='Operator Name'
                labelPosition="top"
                labelAlignment="left"
                labelWidth='100%'
                fieldWidth= '100%'
                value={licenseToUpdateData?.operatorName || operatorData?.operatorName}
                disabled={true}
              />
              <AppTextField
                label='Operator Type'
                labelPosition="top"
                labelAlignment="left"
                labelWidth='100%'
                fieldWidth= '100%'
                value={licenseToUpdateData?.operatorType || operatorData?.operatorType}
                disabled={true}
              />
            </Stack>
            <AppTextField
              label='License Type'
              labelPosition="top"
              labelAlignment="left"
              labelWidth='100%'
              fieldWidth= '100%'
              value={licenseToUpdateData?.licenseType || operatorData?.licenseType}
              disabled={true}
            />
            <Stack direction="row" width="100%" gap={2}>
              <AppTextField
                label='Certificate Name'
                labelPosition="top"
                labelAlignment="left"
                labelWidth='100%'
                fieldWidth= '100%'
                required={true}
                {...register('certificateName')}
              />
              <AppTextField
                label='Certificate No.'
                labelPosition="top"
                labelAlignment="left"
                labelWidth='100%'
                fieldWidth= '100%'
                required={true}
                {...register('certificateNo')}
              />
            </Stack>
            <Stack direction="row" width="100%" gap={2}>
              <AppTextField
                label='Government Body'
                labelPosition="top"
                labelAlignment="left"
                labelWidth='100%'
                fieldWidth= '100%'
                // required={true}
                {...register('governmentBody')}
              />
              <AppTextField
                label='Issuing Agency'
                labelPosition="top"
                labelAlignment="left"
                labelWidth='100%'
                fieldWidth= '100%'
                // required={true}
                {...register('issuingAgency')}
              />
            </Stack>
            <AppPrimeUploadForm
              label='Attached File'
              labelPosition="top"
              labelAlignment="left"
              labelWidth='0%'
              fieldWidth= '100%'
              mt='0px'
              fileName={fileName}
              key={mediaFiles.length}
              onChange={(e) => handleMediaUpload(e.currentTarget.files)}
            />
            <Stack direction="row" justifyContent="center">
              <Button 
                onClick={handleSubmit(onSubmit)} 
                disabled={!isValid}
                sx={{ width: 150 }}
              >
                Save
              </Button>
            </Stack>
          </Stack>
        </Stack>
      </Stack>
    </AppDialog>
  );
}

export default OperatorLicenseForm;