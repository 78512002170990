import { useState } from 'react';

import { Box, ButtonBase, Collapse, Stack, Typography } from '@mui/material';
import { ChevronRight, ExpandMore } from '@mui/icons-material';
import { appColors } from '../../theme';
import WorkStatusCard from './WorkStatusCard';

function WorkStatusSection() {
  const [expanded, setExpanded] = useState(true);

  const toggleExpanded = () => setExpanded((state) => !state);

  return (
    <Box mt={2}>
      <ButtonBase
        onClick={toggleExpanded}
        sx={{
          color: appColors.titleColor,
          padding: '4px 8px',
          borderRadius: '4px',
          marginBottom: '16px',
        }}
      >
        <Typography fontSize={20} fontWeight='bold'>
          Ready to Work Status
        </Typography>
        {expanded ? <ChevronRight /> : <ExpandMore />}
      </ButtonBase>
      <Collapse in={expanded}>
        <Stack direction='row' gap={4}>
          <WorkStatusCard status='Operational' value={123} />
          <WorkStatusCard status='Under Servicing' value={7} />
        </Stack>
      </Collapse>
    </Box>
  );
}

export default WorkStatusSection;
