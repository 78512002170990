import { useMutation, useQuery } from '@tanstack/react-query';
import { queryClient } from '../react-query/queryClient';
import { AxiosError } from 'axios';
import { useNavigate, useParams } from 'react-router';
import { useSnackBar } from '../components/ToastProvider';
import { IEURActivity } from '../modules/MyDashboardsPlusTasks/EUR/utils/types';
import eurActivityApi from '../services/dashboardPlusTasks/eurActivities';

export function useGetEURActivitiesQuery() {
  return useQuery<IEURActivity[]>(['eurActivities'], () => eurActivityApi.getEURActivities(), {
    onError(err) {
      console.log(err);
    },
  });
}

export function useGetEURActivitiesById() {
  const { activityId } = useParams<{ activityId: string }>();
  return useQuery([`eurActivity-${activityId}`, activityId], () => eurActivityApi.getEURActivitiesById(activityId!), {
    enabled : activityId ? true : false
  });
}

export function useGetEURActivitiesByEURNo() {
  const { id } = useParams<{ id: string }>();
  return useQuery(['eurActivities'], () => eurActivityApi.getEURActivitiesByEURSMR(id!));
}

export function useCreateEURActivity() {
  const { showSnackBar } = useSnackBar();
  const { id } = useParams<{ id: string }>();
  const navigate = useNavigate();
  return useMutation((newEURActivity: Partial<IEURActivity>) => eurActivityApi.createEURActivity(newEURActivity), {
    onSuccess(data) {
      queryClient.setQueryData<IEURActivity[]>(['eurActivities'], oldQueryData => oldQueryData && [
        ...oldQueryData,
        data,
      ]);

      showSnackBar('Successfully Created EUR Activity', 'success');
      navigate(`/eur/${id}/activity`, { replace: true });
    },
    onError(err) {
      const errorData = (err as AxiosError<{ error: string }>).response?.data.error;

      if (err && errorData) {
        showSnackBar(errorData, 'error');
      }
    },
  });
}

export function useUpdateEURActivity() {
  const { showSnackBar } = useSnackBar();
  const { id, activityId } = useParams<{ id: string, activityId: string }>();
  const navigate = useNavigate();

  return useMutation((newEURActivity: Partial<IEURActivity>) => eurActivityApi.updateEURActivity(activityId!, newEURActivity), {
    onSuccess() {
      queryClient.invalidateQueries({ queryKey: ['eurActivities'] }).then(() => {
        showSnackBar('Successfully Updated EUR Activity', 'success');
        navigate(`/eur/${id}/activity`, { replace: true });
      });
    },
    onError(err) {
      const errorData = (err as AxiosError<{ error: string }>).response?.data.error;

      if (err && errorData) {
        showSnackBar(errorData, 'error');
      }
    },
  });
}