import { IEURActivity } from '../../modules/MyDashboardsPlusTasks/EUR/utils/types';
import { apiClient, apiEndpoints } from '../apiClient';

export type GetManyReturnType = {
  status: string;
  data: IEURActivity[];
};

type GetOneReturnType = {
  status: string;
  data: IEURActivity;
};

const getEURActivities = async () => {
  const response = await apiClient.get<GetManyReturnType>(apiEndpoints.eurActivities);
  return response.data.data;
};

const getEURActivitiesByEURSMR = async (eurNo: string) => {
  const response = await apiClient.get<GetManyReturnType>(`${apiEndpoints.eurActivities}/${eurNo}/eur-smr`);
  return response.data.data;
};

const getEURActivitiesById = async (id: string) => {
  const response = await apiClient.get<GetOneReturnType>(
    `${apiEndpoints.eurActivities}/${id}`
  );
  return response.data.data;
};

const createEURActivity = async (newEURActivityData: Partial<IEURActivity>) => {
  const response = await apiClient.post<GetOneReturnType>(
    apiEndpoints.eurActivities,
    newEURActivityData
  );
  return response.data.data;
};

const updateEURActivity = async (id: string, updateEURActivityData: Partial<IEURActivity>) => {
  const response = await apiClient.patch<GetOneReturnType>(
    `${apiEndpoints.eurActivities}/${id}`,
    updateEURActivityData
  );

  return response.data.data;
};

const deleteEURActivity = async (id: string) => {
  const response = await apiClient.delete(`${apiEndpoints.eurActivities}/${id}`);
  return response.data.data;
};

const eurActivityApi = {
  getEURActivities,
  getEURActivitiesByEURSMR,
  getEURActivitiesById,
  createEURActivity,
  updateEURActivity,
  deleteEURActivity
};

export default eurActivityApi;