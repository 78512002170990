import PageContainer from '../../../../components/common/PageContainer';

function LaborTypePage() {
  return (
    <>
      <PageContainer title='Servicing Databases' subTitle='Labor Type + Rate Master' showDate>
        Labor Type + Rate Master
      </PageContainer>
    </>
  );
}

export default LaborTypePage;
