import { forwardRef } from 'react';
import Typography from '@mui/material/Typography';
import FormHelperText from '@mui/material/FormHelperText';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select, { SelectProps } from '@mui/material/Select';

import ArrowDownIcon from '@mui/icons-material/ExpandMoreOutlined';

import { SxProps } from '@mui/system';
import { appColors } from '../../../theme';
import { SelectOptionType } from '../forms/form-types';
import { Stack } from '@mui/material';

interface OwnSelectProps {
  label: string;
  labelPosition?: "top" | "left";
  labelAlignment?: "center" | "inherit" | "right" | "left" | "justify";
  labelWidth?: string;
  fieldWidth?: string;
  fontWeight?: number;
  options?: SelectOptionType[];
  placeholderColor?: string;
  helperText?: string;
  containerStyles?: SxProps;
}

type AppSelectProps = OwnSelectProps &
  Omit<SelectProps, 'label | containerStyles'>;

const AppSelect = forwardRef<HTMLInputElement, AppSelectProps>(
  (
    { 
      label, 
      options, 
      placeholderColor, 
      helperText, 
      containerStyles, 
      labelWidth, 
      fieldWidth, 
      labelPosition,
      labelAlignment,
      fontWeight,
      ...rest 
    },
    ref
  ) => {
    return (
      <FormControl sx={{ ...containerStyles, width: "100%" }}>
        <Stack 
          direction={labelPosition && labelPosition === 'top' ? 'column' : 'row'} 
          gap={labelPosition && labelPosition === 'top' ? .50 : 1.5} 
          justifyContent='space-between' 
          alignItems={labelPosition && labelPosition === 'top' ? 'flex-start' : 'center'}
        >
          <Typography
            color={appColors.formText}
            component='label'
            htmlFor={rest.name}
            // mt={1.5}
            fontSize={labelPosition && labelPosition === 'top' ? 13 : 15}
            width={labelWidth ? labelWidth : 130}
            fontWeight={fontWeight ? fontWeight : 600}
            align={labelAlignment ? labelAlignment : 'right'}
            whiteSpace='nowrap'
          >
            {label}
          </Typography>
          <Stack width={fieldWidth ? fieldWidth : 400}>
            <Select
              id={rest.name}
              ref={ref}
              {...rest}
              size='small'
              displayEmpty
              IconComponent={ArrowDownIcon}
              sx={{
                fontSize: 14,
                '& .MuiSvgIcon-root': { color: 'primary.main' },
                ...rest.sx,
              }}
              renderValue={(selected) => {
                if (!selected) {
                  return (
                    <Typography
                      component='small'
                      fontSize={14}
                      fontWeight={400}
                      color={appColors.textGray}
                    >
                      {rest.placeholder}
                    </Typography>
                  );
                }
                return options?.find((i) => i.value === (selected as string))
                  ?.label;
              }}
            >
              <MenuItem disabled value=''>
                <Typography
                  component='small'
                  fontSize={14}
                  fontWeight={400}
                  color={placeholderColor ? placeholderColor : '#000'}
                >
                  {rest.placeholder}
                </Typography>
              </MenuItem>
              {options &&
                options.map((item) => (
                  <MenuItem
                    key={item.id}
                    value={item.value}
                    sx={{ width: '100%', fontSize: 14 }}
                  >
                    {item.label}
                  </MenuItem>
                ))}
            </Select>
            {rest.error && (
              <FormHelperText
                sx={{ color: (theme) => theme.palette.error.main }}
              >
                {helperText}
              </FormHelperText>
            )}
          </Stack>
        </Stack>
      </FormControl>
    );
  }
);

export default AppSelect;
