import { IEUR } from '../modules/Reports/EquipmentUseAndPerformanceReports/utils/types';
import moment from 'moment';

export const useExportEUR = (data: IEUR[]) => {
  const newEURList = data.map(e => (
    {
      date: moment(e.date).format('MM/DD/yyyy'),
      eurNo: e.eurNo,
      equipmentNo: e.equipmentNo,
      operator: e.operator,
      location: e.location,
      startingReading: e.startingReading,
      endingReading: e.endingReading
    }
  ));

  return newEURList;
}