import { useState } from 'react';
import { useNavigate } from 'react-router';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { ForgotPasswordField } from './utils/types';
import { ForgotPasswordInitialValues, ForgotPasswordSchema } from './utils/schema';
import { useForgotPassword } from '../../hooks/useAuth';
import { appColors } from '../../theme/index';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Stack from '@mui/material/Stack';
import TextField from '@mui/material/TextField';
import Typography from '@mui/material/Typography';
import PrimeLogo from '../../assets/images/logo-full.png';
import AuthActionButton from '../../components/common/AuthActionButton';

const ForgotPasswordPage = () => {
  const [isSucess, setIsSucess] = useState<boolean>(false);
  const navigate = useNavigate();
  const forgotPassword = useForgotPassword();

  const {
    register,
    handleSubmit,
    formState: { errors, isSubmitting, isValid },
  } = useForm<ForgotPasswordField>({
    defaultValues: ForgotPasswordInitialValues,
    resolver: yupResolver(ForgotPasswordSchema),
    mode: 'onChange',
  });

  const onSubmit = ({ email }: ForgotPasswordField) => {
    forgotPassword.mutate(email);
    setIsSucess(true);
  };

  const goToLogin = () => navigate('/auth');

  return (
    <Box
      sx={{
        padding: 6,
        boxShadow: '0px 0px 15px rgba(0,0,0,0.15)',
        margin: 10,
        borderRadius: 2,
        bgcolor: 'white',
      }}
    >
      <Stack mb={4}>
        <img
          src={PrimeLogo}
          alt='primebmd'
          width='auto'
          height={75}
          style={{ alignSelf: 'center' }}
        />
      </Stack>
      <Stack component='form' onSubmit={handleSubmit(onSubmit)} sx={{ width: '300px' }} spacing={2}>
        {
          !isSucess && !forgotPassword.isSuccess || forgotPassword.isLoading
          ? <>
              <Typography 
                sx={{ 
                  fontSize: "13px",
                  color: "#000000",
                  textAlign: "center",
                }}
              >
                Kindly input your email address and we'll send you a link to reset your password.
              </Typography>
              <Stack spacing={1} width='300px' mb={1}>
                <Typography fontSize={13} component='label' htmlFor='email'>
                  Email
                </Typography>
                <TextField
                  size='small'
                  placeholder='Type here'
                  {...register('email')}
                  error={!!errors.email}
                  helperText={errors.email?.message}
                  inputProps={{ sx: { fontSize: 14 } }}
                />
              </Stack>
              <Stack gap={2} alignItems='center'>
                <Button
                  disableElevation
                  onClick={goToLogin}
                  sx={{
                    color: appColors.primary,
                    textTransform: "capitalize",
                    borderRadius: "3px",
                    width: "20%",
                    alignSelf: "center",
                    backgroundColor: "transparent",

                    "&:hover": {
                      backgroundColor: "transparent",
                      color: appColors.darkGray,
                    },
                  }}
                >
                  Cancel
                </Button>
                <AuthActionButton
                  label='Submit'
                  isForLogin={false}
                  isLoading={forgotPassword.isLoading}
                  disabled={ isSubmitting || !isValid}
                  width={180}
                />
              </Stack>
            </>
          : <>
              <Stack alignItems='center' spacing={3}>
                <Typography fontSize={13}>
                  An email with the reset link has been sent to your email. Kindly reset your password before logging in.
                </Typography>
                <Button
                  disableElevation
                  onClick={goToLogin}
                  sx={{
                    textTransform: "capitalize",
                    borderRadius: "3px",
                    width: 180,
                    alignSelf: "center"
                  }}
                >
                  Ok
                </Button>
              </Stack>
            </>
        }
      </Stack>
    </Box>
  )
};

export default ForgotPasswordPage;