import { IOnRent, IEXFForm, IEORRForm } from '../../modules/MyDashboardsPlusTasks/MyOnRentEquipments/utils/types';
import { IERF } from '../../modules/MyDashboardsPlusTasks/MyEquipmentRequests/utils/types';
import { apiClient, apiEndpoints } from '../apiClient';

export type GetManyReturnType = {
  status: string;
  data: IOnRent[];
};

type GetOneReturnType = {
  status: string;
  data: IERF;
};

const getOnRentEquipments = async () => {
  const response = await apiClient.get<GetManyReturnType>(
    `${apiEndpoints.epfErf}/on-rents`
  );
  return response.data.data;
};

const createEXF = async (id: string, data: Partial<IEXFForm>) => {
  const response = await apiClient.patch<GetOneReturnType>(
    `${apiEndpoints.epfErf}/request-extension/${id}`,
    data
  );

  return response.data.data;
};

const createEORR = async (id: string, data: Partial<IEORRForm>) => {
  const response = await apiClient.patch<GetOneReturnType>(
    `${apiEndpoints.epfErf}/off-rent/request/${id}`,
    data
  );

  return response.data.data;
};

const onRentApi = {
  getOnRentEquipments,
  createEXF,
  createEORR
};

export default onRentApi;