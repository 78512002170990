import { useQuery } from '@tanstack/react-query';
import { EFCR } from '../modules/Reports/EquipmentCostReports/utils/types';
import efcrApi from '../services/reports/efcr';
import moment from 'moment';

export function useGetEFCRQuery() {
  return useQuery<EFCR[]>(['efcrs'], () => efcrApi.getEFCR(), {
    onError(err) {
      console.log(err);
    },
  });
}

export const useFormatGetEFCRQuery = (data: EFCR[]) => {
    const newEFCRList = [];
    
    const EFCRList = data;
  
    for(let i in EFCRList){
      let json = {
        capacity: EFCRList[i].capacity,
        category: EFCRList[i].category,
        description: EFCRList[i].description,
        equipmentNo: EFCRList[i].equipmentNo,
        frisNo: EFCRList[i].frisNo,
        fuelTruckNo: EFCRList[i].fuelTruckNo,
        make: EFCRList[i].make,
        meterReading: EFCRList[i].meterReading,
        model: EFCRList[i].model,
        operatorName: EFCRList[i].operatorName,
        productFamily: EFCRList[i].productFamily,
        project: EFCRList[i].project,
        quantity: EFCRList[i].quantity,
        unitCost: EFCRList[i].unitCost,
        totalPartsCost: EFCRList[i].totalPartsCost,
        time: EFCRList[i].time,
        completionDate: EFCRList[i].completionDate,
        frisDate: moment(EFCRList[i].frisDate).format('MM/DD/yyyy'),
        ownershipType: EFCRList[i].ownershipType === 'Rental' ? 'Rented' : EFCRList[i].ownershipType
      }
      newEFCRList.push(json);
    }
    return newEFCRList;
  }
  
