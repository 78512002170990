import * as XLSX from 'xlsx';
import { format } from 'date-fns';
import { IEJOColumns, IESRColumns } from '../utils/types';

const ejoMap = {
  'EJO I.D.': 'ejoNumber',
  'Equipment No.' : 'equipmentNo',
  'Project Location' : 'projectLocation',
  'SR No.' : 'serviceRequestNo',
  'Service Request Type' : 'serviceRequestType',
  'Remarks' : 'remarks',
  'Days Elapsed' : 'daysElapsed'
}

const esrMap = {
  'ESR No.' : 'esrNumber',
  'Equipment No.' : 'equipmentNo',
  'Project Location' : 'projectLocation',
  'Service Request Type' : 'serviceRequestType',
  'Remarks' : 'remarks',
  'Days Elapsed' : 'daysElapsed'
}

export const exportEJOToExcel = (data: IEJOColumns[], filename: string) => {
  const headings = [Object.keys(ejoMap)];
  const columnHeaders = [...Object.values(ejoMap)];

  const workbook: XLSX.WorkBook = XLSX.utils.book_new();
  const worksheet: XLSX.WorkSheet = XLSX.utils.json_to_sheet([]);

  XLSX.utils.sheet_add_aoa(worksheet, headings);
  XLSX.utils.sheet_add_json(worksheet, data, {
    origin: 'A2',
    skipHeader: true,
    header: columnHeaders,
  });

  const currentDate = format(Date.now(), 'MM_dd_yyyy');
  const exportedFilename = `${filename}_${currentDate}`;

  XLSX.utils.book_append_sheet(workbook, worksheet, filename);

  const wscols: XLSX.WorkSheet['!cols'] = headings[0].map((h, i) => ({
    wch: 20,
  }));

  worksheet['!cols'] = wscols;

  XLSX.writeFile(workbook, `${exportedFilename}.xlsx`);
};

export const exportESRToExcel = (data: IESRColumns[], filename: string) => {
  const headings = [Object.keys(esrMap)];
  const columnHeaders = [...Object.values(esrMap)];

  const workbook: XLSX.WorkBook = XLSX.utils.book_new();
  const worksheet: XLSX.WorkSheet = XLSX.utils.json_to_sheet([]);

  XLSX.utils.sheet_add_aoa(worksheet, headings);
  XLSX.utils.sheet_add_json(worksheet, data, {
    origin: 'A2',
    skipHeader: true,
    header: columnHeaders,
  });

  const currentDate = format(Date.now(), 'MM_dd_yyyy');
  const exportedFilename = `${filename}_${currentDate}`;

  XLSX.utils.book_append_sheet(workbook, worksheet, filename);

  const wscols: XLSX.WorkSheet['!cols'] = headings[0].map((h, i) => ({
    wch: 20,
  }));

  worksheet['!cols'] = wscols;

  XLSX.writeFile(workbook, `${exportedFilename}.xlsx`);
};