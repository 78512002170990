import { Outlet } from 'react-router-dom';
import PageContainer from '../../../../components/common/PageContainer';
import EFCRTable from './Table';

const EquipmentFuelConsumptionPage = () => {
  return (
    <>
      <PageContainer 
        title='Reports' 
        subTitle='Equipment Cost Reports'
        submoduleTitle='Equipment Fuel Consumption'  
        showDate
      >
        <EFCRTable />
      </PageContainer>
      <Outlet />
    </>
  );
}

export default EquipmentFuelConsumptionPage;