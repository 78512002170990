import { Button, ButtonProps } from '@mui/material';
import AddIcon from '@mui/icons-material/Add';

const FormAddButton = (props: ButtonProps) => {
  return (
    <Button
      disableElevation
      {...props}
      sx={{
        textTransform: 'capitalize',
        fontSize: '9pt ',
        bgcolor: 'primary',
        color: 'white',
        padding: 0.5,
        paddingLeft: 1,
        paddingRight: 1,
        minWidth: 'auto',
        borderRadius: '4px',
        '&:hover': {
          bgcolor: 'primary',
        },
      }}
    >
      <AddIcon />
    </Button>
  );
};

export default FormAddButton;
