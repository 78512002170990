import axios from 'axios';
import { apiEndpoints } from '../services/apiClient'

export const API_URL = process.env.REACT_APP_API_URL;

const axiosInstance = axios.create({
  baseURL: API_URL,
  headers: {
    Accept: 'application/json',
    'Content-Type': 'application/x-www-form-urlencoded',
  },
});

axiosInstance.interceptors.request.use(
  (config) => {
    const storedToken = localStorage.getItem('accessToken');

    let _config = { ...config };

    if (storedToken) {
      const token = JSON.parse(storedToken);
      _config = {
        ...config,
        headers: {
          Authorization: `Bearer ${token}`,
        },
      };
    }
    return _config;
  },
  async (error) => {
    Promise.reject(error);
  }
);

axiosInstance.interceptors.response.use(
  (response) => {
    return response;
  },
  async function (error) {
    const originalRequest = error.config;
    if (error.response.status === 403 && !originalRequest._retry) {
      originalRequest._retry = true;
      const access_token = await refreshAccessToken();
      const config = {
        ...error.config,
        headers: {
          Authorization: `Bearer ${access_token}`,
        },
      };

      return axiosInstance(config);
    }
    return Promise.reject(error);
  }
);

export const refreshAccessToken = async () => {
  const response = await axiosInstance.get(apiEndpoints.refreshToken);
  return response.data.accessToken;
};

interface MediaType {
  originalname: string;
  filename: string;
  path: string;
  size: number;
}

/**
 *
 * @param file file(s) to upload
 * @param module directory identifier where to upload
 * @param type specifies what media type to upload
 * @returns Object that contains file or array of files in object storage form
 */
export const uploadMedia = async (
  file: File | Array<File>,
  module: string,
  type?: string
): Promise<MediaType> => {
  const media = new FormData();
  if (Array.isArray(file)) {
    for (const f of file) media.append('media', f);
  } else media.append('media', file);
  const mediaUploadRes = await axiosInstance.post(
    `upload${
      type ? `?type=${type}` : '?type=json'
    }`,
    media
  );
  // @ts-ignore
  return mediaUploadRes.data.data;
};

/**
 *
 * @param file file(s) to upload
 * @param module directory identifier where to upload
 * @param type specifies what media type to upload
 * @returns Object that contains file or array of files in object storage form
 */
 export const uploadMultipleMedia = async (
  file: File | Array<File>,
  module: string,
  type?: string
): Promise<Array<MediaType>> => {
  const media = new FormData();
  if (Array.isArray(file)) {
    for (const f of file) media.append('media', f);
  } else media.append('media', file);
  const mediaUploadRes = await axiosInstance.post(
    `upload/multiple${
      type ? `?type=${type}` : '?type=json'
    }`,
    media
  );
  // @ts-ignore
  return mediaUploadRes.data.data;
};