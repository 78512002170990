import PageContainer from '../../../../components/common/PageContainer';

function ProjectActivityPage() {
  return (
    <>
      <PageContainer
        title='Project Databases'
        subTitle='Project Activity + Cost Code Master'
        showDate
      >
        Project Activity + Cost Code Master
      </PageContainer>
    </>
  );
}

export default ProjectActivityPage;
