import { Outlet } from 'react-router-dom';
import PageContainer from '../../../../components/common/PageContainer';
import EURActivityTable from './Table';

const EURActivityList = () => {
  return (
    <>
      <PageContainer 
        title='My Dashboard + Tasks' 
        subTitle='My EURs'
        submoduleTitle='Activities'
        showDate
      >
        <EURActivityTable />
      </PageContainer>
      <Outlet />
    </>
  )
}

export default EURActivityList;