import React from 'react';
import AppContent from '../modules/Layout';
import AuthenticatedRoute from './common/AuthenticatedRoute';

const RootLayout = () => {
  return (
    <AuthenticatedRoute>
      <AppContent />
    </AuthenticatedRoute>
  );
};

export default RootLayout;
