import PageContainer from '../../../../components/common/PageContainer';
import ESWWIPTable from './Table';

const ESRWIPPage = () => {
  return (
    <>
      <PageContainer 
        title='My Dashboard + Tasks' 
        subTitle='My EJOs & ESRs' 
        submoduleTitle='ESRs WIP' 
        showDate
      >
        <ESWWIPTable />
      </PageContainer>
    </>
  );
}

export default ESRWIPPage;