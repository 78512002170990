import { IEUR } from '../../modules/MyDashboardsPlusTasks/EUR/utils/types';
import { apiClient, apiEndpoints } from '../apiClient';

export type GetManyReturnType = {
  status: string;
  data: IEUR[];
};

type GetOneReturnType = {
  status: string;
  data: IEUR;
};

const getEURSMR = async (startDate?: string, endDate?: string) => {
  const response = await apiClient.get<GetManyReturnType>(`${apiEndpoints.eurSmr}?startDate=${startDate}&endDate=${endDate}`);
  return response.data.data;
};

const getEURSMROrderByDate = async () => {
  const response = await apiClient.get<GetManyReturnType>(`${apiEndpoints.eurSmr}?orderByDate=true`);
  return response.data.data;
};

const getEURSMRByEquipmentIdAndOperator = async (equipmentId: string, operatorId: string) => {
  const smrData = {
    equipmentId: equipmentId,
    operatorId: operatorId
  };

  const response = await apiClient.post<GetManyReturnType>(
    `${apiEndpoints.eurSmr}/equipment-and-operator`,
    smrData
  );

  return response.data.data;
};

const getEURSMRById = async (id: string) => {
  const response = await apiClient.get<GetOneReturnType>(
    `${apiEndpoints.eurSmr}/${id}`
  );
  return response.data.data;
};

const createEURSMR = async (newEURSMRData: Partial<IEUR>) => {
  const response = await apiClient.post<GetOneReturnType>(
    apiEndpoints.eurSmr,
    newEURSMRData
  );
  return response.data.data;
};

const updateEURSMR = async (id: string, updateEURSMRData: Partial<IEUR>) => {
  const response = await apiClient.patch<GetOneReturnType>(
    `${apiEndpoints.eurSmr}/${id}`,
    updateEURSMRData
  );

  return response.data.data;
};

const deleteEURSMR = async (id: string) => {
  const response = await apiClient.delete(`${apiEndpoints.eurSmr}/${id}`);
  return response.data.data;
};

const eurSMRApi = {
  getEURSMR,
  getEURSMROrderByDate,
  getEURSMRByEquipmentIdAndOperator,
  getEURSMRById,
  createEURSMR,
  updateEURSMR,
  deleteEURSMR,
};

export default eurSMRApi;