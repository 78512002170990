import { Stack, Typography } from '@mui/material';

type WorkStatus = 'Operational' | 'Under Servicing';

const colorMap: Record<WorkStatus, string> = {
  Operational: '#34C759',
  'Under Servicing': '#FF3B30',
};

interface WorkStatusCardProps {
  status: WorkStatus;
  value: number;
}

function WorkStatusCard({ status, value }: WorkStatusCardProps) {
  const color = colorMap[status];

  return (
    <Stack p={1.5} alignItems='center' bgcolor={color} color='white' borderRadius={2} flex={1}>
      <Typography fontSize={18} alignSelf='flex-start'>
        {status}
      </Typography>
      <Typography fontWeight='bold' fontSize={40}>
        {value}
      </Typography>
    </Stack>
  );
}

export default WorkStatusCard;
