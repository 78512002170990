import * as React from 'react';
import Radio from '@mui/material/Radio';
import RadioGroup, { RadioGroupProps } from '@mui/material/RadioGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormControl from '@mui/material/FormControl';
import { Stack, Typography } from '@mui/material';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
export default function AccountActiveButton(props: RadioGroupProps) {
  return (
    <FormControl>
      <Stack direction='row' gap={1.5} justifyContent='space-between'>
        <Typography
          id='account-active-button-label'
          mb='5px'
          color='#4C4C4C'
          component='label'
          fontSize={15}
          htmlFor='account-active-button'
          mt={1.5}
          whiteSpace='nowrap'
          align='right'
          width={130}
        >
          Status
        </Typography>
        <Stack width='400px'>
          <RadioGroup
            row
            aria-labelledby='account-active-button-label'
            name='radio-buttons-group'
            {...props}
          >
            <FormControlLabel
              value={true}
              control={<Radio checkedIcon={<CheckCircleIcon />} />}
              label='Active'
            />
            <FormControlLabel
              value={false}
              control={<Radio checkedIcon={<CheckCircleIcon />} />}
              label='Inactive'
            />
          </RadioGroup>
        </Stack>
      </Stack>
    </FormControl>
  );
}
