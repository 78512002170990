import { Outlet } from 'react-router-dom';
import PageContainer from '../../../../components/common/PageContainer';
import CPCTable from './Table';

const CPCPage = () => {
  return (
    <>
      <PageContainer 
        title='Reports' 
        subTitle='Equipment Cost Reports'
        submoduleTitle='Cost of Parts Consumed (CPC) Report'  
        showDate
      >
        <CPCTable />
      </PageContainer>
      <Outlet />
    </>
  );
}

export default CPCPage;