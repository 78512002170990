import { useQuery } from '@tanstack/react-query';
import { useAuth } from '../components/AuthProvider';
import rolesApi from '../services/roles';

type Module =
  | 'Equipment Plan Form & Equipment Request Form'
  | 'Equipment Job Order'
  | 'Equipment Service Order'
  | 'Equipment Utilization Report'
  | 'Fuel Request Issuance'
  | 'User'
  | 'Role';

const moduleIconMap = {
  'Equipment Plan Form & Equipment Request Form' : 'EPF & ERF',
  'Equipment Job Order': 'EJO',
  'Equipment Service Order': 'ESR',
  'User': 'USER',
  'Role': 'ROLE',
  'Fuel Request Issuance': 'FRIS',
  'Equipment Utilization Report': 'EUR',
};

// const moduleIconRouteMap = {
//   'Equipment Job Order': 'EJO Dashboard',
//   'Equipment Service Order': 'ESR Dashboard',
//   User: 'Users',
//   Role: 'Roles & Permission',
//   'Fuel Request Issuance': 'FRIS Dashboard',
//   'Equipment Utilization Report': 'EUR Dashboard',
// };

export const findArrays = (obj: string[], letter: string): { filteredArrays: string[] } => {
  const filteredArrays = obj
    .filter((elem) => elem.includes(letter))
    .filter((array) => array.length > 0);
  return { filteredArrays };
};

// this is used to check if the user has permission to view a page/module
// they can attempt to access the module via URL
export function usePermission(moduleName: Module) {
  const user = useAuth();
  
  if (!user?.isLoading) {
    const currentRole = user?.currentUserRole?.permissions!;
    // const authUser = useCurrentUserRole();
    // if (!authUser || authUser.isError) return; // no access
    // if (authUser.isLoading) return <LoadingIndicator show />;

    const permissionKey = moduleIconMap[moduleName];
    const permission = currentRole?.map((str) =>
      str.slice(0, str.indexOf(':'))
    );

    return permission?.includes(permissionKey) ? true : false;
  }
}

// this is used to hide/show a module item in the sidebar
// export function useVisibleSideMenu() {
//   const user = useAuth();
//   const currentRole = user?.currentUserRole?.permissions!;
//   if (!user) return []; // no access

//   const isSuperAdmin =
//     user.authUser?.roleId === 'ROLE::81a4a958-2a28-4a77-ab3c-6e4622793baf';

//   const visibleMenu = menu
//     .filter((m) => {
//       const permissionKey = moduleIconRouteMap[m.label as Module];
//       const filteredArray = findArrays(currentRole, 'VIEW').filteredArrays;
//       return filteredArray;
//     })
//     .map((i) =>
//       i.label === 'User Management' && i.subItems
//         ? !isSuperAdmin
//           ? { ...i, subItems: [i.subItems[1]] }
//           : i
//         : i
//     );

//   return visibleMenu;
// }

export const useCurrentUserRole = () => {
  const auth = useAuth();
  const id = auth?.authUser?.roleId!;
  return useQuery([`currentUser-${id}`, id], () => rolesApi.getRoleById(id!));
};
