import PageContainer from '../../../../components/common/PageContainer';

function EquipmentPlannedShiftHoursPage() {
  return (
    <>
      <PageContainer title='Project Databases' subTitle='Equipment Planned Shift Hours' showDate>
        Equipment Planned Shift Hours
      </PageContainer>
    </>
  );
}

export default EquipmentPlannedShiftHoursPage;
