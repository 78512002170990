import PageContainer from '../../../../components/common/PageContainer';

function EquipmentPage() {
  return (
    <>
      <PageContainer title='Equipment Databases' subTitle='Equipment Master' showDate>
        Equipment Master
      </PageContainer>
    </>
  );
}

export default EquipmentPage;
