import { Equipment } from '../modules/MyDashboardsPlusTasks/EUR/utils/types';
import { apiClient, apiEndpoints } from './apiClient';

export type GetManyReturnType = {
  status: string;
  data: Equipment[];
};

export type GetOneReturnType = {
  status: string;
  data: Equipment;
};

const getEquipments = async () => {
  const response = await apiClient.get<GetManyReturnType>(apiEndpoints.equipment);
  return response.data.data;
};

const getEquipmentsById = async (id: string) => {
  const response = await apiClient.get<GetOneReturnType>(
    `${apiEndpoints.equipment}/${id}`
  );
  return response.data.data;
};

export default {
  getEquipments,
  getEquipmentsById
};