import { Link, LinkProps } from 'react-router-dom';
import IconButton from '@mui/material/IconButton';
import EmailIcon from '@mui/icons-material/Email';
interface EmailActionButtonProps {
  editRoute: LinkProps['to'];
  disabled?: boolean;
}

const EmailActionButton = (props: EmailActionButtonProps) => {
  return (
    <IconButton color='primary' component={Link} to={props.editRoute} disabled={props.disabled}>
      <EmailIcon fontSize='medium' />
    </IconButton>
  );
};

export default EmailActionButton;
