import PageContainer from '../../../../components/common/PageContainer';
import ESRPendingTable from './Table';

const ESRCreatedAndPendingPage = () => {
  return (
    <>
      <PageContainer 
        title='My Dashboard + Tasks' 
        subTitle='My EJOs & ESRs'
        submoduleTitle='ESR Created, Pending Approval'  
        showDate
      >
        <ESRPendingTable />
      </PageContainer>
    </>
  );
}

export default ESRCreatedAndPendingPage;