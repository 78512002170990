import Stack from '@mui/material/Stack';
import Paper from '@mui/material/Paper';
import Box from '@mui/material/Box';
import TablePagination from '@mui/material/TablePagination';
import { flexRender } from '@tanstack/react-table';
import {
  AppTable,
  AppTableBody,
  AppTableCell,
  AppTableContainer,
  AppTableHead,
  AppTableRow,
  AppTableSort,
} from '../../../components/common/app-table/ui';
import { IOperatorLicenses } from "./OperatorLicensesUtils"
import useDefaultTable from '../../../components/common/app-table/useDefaultTable';
import LoadingIndicator from '../../../components/common/LoadingIndicator';
import AppSearchField from '../../../components/common/forms/AppSearchField';
import operatorLicenseColumns from './OperatorLicenseColumns';
import { useGetOperatorLicenses } from '../../../hooks/useOperatorLicenses';

const OperatorLicenseTable = () => {
  const licenses = useGetOperatorLicenses();
  const data: IOperatorLicenses[] = licenses?.data === undefined ? [] : licenses?.data;

  const { table, dataCount, searchFilter, setSearchFilter, pageIndex, pageSize } =
  useDefaultTable<IOperatorLicenses>(data, operatorLicenseColumns);

  const emptyText = searchFilter ? 'No operators found' : 'No operators yet';

  if (licenses?.isLoading) return <LoadingIndicator show />;

  return (
    <>
      <Stack direction='row' justifyContent='flex-end' alignItems='center' mb={2}>
        <AppSearchField
          onSearchKeywordChange={setSearchFilter}
          searchKeyword={searchFilter ?? ''}
        />
        {/* {table
          .getAllLeafColumns()
          .filter((c) => c.columnDef.enableHiding)
          .map((column) => (
            <TableColumnFilter columnsToFilter={column} key={column.id} />
          ))}
        <ImportButton /> */}
      </Stack>
      <Paper elevation={3}>
        <AppTableContainer>
          <AppTable stickyHeader>
            <AppTableHead>
              {table.getHeaderGroups().map((headerGroup) => (
                <AppTableRow key={headerGroup.id}>
                  {headerGroup.headers.map((header) => (
                    <AppTableCell key={header.id} align='center'>
                      <AppTableSort
                        canSort={header.column.getCanSort()}
                        currentSort={header.column.getIsSorted()}
                        onSort={header.column.getToggleSortingHandler()}
                      >
                        {flexRender(header.column.columnDef.header, header.getContext())}
                      </AppTableSort>
                    </AppTableCell>
                  ))}
                </AppTableRow>
              ))}
            </AppTableHead>
            <AppTableBody
              dataCount={dataCount}
              // loading={loading}
              emptyText={emptyText}
            >
              {table.getRowModel().rows.map((row) => (
                <AppTableRow key={row.id}>
                  {row.getVisibleCells().map((cell) => (
                    <AppTableCell key={cell.id} align='center'>
                      {flexRender(cell.column.columnDef.cell, cell.getContext())}
                    </AppTableCell>
                  ))}
                </AppTableRow>
              ))}
            </AppTableBody>
          </AppTable>
        </AppTableContainer>
        <Box position='relative'>
          <TablePagination
            sx={{ bgcolor: 'white', borderTop: '1px solid #eee' }}
            component='div'
            count={dataCount}
            page={pageIndex}
            onPageChange={(e, page) => table.setPageIndex(page)}
            rowsPerPage={pageSize}
            onRowsPerPageChange={(e) => table.setPageSize(Number(e.target.value))}
            rowsPerPageOptions={[10, 20, 50, 100]}
          />
        </Box>
      </Paper>{' '}
    </>
  );
}

export default OperatorLicenseTable;