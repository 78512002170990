import { EFCR } from '../../modules/Reports/EquipmentCostReports/utils/types';
import { apiClient, apiEndpoints } from '../apiClient';

export type GetManyReturnType = {
  status: string;
  data: EFCR[];
};

const getEFCR = async () => {
  const response = await apiClient.get<GetManyReturnType>(`${apiEndpoints.efcr}`);

  return response.data.data;
};

const efcrApi = {
    getEFCR
};

export default efcrApi;