import { Outlet } from 'react-router-dom';
import PageContainer from '../../../../components/common/PageContainer';
import PRTable from './Table';

const PRPage = () => {
  return (
    <>
      <PageContainer 
        title='My Dashboard + Tasks' 
        subTitle='My Equipment Requests'
        submoduleTitle='My PRs'  
        showDate
      >
        <PRTable />
      </PageContainer>
      <Outlet />
    </>
  );
}

export default PRPage;