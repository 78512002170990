import React from 'react';
import { Link } from 'react-router-dom';
import Button from '@mui/material/Button';
import Tooltip from '@mui/material/Tooltip';
import Menu from '@mui/material/Menu';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';

import ExpandMore from '@mui/icons-material/ExpandMore';
import AccountIcon from '@mui/icons-material/AccountCircle';
import { appColors } from '../../theme';
import { useAuth } from '../../components/AuthProvider';
import { formatDate } from '../../utils/helpers';
import { useSnackBar } from '../../components/ToastProvider';

const AccountMenu = () => {
  const { showSnackBar } = useSnackBar();
  const auth = useAuth();
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);

  const user = auth?.authUser;

  if (!user) return null;

  const { email, firstName, lastName, dateCreated } = user;
  const userName = `${firstName} ${lastName}`;
  const memberSince = formatDate(dateCreated);

  const open = Boolean(anchorEl);
  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleSignOut = async () => {
    await auth?.logout();
    showSnackBar('Logged Out Successfully', 'success');
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <React.Fragment>
      <Tooltip title='Account'>
        <Button
          sx={{ fontWeight: '500', textTransform: 'capitalize' }}
          variant='text'
          color='secondary'
          onClick={handleClick}
          startIcon={<AccountIcon />}
          endIcon={
            <ExpandMore sx={{ color: (theme) => theme.palette.primary.main }} />
          }
        >
          {userName}
        </Button>
      </Tooltip>
      <Menu
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        onClick={handleClose}
        transformOrigin={{ horizontal: 'right', vertical: 'top' }}
        anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
        elevation={1}
        PaperProps={{
          sx: { width: 265, bgcolor: 'white', paddingX: 3, paddingY: 2 },
        }}
      >
        <Stack alignItems='center'>
          <Stack
            height={48}
            direction='row'
            color={appColors.primary}
            alignItems='center'
            justifyContent='center'
          >
            <Typography
              sx={{ textTransform: 'uppercase' }}
              fontSize={19}
              fontWeight='bold'
            >
              PRIMEBMD
            </Typography>
            <Typography sx={{ textTransform: 'uppercase' }} fontSize={19}>
              AMI
            </Typography>
          </Stack>
          <Typography color={appColors.darkGray} fontWeight='bold'>
            {userName}
          </Typography>
          <Typography color={appColors.darkGray} fontSize={12}>
            {email}
          </Typography>
          <Typography color={appColors.darkGray} fontSize={12} mt={2}>
            Member since: {memberSince}
          </Typography>
          <Stack mt={2} direction='row' spacing={2}>
            <Button
              variant='contained'
              disableElevation
              component={Link}
              sx={{ minWidth: '88px' }}
              to='/profile'
            >
              Profile
            </Button>
            <Button
              color='secondary'
              variant='outlined'
              sx={{ minWidth: '88px' }}
              onClick={handleSignOut}
            >
              Sign Out
            </Button>
          </Stack>
        </Stack>
      </Menu>
    </React.Fragment>
  );
};

export default AccountMenu;
