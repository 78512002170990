import { createColumnHelper } from '@tanstack/react-table';
import { ICLCColumns } from '../utils/types';
import Checkbox from '@mui/material/Checkbox';
import HeaderCellCheckbox from '../../../../components/common/primebmd-ui/HeaderCellCheckbox';
import moment from 'moment';

const columnHelper = createColumnHelper<ICLCColumns>();

const CLCColumns = [
  columnHelper.display({
    id: 'select',
    header: ({ table }) => (
      <HeaderCellCheckbox
        checked={table.getIsAllRowsSelected()}
        indeterminate={table.getIsSomeRowsSelected()}
        onChange={table.getToggleAllRowsSelectedHandler()}
      />
    ),
    cell: ({ row }) => (
      <Checkbox
        checked={row.getIsSelected()}
        indeterminate={row.getIsSomeSelected()}
        onChange={row.getToggleSelectedHandler()}
      />
    ),
  }),
  columnHelper.accessor('dateCompleted', {
    id: 'dateCompleted',
    header: 'ESR Completion Date',
    cell: (row) => row.getValue() ? moment(row.getValue()).format('MM/DD/yyyy'): '-',
    enableGlobalFilter: true,
    enableColumnFilter: true,
  }),
  columnHelper.accessor('esrNumber', {
    id: 'esrNumber',
    header: 'ESR No.',
    cell:  (row) => row.getValue(),
    enableGlobalFilter: true,
    enableColumnFilter: true,
  }),
  columnHelper.accessor('equipmentNo', {
    id: 'equipmentNo',
    header: 'Equipment No.',
    cell: (row ) => row.getValue(),
    enableGlobalFilter: true,
    enableColumnFilter: true,
  }),
  columnHelper.accessor('category', {
    id: 'category',
    header: 'Category',
    cell: (row) => row.getValue(),
    enableGlobalFilter: true,
    enableColumnFilter: true,
  }),
  columnHelper.accessor('productFamily', {
    id: 'productFamily',
    header: 'Product Family',
    cell: (row) => row.getValue(),
    enableGlobalFilter: true,
    enableColumnFilter: true,
  }),
  columnHelper.accessor('description', {
    id: 'description',
    header: 'Description',
    cell: (row) => row.getValue(),
    enableGlobalFilter: true,
    enableColumnFilter: true,
  }),
  columnHelper.accessor('make', {
    id: 'make',
    header: 'Make',
    cell: (row) => row.getValue(),
    enableGlobalFilter: true,
    enableColumnFilter: true,
  }),
  columnHelper.accessor('model', {
    id: 'model',
    header: 'Model',
    cell: (row) => row.getValue(),
    enableGlobalFilter: true,
    enableColumnFilter: true,
  }),
  columnHelper.accessor('capacity', {
    id: 'capacity',
    header: 'Capacity',
    cell:  (row) => row.getValue(),
    enableGlobalFilter: true,
    enableColumnFilter: true,
  }),
  columnHelper.accessor('project', {
    id: 'project',
    header: 'Project',
    cell:  (row) => row.getValue(),
    enableGlobalFilter: true,
    enableColumnFilter: true,
  }),
  columnHelper.accessor('warranty', {
    id: 'warranty',
    header: 'Warranty',
    cell:  (row) => row.getValue(),
    enableGlobalFilter: true,
    enableColumnFilter: true,
  }),
  columnHelper.accessor('technicianName', {
    id: 'technicianName',
    header: 'Technician Name',
    cell:  (row) => row.getValue(),
    enableGlobalFilter: true,
    enableColumnFilter: true,
  }),
  columnHelper.accessor('technicianType', {
    id: 'technicianType',
    header: 'Technician Type',
    cell:  (row) => row.getValue(),
    enableGlobalFilter: true,
    enableColumnFilter: true,
  }),
  columnHelper.accessor('manHours', {
    id: 'manHours',
    header: 'Man Hours',
    cell:  (row) => row.getValue(),
    enableGlobalFilter: true,
    enableColumnFilter: true,
  }),
  columnHelper.accessor('unitCost', {
    id: 'unitCost',
    header: 'Unit Cost',
    cell:  (row) => row.getValue() ? `Php ${row.getValue()}` : '-',
    enableGlobalFilter: true,
    enableColumnFilter: true,
  }),
  columnHelper.accessor('laborCost', {
    id: 'laborCost',
    header: 'Total Labor Cost',
    cell:  (row) => row.getValue() ? `Php ${row.getValue()}` : '-',
    enableGlobalFilter: true,
    enableColumnFilter: true,
  })
];

export default CLCColumns;