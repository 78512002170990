import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { Controller, useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { IAllocateFields } from '../utils/types';
import { AllocateInitialValues, EMDatesSchema } from '../utils/schema';
import { useGetAvailableEquipments, useAllocateEquipment } from '../../../../hooks/useEPFERF';
import { IEquipmentDeploymentStatus } from '../utils/types';
import { appColors } from '../../../../theme';
import AppDialog from '../../../../components/common/AppDialog';
import LoadingIndicator from '../../../../components/common/LoadingIndicator';
import AppDatePicker from '../../../../components/common/forms/AppDatePicker';
import Stack from '@mui/material/Stack';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Checkbox from '@mui/material/Checkbox';

import moment from 'moment';

const AllocationForm = () => {
  const [activeIndex, setIndex] = useState<number>();
  const navigate = useNavigate();
  const allocateEquipment = useAllocateEquipment();
  const getAvailableEquipments = useGetAvailableEquipments();
  const equipments: IEquipmentDeploymentStatus[] = 
    getAvailableEquipments?.data === undefined ? [] : getAvailableEquipments?.data;
  
  const {
    control,
    handleSubmit,
    formState: { errors, isValid },
  } = useForm<IAllocateFields>({
    defaultValues: AllocateInitialValues,
    mode: 'onChange',
    resolver: yupResolver(EMDatesSchema),
  });

  const handleClose = () => navigate('/my-equipment-requests/my-erf', { replace: true });

  const onSubmit = (formData: IAllocateFields) => {
    let equipmentId = '';
    
    if (activeIndex !== undefined) equipmentId = equipments[activeIndex].equipmentId;

    const newFormData = {
      ...formData,
      equipmentId: equipmentId
    }
    
    allocateEquipment.mutate(newFormData);
  };

  if( getAvailableEquipments.isLoading 
    || allocateEquipment.isLoading 
    || getAvailableEquipments.isFetching
    ) 
  {
    return <LoadingIndicator show />;
  }

  return (
    <AppDialog open onDialogClose={handleClose} title='Equipment Allocation' maxWidth='lg' titleAlignment="center">
      <Stack component='form' height='100%' bgcolor='white' py={3} borderRadius={1}>
        <Stack px={3} height='100%' overflow='auto' gap={5}>
          <Stack direction='column' width="100%" justifyContent='center' margin='auto'>
            <Stack 
              direction='row' 
              className="head"  
              width="100%" 
              textAlign="center"
              alignItems="center"
              style={{
                backgroundColor: "#B2B2B2",
                lineHeight: 1.25,
                padding: '5px 0px',
                borderTopLeftRadius: '5px',
                borderTopRightRadius: '5px',
                fontSize: 13,
                color: '#fff'
              }}
            >
              <Box width="5%"></Box>
              <Box width="12%">Equipment No.</Box>
              <Box width="12%">Start Date</Box>
              <Box width="12%">Requested End Date</Box>
              <Box width="18%">EM Start Date</Box>
              <Box width="18%">EM End Date</Box>
              <Box width="11%">Current Deployment Status</Box>
              <Box width="12%">Action</Box>
            </Stack>
            {
            equipments.length === 0 
            ? <Stack width="100%" direction='row' justifyContent='center' pt={3}>
                No available equipment as of the moment.
              </Stack>
            : equipments.map((field, index) => (
                <Stack 
                  direction='row' 
                  className="body" 
                  fontSize={13} 
                  width="100%" 
                  textAlign="center" 
                  alignItems="center" 
                  sx={{
                    borderBottom: '1px solid #ccc',
                    padding: '2px 0px'
                  }}
                  key={field.id}
                >
                  <Box width="5%">
                    <Checkbox
                      id={`check-${index}`}
                      checked={activeIndex === index}
                      onChange={() => setIndex(index)}
                      inputProps={{ 'aria-label': 'controlled' }}
                    />
                  </Box>
                  <Box width="12%">{field.equipmentNo}</Box>
                  <Box width="12%">{moment(field.startDate).format('MM/DD/yyyy')}</Box>
                  <Box width="12%">{moment(field.endDate).format('MM/DD/yyyy')}</Box>
                  <Box width="18%">
                    {
                      activeIndex === index && 
                      <Controller
                        control={control}
                        name='emStartDate'
                        render={({ field: { value, onChange } }) => (
                          <AppDatePicker
                            name='date'
                            value={value}
                            onChange={onChange}
                            label=''
                            labelWidth='0%'
                            fieldWidth= '100%'
                            fontWeight={400}
                            error={!!errors?.emStartDate}
                            helperText={errors?.emStartDate?.message}
                          />
                        )}
                      />
                    }
                  </Box>
                  <Box width="18%">
                    {
                      activeIndex === index &&
                      <Controller
                        control={control}
                        name='emEndDate'
                        render={({ field: { value, onChange } }) => (
                          <AppDatePicker
                            name='date'
                            value={value}
                            onChange={onChange}
                            label=''
                            labelWidth='0%'
                            fieldWidth= '100%'
                            fontWeight={400}
                            error={!!errors?.emEndDate}
                            helperText={errors?.emEndDate?.message}
                          />
                        )}
                      />
                    }
                  </Box>
                  <Box width="11%">{field.status}</Box>
                  <Box width="12%">
                    <Button 
                      variant="contained"
                      onClick={handleSubmit(onSubmit)}
                      disabled={!isValid || activeIndex !== index} 
                      sx={{
                        background : appColors.warning,
                        ":hover" : {
                          backgroundColor: appColors.warningDark,
                        }
                      }}
                    >
                      Allocate
                    </Button>
                  </Box>
                </Stack>
            ))}
          </Stack>
        </Stack>
      </Stack>
    </AppDialog>
  );
}

export default AllocationForm;