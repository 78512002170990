import { DesktopDatePicker } from '@mui/x-date-pickers/DesktopDatePicker';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { Stack, TextField, Typography } from '@mui/material';
import { appColors } from '../../../theme';

interface AppDatePickerProps {
  error?: boolean;
  helperText?: string;
  label: string;
  labelPosition?: "top" | "left";
  labelAlignment?: "center" | "inherit" | "right" | "left" | "justify";
  labelWidth?: string;
  fieldWidth?: string;
  fontWeight?: number;
  name: string;
  required?: boolean;
  disabled?: boolean;
  value: Date | null;
  onChange: (value: any, keyboardInputValue?: string | undefined) => void;
}

const AppDatePicker = (props: AppDatePickerProps) => {
  const { 
    error, 
    helperText, 
    value, 
    onChange, 
    label, 
    labelPosition,
    labelAlignment, 
    labelWidth, 
    fieldWidth, 
    required, 
    name,
    fontWeight,
    disabled 
  } = props;

  return (
    <LocalizationProvider dateAdapter={AdapterDateFns}>
      <DesktopDatePicker
        inputFormat='MM/dd/yyyy'
        value={value}
        disableOpenPicker={disabled ? disabled : false}
        disabled={disabled ? disabled : false}
        onChange={onChange}
        renderInput={(params) => (
          <Stack 
            direction={labelPosition && labelPosition === 'top' ? 'column' : 'row'} 
            gap={labelPosition && labelPosition === 'top' ? .50 : 1.5} 
            justifyContent='space-between' 
            alignItems={labelPosition && labelPosition === 'top' ? "flex-start" : "center"}
            width="100%"
          >
            <Typography
              htmlFor={name}
              aria-required={required}
              color={appColors.formText}
              component='label'
              // mt={1.5}
              fontWeight={fontWeight ? fontWeight : 600}
              fontSize={labelPosition && labelPosition === 'top' ? 13 : 15}
              width={labelWidth ? labelWidth : 130}
              align={labelAlignment ? labelAlignment : 'right'}
              whiteSpace='nowrap'
            >
              {label}&nbsp;
              {required && (
                <Typography component='small' color='error.main'>
                  *
                </Typography>
              )}
            </Typography>
            <Stack width={fieldWidth ? fieldWidth : 400}>
              <TextField
                {...params}
                id={name}
                error={error}
                helperText={helperText}
                size='small'
                sx={{
                  '& ~ fieldset': {
                    borderColor: appColors.primary,
                  },
                  '&:hover ~ fieldset': {
                    borderColor: appColors.primary,
                  },
                  '&.Mui-focused ~ fieldset': {
                    borderColor: appColors.primary,
                  },
                }}
                InputProps={{ sx: { fontSize: '14px' }, ...params.InputProps }}
              />
            </Stack>
          </Stack>
        )}
      />
    </LocalizationProvider>
  );
};

export default AppDatePicker;
