import { useNavigate } from 'react-router-dom';

import { IconButton, Stack } from '@mui/material';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';

import { Role } from './roles-utils';

const RoleActions = ({ role }: { role: Role }) => {
  const navigate = useNavigate();

  const { id } = role;

  const goToRoleForm = () => navigate(`/roles/edit/${id}`);

  const openDeleteDialog = () => navigate(`/roles/delete/${id}`);

  const isSuperAdmin = role.isDefault;

  return (
    <Stack direction='row' gap={1.5}>
      <IconButton color='primary' aria-label='edit' onClick={goToRoleForm} disabled={isSuperAdmin}>
        <EditIcon />
      </IconButton>
      <IconButton
        color='error'
        aria-label='delete'
        onClick={openDeleteDialog}
        disabled={isSuperAdmin}
      >
        <DeleteIcon />
      </IconButton>
    </Stack>
  );
};

export default RoleActions;
