import { createColumnHelper } from '@tanstack/react-table';
import { IPR } from '../utils/types';
import { dateDifference } from '../../../../utils/helpers';
import { CloseActionButton } from '../../../../components/common/primebmd-ui/EditActionButton';
import Stack from '@mui/material/Stack';
import moment from 'moment';

const columnHelper = createColumnHelper<IPR>();

export const prColumns = [
  columnHelper.accessor('prNo', {
    id: 'prNo',
    header: 'PR No.',
    cell: (row ) => row.renderValue(),
    enableGlobalFilter: true,
    enableColumnFilter: true,
  }),
  columnHelper.display({
    id: 'daysSinceCreated',
    header: 'Days since PR Created',
    cell: ({ row }) => dateDifference(new Date(), row.original.prDate),
    enableHiding: false,
  }),
  columnHelper.accessor('project', {
    id: 'project',
    header: 'Project',
    cell: (row) => row.renderValue(),
    enableGlobalFilter: true,
    enableColumnFilter: true,
  }),
  columnHelper.accessor('serfNo', {
    id: 'serfNo',
    header: 'Serf No.',
    cell: (row) => row.renderValue(),
    enableGlobalFilter: true,
    enableColumnFilter: true,
  }),
  columnHelper.accessor('productFamily', {
    id: 'productFamily',
    header: 'Product Family',
    cell: (row) => row.renderValue(),
    enableGlobalFilter: true,
    enableColumnFilter: true,
  }),
  columnHelper.accessor('capacity', {
    id: 'capacity',
    header: 'Capacity',
    cell: (row) => row.renderValue(),
    enableGlobalFilter: true,
    enableColumnFilter: true,
  }),
  columnHelper.accessor('startDate', {
    id: 'startDate',
    header: 'Start Date',
    cell: (row) => moment(row.getValue()).format('MM/DD/yyyy'),
    enableGlobalFilter: true,
    enableColumnFilter: true,
  }),
  columnHelper.accessor('endDate', {
    id: 'endDate',
    header: 'Requested End Date',
    cell: (row) => moment(row.getValue()).format('MM/DD/yyyy'),
    enableGlobalFilter: true,
    enableColumnFilter: true,
  }),
  columnHelper.display({
    id: 'action',
    header: 'Action',
    cell: ({ row }) => (
      <Stack direction="row" gap={1} alignItems="center">
        <CloseActionButton route={`/my-equipment-requests/my-pr/close-pr/${row.original.id}`} />
      </Stack>
    ),
    enableHiding: false,
  }),
];

export default prColumns;