import * as yup from 'yup';
import { IEXFForm, IEORRForm } from './types';

export const CreateEXFInitialValues: IEXFForm = {
  locationId : '',
  exfRequestedEndDate: new Date(Date.now()),
  exfPMRemarks: ''
};

export const CreateEXFSchema = yup.object({
  locationId: yup.string().required('Project is required'),
  exfRequestedEndDate: yup.date().required('Requested End Date is required').typeError('Invalid Requested End Date Format'),
});

export const CreateEORRInitialValues: IEORRForm = {
  eorrRequestedPUD: new Date(Date.now()),
  eorrRemarks: ''
};

export const CreateEORRSchema = yup.object({
  eorrRequestedPUD: yup.date().required('EORR Requested Pick Up Date is required').typeError('Invalid EORR Requested Pick Up Date Format'),
});