import PageContainer from '../../../../components/common/PageContainer';

function FuelCostCodePage() {
  return (
    <>
      <PageContainer title='Other Databases' subTitle='Fuel Cost Code Master' showDate>
        Fuel Cost Code Master
      </PageContainer>
    </>
  );
}

export default FuelCostCodePage;
