import PageContainer from '../../../../components/common/PageContainer';

function FuelTypePage() {
  return (
    <>
      <PageContainer title='Other Databases' subTitle='Fuel Type Master' showDate>
        Fuel Type Master
      </PageContainer>
    </>
  );
}

export default FuelTypePage;
