import PageContainer from '../../../../components/common/PageContainer';
import ESRApprovedTable from './Table';

const EJOApprovedAndUnstartedPage = () => {
  return (
    <>
      <PageContainer 
        title='My Dashboard + Tasks' 
        subTitle='My EJOs & ESRs' 
        submoduleTitle='ESR Approved, Unstarted' 
        showDate
      >
        <ESRApprovedTable />
      </PageContainer>
    </>
  );
}

export default EJOApprovedAndUnstartedPage;