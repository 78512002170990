import { useMutation, useQuery } from '@tanstack/react-query';
import { queryClient } from '../react-query/queryClient';
import { AxiosError } from 'axios';
import { useNavigate, useParams, useLocation } from 'react-router';
import { useSnackBar } from '../components/ToastProvider';
import { 
  IERF, 
  ICreateERFForm, 
  IAllocateFields,
  IEquipmentDeploymentStatus,
  ICreateDeliveryForm,
  InTransits,
  IEXF,
  IEORR,
  ICreatePickUpForm,
  IEXFRemarksForm 
} from '../modules/MyDashboardsPlusTasks/MyEquipmentRequests/utils/types';
import epfErfApi from '../services/dashboardPlusTasks/epfErf';

interface useGetEPFERFOptions {
  role?: string;
  page?: string;
}

export const useGetEPFERFQuery = (options?: useGetEPFERFOptions) => {
  return useQuery<IERF[]>(['epfErfs'], () => epfErfApi.getEPFERF(), {
    onError(err) {
      console.log(err);
    },
    select(epfErfs) {
      if (options?.role && options?.page) {
        if (options?.role === 'Equipment Manager' && options?.page === 'ERF') {
          return epfErfs.filter(e => e.status === "Requested");
        } else if (options?.role === 'Equipment Manager' && options?.page === 'Allocations') {
          return epfErfs.filter(e => e.status === "Allocated" && e.allocationStatus === "Open");
        } else {
          return epfErfs.filter(e => e.status !== "On Rent" && e.status !== "Closed");
        } 
      } else {
        return epfErfs.filter(e => e.status !== "On Rent");
      }
    }
  });
}

export const useGetInTransitQuery = () => {
  return useQuery<InTransits[]>(['inTransitERFs'], () => epfErfApi.getInTransitEquipments(), {
    onError(err) {
      console.log(err);
    }
  });
}

export const useGetEXFQuery = () => {
  return useQuery<IEXF[]>(['exfs'], () => epfErfApi.getEXF(), {
    onError(err) {
      console.log(err);
    }
  });
}

export const useGetEORRQuery = () => {
  return useQuery<IEORR[]>(['eorrs'], () => epfErfApi.getEORR(), {
    onError(err) {
      console.log(err);
    }
  });
}

export const useGetEPFERFById = () => {
  const { id } = useParams<{ id: string}>();

  return useQuery<IERF>([`epfErf-${id}`, id], () => epfErfApi.getEPFERFById(id!));
}

export const useGetAvailableEquipments = () => {
  const { id } = useParams<{ id: string}>();

  return useQuery<IEquipmentDeploymentStatus[]>([`epfErf-${id}`, id], () => epfErfApi.getAvailableEquipments(id!));
}

export const useCreateEPFERF = () => {
  const { showSnackBar } = useSnackBar();
  const navigate = useNavigate();
  
  return useMutation((newEPFERFData: ICreateERFForm) => epfErfApi.createEPFERF(newEPFERFData), {
    onSuccess(data) {
      queryClient.invalidateQueries({ queryKey: ['epfErfs'] }).then(() => {
        showSnackBar('Successfully Created EPF/ERF', 'success');
        navigate('/my-equipment-requests/my-erf', { replace: true });
      });
      
      // queryClient.setQueryData<IEPFERF[]>(['epfErfs'], oldQueryData => oldQueryData && [
      //   ...oldQueryData,
      //   data,
      // ]);

      // showSnackBar('Successfully Created EPF/ERF', 'success');
      // navigate('/my-equipment-requests/my-epf-and-erf', { replace: true });
    },
    onError(err) {
      const errorData = (err as AxiosError<{ error: string }>).response?.data.error;

      if (err && errorData) {
        showSnackBar(errorData, 'error');
      }
    }
  }) 
}

export function useAllocateEquipment() {
  const { showSnackBar } = useSnackBar();
  const { id } = useParams<{ id: string }>();
  const navigate = useNavigate();

  return useMutation((newERFData: IAllocateFields) => epfErfApi.allocateERF(id!, newERFData), {
    onSuccess() {
      queryClient.invalidateQueries({ queryKey: ['epfErfs'] }).then(() => {
        showSnackBar('Successfully Allocated Equipment', 'success');
        navigate('/my-equipment-requests/my-erf', { replace: true });
      });
    },
    onError(err) {
      const errorData = (err as AxiosError<{ error: string }>).response?.data.error;

      if (err && errorData) {
        showSnackBar(errorData, 'error');
      }
    }
  });
}

export function useCreateDelivery() {
  const { showSnackBar } = useSnackBar();
  const { id } = useParams<{ id: string }>();
  const navigate = useNavigate();

  return useMutation((newERFData: Partial<ICreateDeliveryForm>) => epfErfApi.createDelivery(id!, newERFData), {
    onSuccess() {
      queryClient.invalidateQueries({ queryKey: ['epfErfs'] }).then(() => {
        showSnackBar('Successfully Created a Delivery', 'success');
        navigate('/my-equipment-requests/my-allocations', { replace: true });
      });
    },
    onError(err) {
      const errorData = (err as AxiosError<{ error: string }>).response?.data.error;

      if (err && errorData) {
        showSnackBar(errorData, 'error');
      }
    }
  });
}

export function useArrivedOnSite() {
  const { showSnackBar } = useSnackBar();
  const { id } = useParams<{ id: string }>();
  const navigate = useNavigate();

  return useMutation(() => epfErfApi.arrivedOnSite(id!), {
    onSuccess() {
      queryClient.invalidateQueries({ queryKey: ['epfErfs'] }).then(() => {
        showSnackBar('Confirmed Arrived on Site', 'success');
        navigate('/my-equipment-requests/my-erf ', { replace: true });
      });
    },
    onError(err) {
      const errorData = (err as AxiosError<{ error: string }>).response?.data.error;

      if (err && errorData) {
        showSnackBar(errorData, 'error');
      }
    }
  });
}

export function useCreatePickUp() {
  const { showSnackBar } = useSnackBar();
  const { id } = useParams<{ id: string }>();
  const navigate = useNavigate();

  return useMutation((newPickUpData: Partial<ICreatePickUpForm>) => epfErfApi.createPickUp(id!, newPickUpData), {
    onSuccess() {
      queryClient.invalidateQueries({ queryKey: ['eorrs'] }).then(() => {
        showSnackBar('Successfully Created a Pick Up', 'success');
        navigate('/my-equipment-requests/my-eorr', { replace: true });
      });
    },
    onError(err) {
      const errorData = (err as AxiosError<{ error: string }>).response?.data.error;

      if (err && errorData) {
        showSnackBar(errorData, 'error');
      }
    }
  });
}

export function usePickedUpFromSite() {
  const { showSnackBar } = useSnackBar();
  const { id } = useParams<{ id: string }>();
  const navigate = useNavigate();

  return useMutation(() => epfErfApi.pickedUpFromSite(id!), {
    onSuccess() {
      queryClient.invalidateQueries({ queryKey: ['eorrs'] }).then(() => {
        showSnackBar('Confirmed Picked Up from Site', 'success');
        navigate('/my-equipment-requests/my-eorr', { replace: true });
      });
    },
    onError(err) {
      const errorData = (err as AxiosError<{ error: string }>).response?.data.error;

      if (err && errorData) {
        showSnackBar(errorData, 'error');
      }
    }
  });
}

export function useWithdrawAllocation() {
  const { showSnackBar } = useSnackBar();
  const { id } = useParams<{ id: string }>();
  const navigate = useNavigate();

  return useMutation(() => epfErfApi.withdrawAllocation(id!), {
    onSuccess() {
      queryClient.invalidateQueries({ queryKey: ['epfErfs'] }).then(() => {
        showSnackBar('Successfully Withdrawn Allocation', 'success');
        navigate('/my-equipment-requests/my-allocations', { replace: true });
      });
    },
    onError(err) {
      const errorData = (err as AxiosError<{ error: string }>).response?.data.error;

      if (err && errorData) {
        showSnackBar(errorData, 'error');
      }
    }
  });
}

export function useEXFApproval() {
  const { showSnackBar } = useSnackBar();
  const { id } = useParams<{ id: string }>();
  const navigate = useNavigate();
  const location = useLocation();
  const status = location.pathname.split('/')[3];
  const url = status === 'approve' 
    ? epfErfApi.approveEXF 
    : (status === 'reject' ? epfErfApi.rejectEXF : epfErfApi.approveEXFWtChange);
    
  const successMessage = status === 'reject' 
    ? 'Successfully Rejected EXF'
    : 'Successfully Approved EXF';

  return useMutation((data: IEXFRemarksForm) => url(id!, data), {
    onSuccess() {
      queryClient.invalidateQueries({ queryKey: ['exfs'] }).then(() => {
        showSnackBar(successMessage, 'success');
        navigate('/my-equipment-requests/my-exf', { replace: true });
      });
    },
    onError(err) {
      const errorData = (err as AxiosError<{ error: string }>).response?.data.error;

      if (err && errorData) {
        showSnackBar(errorData, 'error');
      }
    }
  });
}

export const useDeleteUser = () => {
  const { showSnackBar } = useSnackBar();
  const navigate = useNavigate();

  return useMutation((id: string) => epfErfApi.deleteEPFERF(id), {
    onSuccess() {
      queryClient.invalidateQueries({ queryKey: ['epfErfs'] }).then(() => {
        showSnackBar('Successfully Deleted EPF/ERF', 'success');
        navigate('/my-equipment-requests/my-erf', { replace: true });
      });
    },
    onError(err) {
      const errorData = (err as AxiosError<{ error: string }>).response?.data.error;

      if (err && errorData) {
        showSnackBar(errorData, 'error');
      }
    },
  });
}