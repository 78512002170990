import Checkbox, { CheckboxProps } from '@mui/material/Checkbox';
import SquareRoundedIcon from '@mui/icons-material/SquareRounded';

function HeaderCellCheckbox(props: CheckboxProps) {
  return (
    <Checkbox
      {...props}
      icon={<SquareRoundedIcon sx={{ color: 'white' }} />}
      sx={{
        '&': {
          position: 'relative',

          '&::before': {
            content: '""',
            position: 'absolute',
            top: 14,
            left: 14,
            zIndex: -1,
            backgroundColor: 'white',
            width: 14,
            height: 14,
          },
        },
      }}
    />
  );
}

export default HeaderCellCheckbox;
