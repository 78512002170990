import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import Stack from '@mui/material/Stack';
import Paper from '@mui/material/Paper';
import Box from '@mui/material/Box';
import { ExportButton } from '../../../../components/common/primebmd-ui/DataButtons';
import TablePagination from '@mui/material/TablePagination';
import { flexRender } from '@tanstack/react-table';
import {
  AppTable,
  AppTableBody,
  AppTableCell,
  AppTableContainer,
  AppTableHead,
  AppTableRow,
  AppTableSort,
} from '../../../../components/common/app-table/ui';
import useDefaultTable from '../../../../components/common/app-table/useDefaultTable';
import LoadingIndicator from '../../../../components/common/LoadingIndicator';
import EFCRColumns from './Columns';
import { EFCR } from '../utils/types';
import { useGetEFCRQuery, useFormatGetEFCRQuery } from '../../../../hooks/useEFCR';
import AppSearchField from '../../../../components/common/forms/AppSearchField';
import { exportEFCRToExcel } from '../utils/exporting';

const EFCRTable = () => {
  const efcr = useGetEFCRQuery();
  const data: EFCR[] = efcr?.data === undefined ? [] : efcr?.data;
  const formattedEFCRData: EFCR[] = useFormatGetEFCRQuery(data);  
  const { table, dataCount, searchFilter, setSearchFilter, pageIndex, pageSize } =
  useDefaultTable<Partial<EFCR>>(data, EFCRColumns);

  const emptyText = searchFilter ? 'No data found' : 'No data yet';

  if (efcr?.isLoading) return <LoadingIndicator show />;

  const handleExport = () => {
    exportEFCRToExcel(formattedEFCRData, 'EFCR Report');
  }

  return (
    <>
     <Stack direction="row" justifyContent="flex-end" mb={2} gap={2}>
        <AppSearchField
          onSearchKeywordChange={setSearchFilter}
          searchKeyword={searchFilter ?? ''}
        />
         <Stack direction="row" gap={.75}>
          <ExportButton onClick={handleExport} />
        </Stack>
      </Stack>
      <Paper elevation={3}>
        <AppTableContainer>
          <AppTable stickyHeader sx={{ minWidth: '1650px'}}>
            <AppTableHead>
              {table.getHeaderGroups().map((headerGroup) => (
                <AppTableRow key={headerGroup.id}>
                  {headerGroup.headers.map((header) => (
                    <AppTableCell key={header.id} align='center'>
                      <AppTableSort
                        canSort={header.column.getCanSort()}
                        currentSort={header.column.getIsSorted()}
                        onSort={header.column.getToggleSortingHandler()}
                      >
                        {flexRender(header.column.columnDef.header, header.getContext())}
                      </AppTableSort>
                    </AppTableCell>
                  ))}
                </AppTableRow>
              ))}
            </AppTableHead>
            <AppTableBody
              dataCount={dataCount}
              emptyText={emptyText}
            >
              {table.getRowModel().rows.map((row) => (
                     <AppTableRow key={row.id} sx={{ height: '45px' }}>
                  {row.getVisibleCells().map((cell) => (
                    <AppTableCell key={cell.id} align='center'>
                      {flexRender(cell.column.columnDef.cell, cell.getContext())}
                    </AppTableCell>
                  ))}
                </AppTableRow>
              ))}
            </AppTableBody>
          </AppTable>
        </AppTableContainer>
        <Box position='relative'>
          <TablePagination
            sx={{ bgcolor: 'white', borderTop: '1px solid #eee' }}
            component='div'
            count={dataCount}
            page={pageIndex}
            onPageChange={(e, page) => table.setPageIndex(page)}
            rowsPerPage={pageSize}
            onRowsPerPageChange={(e) => table.setPageSize(Number(e.target.value))}
            rowsPerPageOptions={[10, 20, 50, 100]}
          />
        </Box>
      </Paper>
    </>
  );
}

export default EFCRTable;