import List from '@mui/material/List';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import Typography from '@mui/material/Typography';
import Collapse from '@mui/material/Collapse';

import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import ExpandLessIcon from '@mui/icons-material/ExpandLess';

import { SidebarMenu } from '../../../modules/Layout/appMenuItems';
import SideNavItem from './SideNavItem';
import { SideNavProvider, useSideNav } from './SideNavContext';

function SideNavButton({ menuItem: sidebarItem }: { menuItem: SidebarMenu }) {
  const { expandedNavs, handleSideNavExpand } = useSideNav();

  const expanded = expandedNavs.includes(sidebarItem.label);

  return (
    <div>
      <ListItemButton
        onClick={(e) => {
          e.preventDefault();
          handleSideNavExpand(sidebarItem.label, Boolean(sidebarItem.subItems));
        }}
        sx={{
          py: '16px',
          height: '44px',
          color: 'white',
          bgcolor: 'unset',

          '& + .collapse .nested a > div': {
            paddingLeft: sidebarItem.label === 'My EJOs & ESRs' 
                        || sidebarItem.label === 'My EURs' 
                        || sidebarItem.label === 'My Equipment Requests'
                        || sidebarItem.label === 'Equipment Use & Performance Reports'
                        || sidebarItem.label === 'Equipment Cost Reports' ? 8 : 6.6,
          },
        }}
      >
        {sidebarItem.icon ? (
          <ListItemIcon sx={{ color: 'inherit', minWidth: 'auto', mr: 2 }}>
            {sidebarItem.icon}
          </ListItemIcon>
        ) : (
          <ListItemIcon sx={{ color: 'inherit', minWidth: '37px' }} />
        )}
        <Typography color='inherit' fontSize={13}>
          {sidebarItem.label}
        </Typography>
        <ListItemIcon sx={{ color: 'inherit', minWidth: 'auto', ml: 'auto' }}>
          {expanded ? <ExpandLessIcon /> : <ExpandMoreIcon />}
        </ListItemIcon>
      </ListItemButton>

      <SideNavProvider>
        <Collapse in={expanded} timeout='auto' unmountOnExit className='collapse'>
          <List component='div' disablePadding className='nested'>
            {sidebarItem.subItems?.map((item, idx) => {
              if (item.subItems) {
                return <SideNavButton key={item.label} menuItem={item} />;
              }

              return <SideNavItem key={item.label} menuItem={item} />;
            })}
          </List>
        </Collapse>
      </SideNavProvider>
    </div>
  );
}

export default SideNavButton;
