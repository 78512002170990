import { useLocation } from 'react-router-dom';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemButton from '@mui/material/ListItemButton';
import Typography from '@mui/material/Typography';

import { activeStyles, SidebarMenu } from '../../../modules/Layout/appMenuItems';
import AppNavLink from '../AppNavLink';
import SideNavButton from './SideNavButton';

function SideNavItem({ menuItem: sidebarItem }: { menuItem: SidebarMenu }) {
  const { pathname } = useLocation();

  console.log(pathname);

  if (sidebarItem.subItems) {
    return <SideNavButton menuItem={sidebarItem} />;
  }

  return (
    <AppNavLink
      to={sidebarItem.path as string}
      style={({ isActive }) => ({
        ...(pathname?.includes('/home') && sidebarItem.path?.includes('/home')
          ? activeStyles
          : isActive
          ? activeStyles
          : undefined),
      })}
    >
      <ListItemButton
        disableGutters
        sx={{
          display: 'flex',
          alignItems: 'center',
          height: '44px',
          width: '100%',
          padding: 2,
        }}
      >
        {sidebarItem.icon ? (
          <ListItemIcon
            sx={{
              color: 'inherit',
              minWidth: 'auto',
              mr: 2,
              pl: 0,
            }}
          >
            {sidebarItem.icon}
          </ListItemIcon>
        ) : // <ListItemIcon sx={{ minWidth: 37 }} />
        null}
        <Typography color='inherit' fontSize={13}>
          {sidebarItem.label}
        </Typography>
      </ListItemButton>
    </AppNavLink>
  );
}

export default SideNavItem;
