import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { Controller, useFieldArray, useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { SelectOptionType } from '../../../../components/common/forms/form-types';
import { ICreateDeliveryForm } from '../utils/types';
import { CreateDeliveryInitialValues, CreateDeliverySchema } from '../utils/schema';
import { useGetLocationsQuery } from '../../../../hooks/useLocations';
import { useGetEPFERFById, useCreateDelivery } from '../../../../hooks/useEPFERF';
import AppDatePicker from '../../../../components/common/forms/AppDatePicker';
import AppSelect from '../../../../components/common/forms/AppSelect';
import AppTextField from '../../../../components/common/forms/AppTextField';
import AppDialog from '../../../../components/common/AppDialog';
import AppPrimeUploadForm from '../../../../components/common/forms/AppPrimeUploadForm';
import LoadingIndicator from '../../../../components/common/LoadingIndicator';
import Stack from '@mui/material/Stack';
import Button from '@mui/material/Button';
import moment from 'moment';

const CreateDeliveryForm = () => {
  const {
    register,
    handleSubmit,
    control,
    watch,
    formState: { errors, isValid },
  } = useForm<ICreateDeliveryForm>({
    defaultValues: CreateDeliveryInitialValues,
    mode: 'onChange',
    resolver: yupResolver(CreateDeliverySchema),
  });  

  const [fileName, setFileName] = useState('');
  const navigate = useNavigate();
  const createDelivery = useCreateDelivery();
  const erfToUpdate = useGetEPFERFById();
  const erfToUpdateData = erfToUpdate.data;
  const locationData = useGetLocationsQuery({type: "Project"});
  const locationOptions : SelectOptionType[] = 
  locationData?.data === undefined ? [] : locationData.data.map(l => {
                                          return {
                                            id: l.id,
                                            label: l.name,
                                            value: l.id
                                          }
                                        });

  const onSubmit = (formData: ICreateDeliveryForm) => {
    createDelivery.mutate(formData);
  };

  const handleClose = () => navigate('/my-equipment-requests/my-allocations', { replace: true });

  const mediaFiles = watch('allocationETICAttachment');
  const { append } = useFieldArray({ control, name: 'allocationETICAttachment' });

  const handleMediaUpload = (files: FileList | null) => {
    if (!files) return;

    // check for possible duplicate files (lookup value is filename)
    const emailFilenames = mediaFiles.map((item) => item.fileName);

    for (let i = 0; i < files.length; i++) {
      if (emailFilenames.includes(files[i].name)) {
        return console.log(`${files[i].name} already exists`);
      }
    }

    Array.from(files).forEach((file) => {
      append({
        fileName: file.name,
        source: file,
      });
    });
    setFileName(files[0].name);
  };

  if (erfToUpdate.isLoading || createDelivery.isLoading) {
    return <LoadingIndicator show />;
  }

  return (
    <AppDialog open onDialogClose={handleClose} title='Create Delivery' maxWidth='sm' titleAlignment="center">
      <Stack component='form' height='100%' bgcolor='white' py={3} borderRadius={1}>
        <Stack px={3} height='100%' overflow='auto' gap={5}>
          <Stack direction='column' gap={2} width="100%" justifyContent='center' margin='auto'>
            <Stack direction="row" gap={2}>
              <AppTextField
                label="ERF No."
                labelPosition="top"
                labelAlignment="left"
                labelWidth='100%'
                fieldWidth= '100%'
                fontWeight={400}
                value={erfToUpdateData?.erfNo}
                disabled={true}
              />
              <AppTextField
                label="SERF No."
                labelPosition="top"
                labelAlignment="left"
                labelWidth='100%'
                fieldWidth= '100%'
                fontWeight={400}
                value={erfToUpdateData?.serfNo}
                disabled={true}
              />
            </Stack>
            <Stack direction="row" gap={2}>
              <AppTextField
                label="Allocation No."
                labelPosition="top"
                labelAlignment="left"
                labelWidth='100%'
                fieldWidth= '100%'
                fontWeight={400}
                value={erfToUpdateData?.allocationNo}
                disabled={true}
              />
              <AppTextField
                label="Equipment No."
                labelPosition="top"
                labelAlignment="left"
                labelWidth='100%'
                fieldWidth= '100%'
                fontWeight={400}
                value={erfToUpdateData?.equipmentNo}
                disabled={true}
              />
            </Stack>
            <Stack direction="row" gap={2}>
              <AppTextField
                label="Allocation Delivery No. Creation Date"
                labelPosition="top"
                labelAlignment="left"
                labelWidth='100%'
                fieldWidth= '100%'
                fontWeight={400}
                value={moment(new Date()).format('MM/DD/yyyy')}
                disabled={true}
              />
              <AppTextField
                label="Allocation ETIC No."
                labelPosition="top"
                labelAlignment="left"
                labelWidth='100%'
                fieldWidth= '100%'
                fontWeight={400}
                error={!!errors.allocationETICNo}
                helperText={errors.allocationETICNo?.message}
                {...register('allocationETICNo')}
              />
            </Stack>
             <AppPrimeUploadForm
              label='Allocation ETIC Attachment'
              fileName={fileName}
              key={mediaFiles.length}
              onChange={(e) => handleMediaUpload(e.currentTarget.files)}
              labelPosition="top"
              labelAlignment="left"
              labelWidth='0%'
              fieldWidth= '100%'
              fontWeight={400}
              mt='0px'
            />
            <Controller
              control={control}
              name='allocationPickUpLocationId'
              render={({ field }) => (
                <AppSelect
                  sx={{ width: "100%" }}
                  label="Allocation Pickup Location"
                  labelPosition="top"
                  labelAlignment="left"
                  labelWidth='100%'
                  fieldWidth= '100%'
                  fontWeight={400}
                  placeholder="Select here"
                  error={!!errors.allocationPickUpLocationId}
                  helperText={errors.allocationPickUpLocationId?.message}
                  options={locationOptions}
                  {...register('allocationPickUpLocationId')}
                  {...field}
                />
              )}
            />
            <AppTextField
              label="Allocation Truck No."
              labelPosition="top"
              labelAlignment="left"
              labelWidth='100%'
              fieldWidth= '100%'
              fontWeight={400}
              error={!!errors.allocationTruckNo}
              helperText={errors.allocationTruckNo?.message}
              {...register('allocationTruckNo')}
            />
            <Stack direction="row" gap={1}>
              <Controller
                control={control}
                name='allocationEPUD'
                render={({ field: { value, onChange } }) => (
                  <AppDatePicker
                    name='date'
                    value={value}
                    onChange={onChange}
                    label='Allocation Estimated Pickup Date'
                    labelPosition="top"
                    labelAlignment="left"
                    labelWidth='100%'
                    fieldWidth= '100%'
                    fontWeight={400}
                    error={!!errors.allocationEPUD}
                    helperText={errors.allocationEPUD?.message}
                  />
                )}
              />
              <Controller
                control={control}
                name='allocationETA'
                render={({ field: { value, onChange } }) => (
                  <AppDatePicker
                    name='date'
                    value={value}
                    onChange={onChange}
                    label='Allocation ETA at Drop Off'
                    labelPosition="top"
                    labelAlignment="left"
                    labelWidth='100%'
                    fieldWidth= '100%'
                    fontWeight={400}
                    error={!!errors.allocationETA}
                    helperText={errors.allocationETA?.message}
                  />
                )}
              />
            </Stack>
            <AppTextField
              label="Allocation Drop Off Location"
              labelPosition="top"
              labelAlignment="left"
              labelWidth='100%'
              fieldWidth= '100%'
              fontWeight={400}
              value={erfToUpdateData?.project}
              disabled={true}
            />
            <AppTextField
              label="Allocation Delivery Remark"
              labelPosition="top"
              labelAlignment="left"
              labelWidth='100%'
              fieldWidth= '100%'
              fontWeight={400}
              multiline
              rows={3}
              error={!!errors.allocationDeliveryRemarks}
              helperText={errors.allocationDeliveryRemarks?.message}
              {...register('allocationDeliveryRemarks')}
            />
            <Stack mt={2}>
              <Button 
                onClick={handleSubmit(onSubmit)}
                disabled={!isValid} 
                sx={{ width: 200, m: '0 auto' }}
              >
                Submit
              </Button>
            </Stack>
          </Stack>
        </Stack>
      </Stack>
    </AppDialog>
  );
}

export default CreateDeliveryForm;