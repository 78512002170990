import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import { appColors } from '../../../theme';
import { getDateToday } from '../../../utils/helpers';

interface PageTitleProps {
  title: string;
  subTitle?: string;
  sideTitle?: string;
  hideSubtitle?: boolean;
  fontWeight?: number;
}

const PageTitle = ({
  title,
  subTitle = getDateToday(),
  hideSubtitle,
  sideTitle,
  fontWeight
}: PageTitleProps) => {
  return (
    <>
      <Stack direction='row' alignItems='center' gap={4}>
        <Typography
          sx={{ color: appColors.titleColor }}
          fontSize={32}
          fontWeight={fontWeight ? fontWeight : 600}
          mt='-6px'
        >
          {title}
        </Typography>
        {sideTitle && (
          <Typography
            sx={{ color: appColors.titleColor }}
            fontSize={24}
            fontWeight={600}
          >
            {sideTitle}
          </Typography>
        )}
      </Stack>
      {!hideSubtitle && (
        <Typography sx={{ color: appColors.labelColor }} fontSize={14} mb={1}>
          {subTitle}
        </Typography>
      )}
    </>
  );
};

export default PageTitle;
