import { Snackbar, Alert } from '@mui/material';

import SuccessIcon from '@mui/icons-material/CheckCircle';
import InfoIcon from '@mui/icons-material/Info';
import WarningIcon from '@mui/icons-material/Warning';
import ErrorIcon from '@mui/icons-material/Error';

interface AppProps {
  visible: boolean;
  type: 'success' | 'error' | 'info';
  message: string;
  handleClose: () => void;
}

const AppToast = (props: AppProps) => {
  const { visible, type, message, handleClose } = props;

  return (
    <Snackbar
      anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
      autoHideDuration={3000}
      open={visible}
      onClose={handleClose}
    >
      <Alert
        variant='filled'
        severity={type}
        sx={{ minWidth: 180 }}
        iconMapping={{
          success: <SuccessIcon />,
          error: <ErrorIcon />,
          info: <InfoIcon />,
          warning: <WarningIcon />,
        }}
      >
        {message}
      </Alert>
    </Snackbar>
  );
};

export default AppToast;
