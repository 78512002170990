import PageContainer from '../../../../components/common/PageContainer';

function LocationPage() {
  return (
    <>
      <PageContainer title='Other Databases' subTitle='Location Master' showDate>
        Location Master
      </PageContainer>
    </>
  );
}

export default LocationPage;
