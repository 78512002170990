import { Stack, IconButton, Tooltip } from '@mui/material';

import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';

import { useNavigate } from 'react-router-dom';
import { IAccounts } from './accounts-utils';

interface Ownprops {
  userAccount: IAccounts;
}

const AccountsActionButton = ({ userAccount }: Ownprops) => {
  const { id } = userAccount;

  const navigate = useNavigate();

  const goToUserAccountForm = () => navigate(`/users/edit/${id}`);

  const openDeleteDialog = () => navigate(`/users/delete/${id}`);

  return (
    <Stack direction='row' gap={1.5}>
      <Tooltip title='Edit' placement='top'>
        <IconButton color='primary' aria-label='edit' onClick={goToUserAccountForm}>
          <EditIcon />
        </IconButton>
      </Tooltip>
      <Tooltip title='Delete' placement='top' onClick={openDeleteDialog}>
        <IconButton color='error' aria-label='delete'>
          <DeleteIcon />
        </IconButton>
      </Tooltip>
    </Stack>
  );
};

export default AccountsActionButton;
