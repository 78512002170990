import { ReactNode } from 'react';
import { Navigate } from 'react-router-dom';
import { useAuth } from '../AuthProvider';

const AuthenticatedRoute = ({ children }: { children: ReactNode }) => {
  const auth = useAuth();
  const storedToken = localStorage.getItem('accessToken');

  if ((!auth?.authUser && !auth?.isLoading) || !storedToken) {
    return <Navigate to='/auth' replace />;
  }

  return <>{children}</>;
};

export default AuthenticatedRoute;
