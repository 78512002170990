import { useNavigate, useParams, Outlet, Link } from 'react-router-dom';
import Stack from '@mui/material/Stack';
import Paper from '@mui/material/Paper';
import Box from '@mui/material/Box';
import TablePagination from '@mui/material/TablePagination';
import KeyboardBackspaceOutlinedIcon from '@mui/icons-material/KeyboardBackspaceOutlined';
import { flexRender } from '@tanstack/react-table';
import {
  AppTable,
  AppTableBody,
  AppTableCell,
  AppTableContainer,
  AppTableHead,
  AppTableRow,
  AppTableSort,
} from '../../../../components/common/app-table/ui';
import useDefaultTable from '../../../../components/common/app-table/useDefaultTable';
import LoadingIndicator from '../../../../components/common/LoadingIndicator';
import AddActivityButton from '../ui/AddActivityButton';
import { appColors } from '../../../../theme';
import { IEURActivity } from '../utils/types';
import { useCreateColumns } from '../hooks/useCreateColumns';
import { useGetEURActivitiesByEURNo } from '../../../../hooks/useEURActivities';

const EURActivityTable = () => {
  const columns = useCreateColumns();
  const activityList = useGetEURActivitiesByEURNo();
  const activities: IEURActivity[]  = activityList.data === undefined ? [] : activityList.data;

  const { id } = useParams();
  const activityEURLink = `/eur/${id}/activity/new`;
  const navigate = useNavigate();
  
  const goToAddActivityEUR = () => navigate(activityEURLink);

  const { table, dataCount, searchFilter, pageIndex, pageSize } =
  useDefaultTable<IEURActivity>(activities, columns);

  const emptyText = searchFilter ? 'No data found' : 'No data yet';

  if (activityList?.isLoading) return <LoadingIndicator show />;

  return (
    <>
      <Stack direction='column' mb={2} mt={5} gap={3}>
        <Stack direction="row" justifyContent="space-between">
          <Link 
            to={`/eur`}
            style={{ 
              fontSize: 14, 
              display: 'flex', 
              flexDirection: 'row', 
              alignItems: 'center',
              color: appColors.primary
            }}
          >
            <KeyboardBackspaceOutlinedIcon />
            Go back to EUR List
          </Link> 
          <AddActivityButton onClick={goToAddActivityEUR} />
        </Stack>
      </Stack>
      <Paper elevation={3}>
        <AppTableContainer>
          <AppTable stickyHeader>
            <AppTableHead>
              {table.getHeaderGroups().map((headerGroup) => (
                <AppTableRow key={headerGroup.id}>
                  {headerGroup.headers.map((header) => (
                    <AppTableCell key={header.id} align='center'>
                      <AppTableSort
                        canSort={header.column.getCanSort()}
                        currentSort={header.column.getIsSorted()}
                        onSort={header.column.getToggleSortingHandler()}
                      >
                        {flexRender(header.column.columnDef.header, header.getContext())}
                      </AppTableSort>
                    </AppTableCell>
                  ))}
                </AppTableRow>
              ))}
            </AppTableHead>
            <AppTableBody
              dataCount={dataCount}
              // loading={loading}
              emptyText={emptyText}
            >
              {table.getRowModel().rows.map((row) => (
                <AppTableRow key={row.id}>
                  {row.getVisibleCells().map((cell) => (
                    <AppTableCell key={cell.id} align='center'>
                      {flexRender(cell.column.columnDef.cell, cell.getContext())}
                    </AppTableCell>
                  ))}
                </AppTableRow>
              ))}
            </AppTableBody>
          </AppTable>
        </AppTableContainer>
        <Box position='relative'>
          <TablePagination
            sx={{ bgcolor: 'white', borderTop: '1px solid #eee' }}
            component='div'
            count={dataCount}
            page={pageIndex}
            onPageChange={(e, page) => table.setPageIndex(page)}
            rowsPerPage={pageSize}
            onRowsPerPageChange={(e) => table.setPageSize(Number(e.target.value))}
            rowsPerPageOptions={[10, 20, 50, 100]}
          />
        </Box>
      </Paper>
      <Outlet />
    </>
  );
}

export default EURActivityTable;