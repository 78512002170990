import { createColumnHelper } from '@tanstack/react-table';
import { EFCR } from '../utils/types';
import moment from 'moment';

const columnHelper = createColumnHelper<Partial<EFCR>>();

const EFCRColumns = [
  columnHelper.accessor('frisDate', {
    id: 'frisDate',
    header: 'FRIS Date',
    cell: (row) =>  moment(row.getValue()).format('MM/DD/yyyy'),
    enableGlobalFilter: true,
    enableColumnFilter: true,
    enableHiding: true
  }),
  columnHelper.accessor('frisNo', {
    id: 'frisNo',
    header: 'FRIS No.',
    cell: (row) => row.getValue(),
    enableGlobalFilter: true,
    enableColumnFilter: true,
    enableHiding: true
  }),
  columnHelper.accessor('equipmentNo', {
    id: 'equipmentNo',
    header: 'Equipment No.',
    cell: (row) => row.getValue(),
    enableGlobalFilter: true,
    enableColumnFilter: true,
    enableHiding: true
  }),
  columnHelper.accessor('category', {
    id: 'category',
    header: 'Category',
    cell: (row) => row.getValue(),
    enableGlobalFilter: true,
    enableColumnFilter: true,
    enableHiding: true
  }),
  columnHelper.accessor('productFamily', {
    id: 'productFamily',
    header: 'Product Family',
    cell: (row) => row.getValue(),
    enableGlobalFilter: true,
    enableColumnFilter: true,
    enableHiding: true
  }),
  columnHelper.accessor('description', {
    id: 'description',
    header: 'Description',
    cell: (row) => row.getValue(),
    enableGlobalFilter: true,
    enableColumnFilter: true,
    enableHiding: true
  }),
  columnHelper.accessor('make', {
    id: 'make',
    header: 'Make',
    cell: (row) => row.getValue(),
    enableGlobalFilter: true,
    enableColumnFilter: true,
    enableHiding: true
  }),
  columnHelper.accessor('model', {
    id: 'model',
    header: 'Model',
    cell: (row) => row.getValue(),
    enableGlobalFilter: true,
    enableColumnFilter: true,
    enableHiding: true
  }),
  columnHelper.accessor('capacity', {
    id: 'capacity',
    header: 'Capacity',
    cell: (row) => row.getValue(),
    enableGlobalFilter: true,
    enableColumnFilter: true,
    enableHiding: true
  }),
  columnHelper.accessor('project', {
    id: 'project',
    header: 'Project',
    cell: (row) => row.getValue(),
    enableGlobalFilter: true,
    enableColumnFilter: true,
    enableHiding: true
  }),
  columnHelper.accessor('completionDate', {
    id: 'completionDate',
    header: 'FRIS Completion Date.',
    cell: (row) => row.getValue(),
    enableGlobalFilter: true,
    enableColumnFilter: true,
    enableHiding: true
  }),
  columnHelper.accessor('time', {
    id: 'time',
    header: 'FRIS Time.',
    cell: (row) => row.getValue(),
    enableGlobalFilter: true,
    enableColumnFilter: true,
    enableHiding: true
  }),
  columnHelper.accessor('quantity', {
    id: 'quantity',
    header: 'Quantity.',
    cell: (row) => row.getValue(),
    enableGlobalFilter: true,
    enableColumnFilter: true,
    enableHiding: true
  }),
  columnHelper.accessor('meterReading', {
    id: 'meterReading',
    header: 'Meter Reading.',
    cell: (row) => row.getValue(),
    enableGlobalFilter: true,
    enableColumnFilter: true,
    enableHiding: true
  }),
  columnHelper.accessor('fuelTruckNo', {
    id: 'fuelTruckNo',
    header: 'Fuel Truck No.',
    cell: (row) => row.getValue(),
    enableGlobalFilter: true,
    enableColumnFilter: true,
    enableHiding: true
  }),
  columnHelper.accessor('operatorName', {
    id: 'operatorName',
    header: 'Operator Name',
    cell: (row) => row.getValue(),
    enableGlobalFilter: true,
    enableColumnFilter: true,
    enableHiding: true
  }),
  columnHelper.accessor('unitCost', {
    id: 'unitCost',
    header: 'Unit Cost',
    cell: (row) => row.getValue(),
    enableGlobalFilter: true,
    enableColumnFilter: true,
    enableHiding: true
  }),
  columnHelper.accessor('totalPartsCost', {
    id: 'totalPartsCost',
    header: 'Total Parts Cost',
    cell: (row) => row.getValue(),
    enableGlobalFilter: true,
    enableColumnFilter: true,
    enableHiding: true
  }),
  columnHelper.accessor('ownershipType', {
    id: 'ownershipType',
    header: 'Ownership Type',
    cell: (row) => row.getValue() === 'Rental' ? 'Rented' : row.getValue(),
    enableGlobalFilter: true,
    enableColumnFilter: true,
    enableHiding: true
  }),
];

export default EFCRColumns;