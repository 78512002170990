import { type Role } from './roles-utils';

import HomeRepairServiceIcon from '@mui/icons-material/HomeRepairService';
import BarChartIcon from '@mui/icons-material/BarChart';
import PersonIcon from '@mui/icons-material/Person';
import GroupIcon from '@mui/icons-material/Group';
import EvStationIcon from '@mui/icons-material/EvStation';
import NoteAltIcon from '@mui/icons-material/NoteAlt';
import { Stack } from '@mui/material';
const moduleIconMap = {
  EJO: <NoteAltIcon />,
  ESR: <HomeRepairServiceIcon />,
  USER: <PersonIcon />,
  ROLE: <GroupIcon />,
  FRIS: <EvStationIcon />,
  EUR: <BarChartIcon />,
};

function RoleIconList({ role }: { role: Role }) {
  const moduleNames = Array.from(
    new Set(role.permissions.map((p) => p.split('::')[0]))
  ) as Array<keyof typeof moduleIconMap>;

  return (
    <Stack gap={1} direction='row'>
      {moduleNames.map((moduleName) => (
        <span key={moduleName}>{moduleIconMap[moduleName]}</span>
      ))}
    </Stack>
  );
}

export default RoleIconList;
