import { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { Controller, useFieldArray, useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { SelectOptionType } from '../../../../components/common/forms/form-types';
import { ICreatePickUpForm } from '../utils/types';
import { CreatePickUpInitialValues, CreatePickUpSchema } from '../utils/schema';
import { InSituOptions } from '../utils/options';
import { useGetLocationsQuery } from '../../../../hooks/useLocations';
import { useGetEPFERFById, useCreatePickUp } from '../../../../hooks/useEPFERF';
import AppDatePicker from '../../../../components/common/forms/AppDatePicker';
import AppSelect from '../../../../components/common/forms/AppSelect';
import AppTextField from '../../../../components/common/forms/AppTextField';
import AppDialog from '../../../../components/common/AppDialog';
import AppPrimeUploadForm from '../../../../components/common/forms/AppPrimeUploadForm';
import LoadingIndicator from '../../../../components/common/LoadingIndicator';
import Stack from '@mui/material/Stack';
import Button from '@mui/material/Button';
import moment from 'moment';

const CreatePickUpForm = () => {
  const [fileName, setFileName] = useState('');
  const navigate = useNavigate();
  const createPickup = useCreatePickUp();
  const erfToUpdate = useGetEPFERFById();
  const erfToUpdateData = erfToUpdate.data;
  const locationData = useGetLocationsQuery({type: "Project"});
  const locationOptions : SelectOptionType[] = 
  locationData?.data === undefined ? [] : locationData.data.map(l => {
                                          return {
                                            id: l.id,
                                            label: l.name,
                                            value: l.id
                                          }
                                        });

  const {
    register,
    handleSubmit,
    setValue,
    control,
    watch,
    formState: { errors, isValid },
  } = useForm<ICreatePickUpForm>({
    defaultValues: erfToUpdateData! 
                  ? {
                      inSitu : "No",
                      eorrPickUpLocationId: erfToUpdateData.locationId,
                      eorrETICNo: '',
                      eorrETICAttachment: [],
                      eorrDropOffLocationId: '',
                      eorrTruckNo: '',
                      eorrEPUD: new Date(Date.now()),
                      eorrPickUpRemarks: '',
                    }
                  : CreatePickUpInitialValues,
    mode: 'onChange',
    resolver: yupResolver(CreatePickUpSchema),
  });  

  useEffect(() => {
    if (!erfToUpdateData) return;

    const { locationId } = erfToUpdateData;
    setValue("eorrPickUpLocationId", locationId);

  }, [erfToUpdateData, setValue]);

  const onSubmit = (formData: ICreatePickUpForm) => {
    createPickup.mutate(formData);
  };

  const inSitu = watch('inSitu');

  const mediaFiles = watch('eorrETICAttachment');
  const { append } = useFieldArray({ control, name: 'eorrETICAttachment' });

  const handleMediaUpload = (files: FileList | null) => {
    if (!files) return;

    // check for possible duplicate files (lookup value is filename)
    const emailFilenames = mediaFiles.map((item) => item.fileName);

    for (let i = 0; i < files.length; i++) {
      if (emailFilenames.includes(files[i].name)) {
        return console.log(`${files[i].name} already exists`);
      }
    }

    Array.from(files).forEach((file) => {
      append({
        fileName: file.name,
        source: file,
      });
    });
    setFileName(files[0].name);
  };

  const handleClose = () => navigate('/my-equipment-requests/my-eorr', { replace: true });

  if (erfToUpdate.isLoading || createPickup.isLoading) {
    return <LoadingIndicator show />;
  }

  return (
    <AppDialog open onDialogClose={handleClose} title='Create Pickup' maxWidth='sm' titleAlignment="center">
      <Stack component='form' height='100%' bgcolor='white' py={3} borderRadius={1}>
        <Stack px={3} height='100%' overflow='auto' gap={5}>
          <Stack direction='column' gap={2} width="100%" justifyContent='center' margin='auto'>
            <Stack direction="row" gap={2}>
              <AppTextField
                label="Equipment No."
                labelPosition="top"
                labelAlignment="left"
                labelWidth='100%'
                fieldWidth= '100%'
                fontWeight={400}
                value={erfToUpdateData?.equipmentNo}
                disabled={true}
              />
              <AppTextField
                label="EORR Pick Up No. Creation Date"
                labelPosition="top"
                labelAlignment="left"
                labelWidth='100%'
                fieldWidth= '100%'
                fontWeight={400}
                value={moment(new Date()).format('MM/DD/yyyy')}
                disabled={true}
              />
            </Stack>
            <Controller
              control={control}
              name='inSitu'
              render={({ field }) => (
                <AppSelect
                  sx={{ width: "100%" }}
                  label="In Situ"
                  labelPosition="top"
                  labelAlignment="left"
                  labelWidth='100%'
                  fieldWidth= '100%'
                  fontWeight={400}
                  placeholder="Select here"
                  error={!!errors.inSitu}
                  helperText={errors.inSitu?.message}
                  options={InSituOptions}
                  {...register('inSitu')}
                  {...field}
                />
              )}
            />
            {
              inSitu === "No" && 
              <Controller
                control={control}
                name='eorrPickUpLocationId'
                render={({ field }) => (
                  <AppSelect
                    sx={{ width: "100%" }}
                    label="EORR Pick Up Location"
                    labelPosition="top"
                    labelAlignment="left"
                    labelWidth='100%'
                    fieldWidth= '100%'
                    fontWeight={400}
                    placeholder="Select here"
                    error={!!errors.eorrPickUpLocationId}
                    helperText={errors.eorrPickUpLocationId?.message}
                    options={locationOptions}
                    {...register('eorrPickUpLocationId')}
                    {...field}
                  />
                )}
              />
            }
            <AppTextField
              label="EORR ETIC No."
              labelPosition="top"
              labelAlignment="left"
              labelWidth='100%'
              fieldWidth= '100%'
              fontWeight={400}
              error={!!errors.eorrETICNo}
              helperText={errors.eorrETICNo?.message}
              {...register('eorrETICNo')}
            />
             <AppPrimeUploadForm
              label='EORR ETIC Attachment'
              fileName={fileName}
              key={mediaFiles.length}
              onChange={(e) => handleMediaUpload(e.currentTarget.files)}
              labelPosition="top"
              labelAlignment="left"
              labelWidth='0%'
              fieldWidth= '100%'
              fontWeight={400}
              mt='0px'
            />
            {
              inSitu === "No" && 
              <>
                <Controller
                  control={control}
                  name='eorrDropOffLocationId'
                  render={({ field }) => (
                    <AppSelect
                      sx={{ width: "100%" }}
                      label="EORR Drop Off Location"
                      labelPosition="top"
                      labelAlignment="left"
                      labelWidth='100%'
                      fieldWidth= '100%'
                      fontWeight={400}
                      placeholder="Select here"
                      error={!!errors.eorrDropOffLocationId}
                      helperText={errors.eorrDropOffLocationId?.message}
                      options={locationOptions}
                      {...register('eorrDropOffLocationId')}
                      {...field}
                    />
                  )}
                />
                <AppTextField
                  label="EORR Truck No."
                  labelPosition="top"
                  labelAlignment="left"
                  labelWidth='100%'
                  fieldWidth= '100%'
                  fontWeight={400}
                  error={!!errors.eorrTruckNo}
                  helperText={errors.eorrTruckNo?.message}
                  {...register('eorrTruckNo')}
                />
                <Controller
                  control={control}
                  name='eorrEPUD'
                  render={({ field: { value, onChange } }) => (
                    <AppDatePicker
                      name='date'
                      value={value}
                      onChange={onChange}
                      label='EORR Estimated Pickup Date'
                      labelPosition="top"
                      labelAlignment="left"
                      labelWidth='100%'
                      fieldWidth= '100%'
                      fontWeight={400}
                      error={!!errors.eorrEPUD}
                      helperText={errors.eorrEPUD?.message}
                    />
                  )}
                />
              </>
            }
            <AppTextField
              label="EORR Remarks"
              labelPosition="top"
              labelAlignment="left"
              labelWidth='100%'
              fieldWidth= '100%'
              fontWeight={400}
              multiline
              rows={3}
              error={!!errors.eorrPickUpRemarks}
              helperText={errors.eorrPickUpRemarks?.message}
              {...register('eorrPickUpRemarks')}
            />
            <Stack mt={2}>
              <Button 
                onClick={handleSubmit(onSubmit)}
                disabled={!isValid} 
                sx={{ width: 200, m: '0 auto' }}
              >
                Submit
              </Button>
            </Stack>
          </Stack>
        </Stack>
      </Stack>
    </AppDialog>
  );
}

export default CreatePickUpForm;