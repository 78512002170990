import { Outlet } from 'react-router-dom';
import PageContainer from '../../../../components/common/PageContainer';
import EETTable from './Table';

const EETPage = () => {
  return (
    <>
      <PageContainer 
        title='Reports' 
        subTitle='Equipment Cost Reports'
        submoduleTitle='EJO-ESR Timeline Report'  
        showDate
      >
        <EETTable />
      </PageContainer>
      <Outlet />
    </>
  );
}

export default EETPage;