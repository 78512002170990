import { useQuery } from '@tanstack/react-query';
import { useParams } from 'react-router';
import { Equipment } from '../modules/MyDashboardsPlusTasks/EUR/utils/types';
import equipmentApi from '../services/equipments';

interface ApiError {
  status: number;
  message: string;
}
interface useGetEquipmentOptions {
  dropdown?: boolean;
  productFamilyId?: string;
}

export function useGetEquipmentsQuery(options?: useGetEquipmentOptions) {
  return useQuery<Equipment[]>(['equipments'], () => equipmentApi.getEquipments(), {
    onError(err) {
      console.log(err);
    },
    select(equipments) {
      if (options?.dropdown) {
        const uniqueEquipments = equipments.filter(
          (obj, idx) => equipments.findIndex((eq) => eq.productFamily.categoryName === obj.productFamily.categoryName) === idx
        )

        return uniqueEquipments;
      } else {
        return equipments;
      }
    }
  });
}

export function useGetEquipmentsRatingQuery(options?: useGetEquipmentOptions) {
  return useQuery<Equipment[]>(['equipments'], () => equipmentApi.getEquipments(), {
    onError(err) {
      console.log(err);
    },
    select(equipments) {
      if (options?.productFamilyId && options?.productFamilyId.trim().length !== 0) {
        const equipment = equipments.filter(eq => eq.id === options?.productFamilyId);
        const productFamilyId = equipment[0].productFamilyId;
        const filteredEqByProductFamily = equipments.filter(e => e.productFamilyId === productFamilyId);

        const uniqueEquipmentsByCapacity = filteredEqByProductFamily.filter(
          (obj, idx) => filteredEqByProductFamily.findIndex((eq) => eq.capacity === obj.capacity) === idx
        )
        
        return uniqueEquipmentsByCapacity;
      } else {
        return equipments;
      }

      return equipments;
    }
  });
}

export function useGetEquipmentById() {
  const { id } = useParams<{ id: string }>();
  return useQuery([`equipments-${id}`, id], () => equipmentApi.getEquipmentsById(id!));
}