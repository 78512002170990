import { ReactNode } from 'react';
import { useNavigate } from 'react-router-dom';

import Box from '@mui/material/Box';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import BackIcon from '@mui/icons-material/ArrowBack';
import Stack from '@mui/material/Stack';
import Divider from '@mui/material/Divider';
import { appColors } from '../../theme';
import { getDateToday } from '../../utils/helpers';

interface PageContainerProps {
  children: ReactNode;
  title: string;
  subTitle?: string;
  submoduleTitle?: string;
  backTo?: string;
  controls?: ReactNode;
  loading?: boolean;
  titleDate?: boolean;
  showDate?: boolean;
}

const PageContainer = (props: PageContainerProps) => {
  const navigate = useNavigate();

  const {
    children,
    showDate,
    titleDate = getDateToday(),
    title,
    backTo,
    controls,
    loading,
    subTitle,
    submoduleTitle
  } = props;

  const handleBack = () => {
    if (!backTo) return;

    navigate(backTo, { replace: true });
  };

  return (
    <Stack p={1} height='calc(100% - 64px)'>
      <Stack
        direction='row'
        alignItems='center'
        gap={2}
        mb={2}
        sx={{ opacity: loading ? 0 : 1 }}
      >
        {backTo && (
          <IconButton
            aria-label='back'
            onClick={handleBack}
            edge='start'
            color='primary'
          >
            <BackIcon />
          </IconButton>
        )}
        <Typography
          sx={{ color: appColors.titleColor }}
          fontWeight={600}
          fontSize={32}
          letterSpacing={0.5}
        >
          {title} {subTitle && '>'}
        </Typography>

        <Typography
          sx={{ color: appColors.titleColor }}
          fontWeight={200}
          fontSize={20}
          letterSpacing={0}
        >
          {subTitle} {submoduleTitle && '>'}
        </Typography>

        <Stack>
          <Typography
            sx={{ color: appColors.titleColor }}
            fontWeight={500}
            fontSize={20}
            letterSpacing={0}
          >
            {submoduleTitle}
          </Typography>
          <Divider variant="middle" style={{ borderBottomWidth: 2, borderColor: appColors.primary }} />
        </Stack>

        {controls && (
          <Stack ml='auto' direction='row' alignItems='center'>
            {controls}
          </Stack>
        )}
      </Stack>
      {showDate && (
        <Typography
          sx={{ color: appColors.labelColor, mt: -2 }}
          fontSize={14}
          mb={1}
        >
          {titleDate}
        </Typography>
      )}
      <Box height='calc(100% - 61px)' position='relative'>
        {children}
      </Box>
    </Stack>
  );
};

export default PageContainer;
