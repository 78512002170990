import { ICPC } from '../../modules/Reports/EquipmentCostReports/utils/types';
import { apiClient, apiEndpoints } from '../apiClient';

export type GetManyReturnType = {
  status: string;
  data: ICPC[];
};

const getAllCPC = async (status?: string) => {
  const response = await apiClient.get<GetManyReturnType>(apiEndpoints.cpc);
  return response.data.data;
};

const cpcApi = {
    getAllCPC
};

export default cpcApi;