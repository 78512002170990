export const InSituOptions = [
  {
    "id" : "Yes",
    "label" : "Yes",
    "value" : "Yes"
  },
  {
    "id" : "No",
    "label" : "No",
    "value" : "No"
  }
]