import { Outlet } from 'react-router-dom';
import PageContainer from '../../../../components/common/PageContainer';
import AllocationsTable from './Table';

const AllocationsPage = () => {
  return (
    <>
      <PageContainer 
        title='My Dashboard + Tasks' 
        subTitle='My Equipment Requests'
        submoduleTitle='My Allocations'  
        showDate
      >
        <AllocationsTable />
      </PageContainer>
      <Outlet />
    </>
  );
}

export default AllocationsPage;