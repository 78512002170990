import { createColumnHelper } from '@tanstack/react-table';
import { EET } from '../utils/types';
import Checkbox from '@mui/material/Checkbox';
import HeaderCellCheckbox from '../../../../components/common/primebmd-ui/HeaderCellCheckbox';
import moment from 'moment';

const columnHelper = createColumnHelper<EET>();

const EETColumns = [
    columnHelper.display({
        id: 'select',
        header: ({ table }) => (
        <HeaderCellCheckbox
            checked={table.getIsAllRowsSelected()}
            indeterminate={table.getIsSomeRowsSelected()}
            onChange={table.getToggleAllRowsSelectedHandler()}
        />
        ),
        cell: ({ row }) => (
        <Checkbox
            checked={row.getIsSelected()}
            indeterminate={row.getIsSomeSelected()}
            onChange={row.getToggleSelectedHandler()}
        />
        ),
    }),
    columnHelper.accessor('ejoNumber', {
        id: 'ejoNumber',
        header: 'EJO Number',
        cell:  (row) => row.getValue(),
        enableGlobalFilter: true,
        enableColumnFilter: true,
    }),
    columnHelper.accessor('ejoCreatedDate', {
        id: 'ejoCreatedDate',
        header: 'EJO Creation Date',
        cell: (row) => row.getValue() != '' ? moment(row.getValue()).format('MM/DD/yyyy'): '-',
        enableGlobalFilter: true,
        enableColumnFilter: true,
    }),
    columnHelper.accessor('ejoApprovalDate', {
        id: 'ejoApprovalDate',
        header: 'EJO Approval Date',
        cell: (row) => row.getValue() != '' ? moment(row.getValue()).format('MM/DD/yyyy'): '-',
        enableGlobalFilter: true,
        enableColumnFilter: true,
    }),
    columnHelper.accessor('esrNumber', {
        id: 'esrNumber',
        header: 'ESR Number',
        cell:  (row) => row.getValue(),
        enableGlobalFilter: true,
        enableColumnFilter: true,
    }),
    columnHelper.accessor('esrCreatedDate', {
        id: 'esrCreatedDate',
        header: 'ESR Creation Date',
        cell: (row) => row.getValue() != '' ? moment(row.getValue()).format('MM/DD/yyyy'): '-',
        enableGlobalFilter: true,
        enableColumnFilter: true,
    }),
    columnHelper.accessor('esrApprovalDate', {
        id: 'esrApprovalDate',
        header: 'ESR Approval Date',
        cell: (row) => row.getValue() != '' ? moment(row.getValue()).format('MM/DD/yyyy'): '-',
        enableGlobalFilter: true,
        enableColumnFilter: true,
    }),
    columnHelper.accessor('esrStartDate', {
        id: 'esrStartDate',
        header: 'ESR Start Date',
        cell: (row) => row.getValue() != '' ? moment(row.getValue()).format('MM/DD/yyyy'): '-',
        enableGlobalFilter: true,
        enableColumnFilter: true,
    }),
    columnHelper.accessor('esrCompletionDate', {
        id: 'esrCompletionDate',
        header: 'ESR Completion Date',
        cell: (row) => row.getValue() != '' ? moment(row.getValue()).format('MM/DD/yyyy'): '-',
        enableGlobalFilter: true,
        enableColumnFilter: true,
    }),
    columnHelper.accessor('equipmentNumber', {
        id: 'equipmentNumber',
        header: 'Equipment No',
        cell:  (row) => row.getValue(),
        enableGlobalFilter: true,
        enableColumnFilter: true,
    }),
    columnHelper.accessor('model', {
        id: 'model',
        header: 'Model',
        cell:  (row) => row.getValue(),
        enableGlobalFilter: true,
        enableColumnFilter: true,
    }),
    columnHelper.accessor('productFamily', {
        id: 'productFamily',
        header: 'Product Family',
        cell:  (row) => row.getValue(),
        enableGlobalFilter: true,
        enableColumnFilter: true,
    }),
    columnHelper.accessor('make', {
        id: 'make',
        header: 'Make',
        cell:  (row) => row.getValue(),
        enableGlobalFilter: true,
        enableColumnFilter: true,
    }),
    columnHelper.accessor('project', {
        id: 'project',
        header: 'Project',
        cell:  (row) => row.getValue(),
        enableGlobalFilter: true,
        enableColumnFilter: true,
    }),
    columnHelper.accessor('ownedOrRented', {
        id: 'ownedOrRented',
        header: 'Ownership Type',
        cell:  (row) => row.getValue(),
        enableGlobalFilter: true,
        enableColumnFilter: true,
    })
];

export default EETColumns;