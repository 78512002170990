import { Outlet } from 'react-router-dom';
import PageContainer from '../../../../components/common/PageContainer';
import HistoricalRentalsTable from './Table';

const HistoricalRentalsPage = () => {
  return (
    <>
      <PageContainer 
        title='Reports' 
        subTitle='Equipment Use & Performance Reports'
        submoduleTitle='Historical Rentals'  
        showDate
      >
        <HistoricalRentalsTable />
      </PageContainer>
      <Outlet />
    </>
  );
}

export default HistoricalRentalsPage;