import { useNavigate } from 'react-router-dom';
import { Controller, useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { IEORRForm } from './utils/types';
import { CreateEORRInitialValues, CreateEORRSchema } from './utils/schema';
import { useGetEPFERFById } from '../../../hooks/useEPFERF';
import { useCreateEORR } from '../../../hooks/useOnRentEquipments';
import Stack from '@mui/material/Stack';
import Button from '@mui/material/Button';
import AppDatePicker from '../../../components/common/forms/AppDatePicker';
import AppTextField from '../../../components/common/forms/AppTextField';
import AppDialog from '../../../components/common/AppDialog';
import LoadingIndicator from '../../../components/common/LoadingIndicator';
import moment from 'moment';

const CreateEORRForm = () => {
  const {
    register,
    handleSubmit,
    control,
    formState: { errors, isValid },
  } = useForm<IEORRForm>({
    defaultValues: CreateEORRInitialValues,
    mode: 'onChange',
    resolver: yupResolver(CreateEORRSchema),
  });  

  const navigate = useNavigate();
  const createEORR = useCreateEORR();
  const erfToUpdate = useGetEPFERFById();
  const erfToUpdateData = erfToUpdate.data;

  const handleClose = () => navigate('/my-on-rent-equipment', { replace: true });

  const onSubmit = (formData: IEORRForm) => {
    createEORR.mutate(formData);
  };

  if (erfToUpdate.isLoading || createEORR.isLoading) {
    return <LoadingIndicator show />;
  }

  return (
    <AppDialog open onDialogClose={handleClose} title='New EORR' maxWidth='sm' titleAlignment="center">
      <Stack component='form' height='100%' bgcolor='white' py={3} borderRadius={1}>
        <Stack px={3} height='100%' overflow='auto' gap={5}>
          <Stack direction='column' gap={2} width="100%" justifyContent='center' margin='auto'>
            <AppTextField
              label="Equipment No."
              labelPosition="top"
              labelAlignment="left"
              labelWidth='100%'
              fieldWidth= '100%'
              fontWeight={400}
              value={erfToUpdateData?.equipmentNo}
              disabled={true}
            />
            <Stack direction="row" gap={2}>
              <AppTextField
                label="Product Family"
                labelPosition="top"
                labelAlignment="left"
                labelWidth='100%'
                fieldWidth= '100%'
                fontWeight={400}
                value={erfToUpdateData?.productFamily}
                disabled={true}
              />
              <AppTextField
                label="Capacity"
                labelPosition="top"
                labelAlignment="left"
                labelWidth='100%'
                fieldWidth= '100%'
                fontWeight={400}
                value={erfToUpdateData?.capacity}
                disabled={true}
              />
            </Stack>
            <AppTextField
              label="EORR Creation Date"
              labelPosition="top"
              labelAlignment="left"
              labelWidth='100%'
              fieldWidth= '100%'
              fontWeight={400}
              value={moment(new Date()).format('MM/DD/yyyy')}
              disabled={true}
            />
            <Controller
              control={control}
              name='eorrRequestedPUD'
              render={({ field: { value, onChange } }) => (
                <AppDatePicker
                  name='date'
                  value={value}
                  onChange={onChange}
                  label='Requested Pick Up Date'
                  labelPosition="top"
                  labelAlignment="left"
                  labelWidth='100%'
                  fieldWidth= '100%'
                  fontWeight={400}
                  error={!!errors.eorrRequestedPUD}
                  helperText={errors.eorrRequestedPUD?.message}
                />
              )}
            />
            <AppTextField
              label="EORR Remarks"
              labelPosition="top"
              labelAlignment="left"
              labelWidth='100%'
              fieldWidth= '100%'
              fontWeight={400}
              multiline
              rows={3}
              error={!!errors.eorrRemarks}
              helperText={errors.eorrRemarks?.message}
              {...register('eorrRemarks')}
            />
            <Stack mt={2}>
              <Button 
                onClick={handleSubmit(onSubmit)}
                disabled={!isValid} 
                sx={{ width: 200, m: '0 auto' }}
              >
                Submit
              </Button>
            </Stack>
          </Stack>
        </Stack>
      </Stack>
    </AppDialog>
  );
}

export default CreateEORRForm;