import * as yup from 'yup';
import { SelectOptionType } from '../../../components/common/forms/form-types';

export type EmailMedia = {
  fileName: string;
  source: File;
};

export type EmailAttachment = {
  filename: string;
  path: string;
};

export interface ICertificateTracker {
  id: string;
  equipmentId: string;
  equipmentNo: string;
  equipmentType: string;
  certificateName: string;
  certificateNo: string;
  governmentBody: string;
  issuingAgency: string;
  issuanceDate: Date;
  validityDate: Date;
  pic: string;
  media: string;
}

export interface IEmailForm {
  toemail: string[];
  ccemail: string[];
  subject: string;
  message: string;
  media: EmailMedia[];
}

export interface logCertificateFields {
  equipmentNo: string;
  equipmentType: string;
  certificateName: string;
  certificateNo: string;
  governmentBody: string;
  issuingAgency: string;
  issuanceDate: Date;
  validityDate: Date;
  pic: string;
  media: EmailMedia[];
}

export const equipmentNumberList: SelectOptionType[] = [
  {
    id: 'BL-01',
    label: 'BL-01',
    value: 'BL-01',
  },
  {
    id: 'BL-02',
    label: 'BL-02',
    value: 'BL-02',
  },
];

export const equipmentNameList: SelectOptionType[] = [
  {
    id: 'airCompressor',
    label: 'Air Compressor',
    value: 'airCompressor',
  },
  {
    id: 'truck',
    label: 'Truck',
    value: 'truck',
  },
];
export const emailSchema = yup.object({
  toemail: yup.array().of(yup.string().email('Enter a valid email').required('Email is required')),
  ccemail: yup.array().of(yup.string().email('Enter a valid email')),
  subject: yup.string(),
  message: yup.string(),
  media: yup.array().max(3, 'You can only upload a maximum of 3 files'),
});

export const emailInitialValues: IEmailForm = {
  toemail: [''],
  ccemail: [''],
  subject: '',
  message: '',
  media: [],
};

export const logCertificateSchema = yup.object({
  certificateName: yup.string().required(),
  certificateNo: yup.string().required(),
  governmentBody: yup.string().optional(),
  issuingAgency: yup.string().optional(),
  pic: yup.string().optional(),
  issuanceDate: yup.date().required(),
  validityDate:  yup.date().required(),
  // validityDate: yup
  //   .date()
  //   .when(
  //     'issuanceDate',
  //     (issuanceDate, schema) =>
  //     issuanceDate &&
  //       schema.min(
  //         issuanceDate,
  //         'Expiration Date must not be less than or equals to the Order Date.'
  //       )
  //   )
  //   .required(),
  // equipmentNumber: yup.string().required(),
  // equipmentType: yup.string().required(),
  media: yup.array().optional(),
});

export const logCertificateInitialValues: logCertificateFields = {
  certificateName: '',
  certificateNo: '',
  governmentBody: '',
  issuingAgency: '',
  pic: '',
  issuanceDate: new Date(Date.now()),
  validityDate: new Date(new Date().setDate(new Date().getDate() + 5)),
  equipmentNo: '',
  equipmentType: '',
  media: [],
};
