import PageContainer from '../../../../components/common/PageContainer';

function RentalRatesPage() {
  return (
    <>
      <PageContainer title='Other Databases' subTitle='Rental Rates Master' showDate>
        Rental Rates Master
      </PageContainer>
    </>
  );
}

export default RentalRatesPage;
