import { useQuery } from '@tanstack/react-query';
import { DAIU } from '../modules/Reports/EquipmentUseAndPerformanceReports/utils/types';
import daiuApi from '../services/reports/daiu';

export function useGetDAIUQuery(startDate?: string, endDate?: string) {
  return useQuery<DAIU[]>(['daius', startDate, endDate], () => daiuApi.getDAIU(startDate, endDate), {
    onError(err) {
      console.log(err);
    },
  });
}

export const useFormatGetDAIUQuery = (data: DAIU[]) => {
  const newDAIUList = [];
  
  const DAIUList = data;

  for(let i in DAIUList){
    let json = {
      capacity: DAIUList[i].capacity,
      categoryName:  DAIUList[i].categoryName,
      downHours:  DAIUList[i].downHours,
      equipmentNo: DAIUList[i].equipmentNo,
      make: DAIUList[i].make,
      model: DAIUList[i].model,
      name: DAIUList[i].name,
      dailyShiftHours:  DAIUList[i].dailyShiftHours,
      date:  DAIUList[i].date,
      monthYear:  DAIUList[i].monthYear,
      availableHours:  DAIUList[i].availableHours,
      operatingHour: DAIUList[i].operatingHour,
      idleTime: DAIUList[i].idleTime,
      ownershipType: DAIUList[i].ownershipType === 'Rental' ? 'Rented' : DAIUList[i].ownershipType
    }
    newDAIUList.push(json);
  }
  return newDAIUList;
}
