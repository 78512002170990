import { useNavigate } from 'react-router-dom';
import { Controller, useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import Stack from '@mui/material/Stack';
import Button from '@mui/material/Button';
import AppDatePicker from '../../../../components/common/forms/AppDatePicker';
import AppSelect from '../../../../components/common/forms/AppSelect';
import AppTextField from '../../../../components/common/forms/AppTextField';
import AppDialog from '../../../../components/common/AppDialog';
import LoadingIndicator from '../../../../components/common/LoadingIndicator';
import { SelectOptionType } from '../../../../components/common/forms/form-types';
import { ICreateERFForm } from '../utils/types';
import { CreateERFInitialValues, CreateERFSchema } from '../utils/schema';
import { useGetEquipmentsQuery, useGetEquipmentsRatingQuery } from '../../../../hooks/useEquipments';
import { useGetLocationsQuery } from '../../../../hooks/useLocations';
import { useCreateEPFERF } from '../../../../hooks/useEPFERF';

const CreateEPFERFForm = () => {
  const {
    register,
    handleSubmit,
    control,
    watch,
    formState: { errors, isValid },
  } = useForm<ICreateERFForm>({
    defaultValues: CreateERFInitialValues,
    mode: 'onChange',
    resolver: yupResolver(CreateERFSchema),
  });

  const navigate = useNavigate();
  const equipmentData = useGetEquipmentsQuery({dropdown: true});
  const ratings = useGetEquipmentsRatingQuery({productFamilyId: watch("productFamilyId") });
  const locationData = useGetLocationsQuery({type: "Project"});
  const createEPFERF = useCreateEPFERF();
  const equipmentOptions : SelectOptionType[] = 
    equipmentData?.data === undefined ? [] : equipmentData.data.map(e => {
                                              return { 
                                                id: e.id, 
                                                label: e.productFamily.categoryName, 
                                                value: e.id 
                                              }
                                             });
  const locationOptions : SelectOptionType[] = 
    locationData?.data === undefined ? [] : locationData.data.map(l => {
                                            return {
                                              id: l.id,
                                              label: l.name,
                                              value: l.id
                                            }
                                          });
  const ratingOptions : SelectOptionType[] = 
  ratings?.data === undefined ? [] : ratings.data.map(r => {
                                          return {
                                            id: r.id,
                                            label: r.capacity,
                                            value: r.id
                                          }
                                        });        

  const onSubmit = (formData: ICreateERFForm) => {
    createEPFERF.mutate(formData);
  };

  if (createEPFERF.isLoading) {
    return <LoadingIndicator show />;
  }

  const handleClose = () => navigate('/my-equipment-requests/my-erf', { replace: true });

  return (
    <AppDialog open onDialogClose={handleClose} title='New ERF' maxWidth='sm' titleAlignment="center">
      <Stack component='form' height='100%' bgcolor='white' py={3} borderRadius={1}>
        <Stack px={3} height='100%' overflow='auto' gap={5}>
          <Stack direction='column' gap={2} width="100%" justifyContent='center' margin='auto'>
            {/* <Controller
              control={control}
              name='locationId'
              render={({ field }) => (
                <AppSelect
                  label="EPF No."
                  labelPosition="top"
                  labelAlignment="left"
                  labelWidth='100%'
                  fieldWidth= '100%'
                  fontWeight={400}
                  placeholder="Select here"
                  error={!!errors.locationId}
                  helperText={errors.locationId?.message}
                  options={[]}
                  {...register('locationId')}
                  {...field}
                />
              )}
            /> */}
            <Controller
              control={control}
              name='locationId'
              render={({ field }) => (
                <AppSelect
                  label="Project Name"
                  labelPosition="top"
                  labelAlignment="left"
                  labelWidth='100%'
                  fieldWidth= '100%'
                  fontWeight={400}
                  placeholder="Select here"
                  error={!!errors.locationId}
                  helperText={errors.locationId?.message}
                  options={locationOptions}
                  {...register('locationId')}
                  {...field}
                />
              )}
            />
            <Stack direction="row" width="100%" gap={2}>
              <Controller
                control={control}
                name='productFamilyId'
                render={({ field }) => (
                  <AppSelect
                    sx={{ width: "100%" }}
                    label="Product Family"
                    labelPosition="top"
                    labelAlignment="left"
                    labelWidth='100%'
                    fieldWidth= '100%'
                    fontWeight={400}
                    placeholder="Select here"
                    error={!!errors.productFamilyId}
                    helperText={errors.productFamilyId?.message}
                    options={equipmentOptions}
                    {...register('productFamilyId')}
                    {...field}
                  />
                )}
              />
              <Controller
                control={control}
                name='capacityId'
                render={({ field }) => (
                  <AppSelect
                    sx={{ width: "100%" }}
                    label="Capacity"
                    labelPosition="top"
                    labelAlignment="left"
                    labelWidth='100%'
                    fieldWidth= '100%'
                    fontWeight={400}
                    placeholder="Select here"
                    disabled={watch("productFamilyId").trim().length === 0}
                    error={!!errors.capacityId}
                    helperText={errors.capacityId?.message}
                    options={ratingOptions}
                    {...register('capacityId')}
                    {...field}
                  />
                )}
              />
            </Stack>
            <Stack direction="row" width="100%" gap={2}>
              <Controller
                control={control}
                name='startDate'
                render={({ field: { value, onChange } }) => (
                  <AppDatePicker
                    name='date'
                    value={value}
                    onChange={onChange}
                    label='Start Date'
                    labelPosition="top"
                    labelAlignment="left"
                    labelWidth='100%'
                    fieldWidth= '100%'
                    fontWeight={400}
                    error={!!errors.startDate}
                    helperText={errors.startDate?.message}
                  />
                )}
              />
              <Controller
                control={control}
                name='endDate'
                render={({ field: { value, onChange } }) => (
                  <AppDatePicker
                    name='date'
                    value={value}
                    onChange={onChange}
                    label='End Date'
                    labelPosition="top"
                    labelAlignment="left"
                    labelWidth='100%'
                    fieldWidth= '100%'
                    fontWeight={400}
                    error={!!errors.endDate}
                    helperText={errors.endDate?.message}
                  />
                )}
              />
            </Stack>
            <AppTextField
              label="Quantity"
              labelPosition="top"
              labelAlignment="left"
              labelWidth='100%'
              fieldWidth= '100%'
              fontWeight={400}
              placeholder="00"
              error={!!errors.quantity}
              helperText={errors.quantity?.message}
              {...register('quantity')}
            />
            <AppTextField
              label="Remarks"
              labelPosition="top"
              labelAlignment="left"
              labelWidth='100%'
              fieldWidth= '100%'
              fontWeight={400}
              multiline
              rows={3}
              error={!!errors.remarks}
              helperText={errors.remarks?.message}
              {...register('remarks')}
            />
            <Stack mt={2}>
              <Button 
                onClick={handleSubmit(onSubmit)}
                disabled={!isValid} 
                sx={{ width: 200, m: '0 auto' }}
              >
                Submit
              </Button>
            </Stack>
          </Stack>
        </Stack>
      </Stack>
    </AppDialog>
  );
}

export default CreateEPFERFForm;