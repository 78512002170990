import { createColumnHelper } from '@tanstack/react-table';
import { formatDate } from '../../../utils/helpers';
import { IAccounts } from './accounts-utils';
import AccountsActionButton from './AccountsActionsButtons';

const columnHelper = createColumnHelper<IAccounts>();

const accountsColumns = [
  columnHelper.accessor((row) => `${row.firstName} ${row.lastName}`, {
    id: 'fullName',
    header: 'Name',
    cell: (row) => row.getValue(),
    enableColumnFilter: false,
  }),
  columnHelper.accessor('roleName', {
    id: 'roleName',
    header: 'Role',
    cell: (row) => row.renderValue(),
    enableGlobalFilter: true,
    enableColumnFilter: true,
  }),
  columnHelper.accessor('isActive', {
    id: 'isActive',
    header: 'Status',
    cell: (row) => (row.getValue() ? 'Active' : 'Inactive'),
    enableGlobalFilter: true,
    enableColumnFilter: true,
  }),

  columnHelper.accessor('dateCreated', {
    id: 'dateCreated',
    header: 'Date Created',
    cell: ({ row }) => formatDate(row.original.dateCreated),
    enableGlobalFilter: true,
    enableColumnFilter: true,
  }),
  columnHelper.accessor('dateUpdated', {
    id: 'dateUpdated',
    header: 'Date Updated',
    cell: ({ row }) => formatDate(row.original.dateUpdated),
    enableGlobalFilter: true,
    enableColumnFilter: true,
  }),

  columnHelper.display({
    id: 'actions',
    header: 'Action',
    cell: ({ row }) => <AccountsActionButton userAccount={row.original} />,
    enableHiding: false,
  }),
];

export default accountsColumns;
