import Typography from '@mui/material/Typography';

import { useAuth } from '../../components/AuthProvider';
import { appColors } from '../../theme';
import { getDateToday } from '../../utils/helpers';

function DashboardTitle() {
  const auth = useAuth();

  const user = auth?.authUser;

  if (!user) return null;

  const userName = `${user.firstName} ${user.lastName}`;

  return (
    <>
      <Typography
        sx={{ color: appColors.titleColor }}
        fontWeight={600}
        fontSize={32}
        letterSpacing={0.5}
        align='center'
      >
        Welcome {userName}
      </Typography>

      <Typography
        sx={{ color: appColors.labelColor }}
        fontWeight={200}
        fontSize={20}
        letterSpacing={0}
        align='center'
      >
        {getDateToday()}
      </Typography>
    </>
  );
}

export default DashboardTitle;
