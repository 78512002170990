import { Outlet } from 'react-router-dom';
import PageContainer from '../../../../components/common/PageContainer';
import CLCTable from './Table';

const CLCPage = () => {
  return (
    <>
      <PageContainer 
        title='Reports' 
        subTitle='Equipment Cost Reports'
        submoduleTitle='Cost of Labor Consumed Report'  
        showDate
      >
        <CLCTable />
      </PageContainer>
      <Outlet />
    </>
  );
}

export default CLCPage;