import { Role } from '../modules/Users/Roles/roles-utils';
import { apiClient, apiEndpoints } from './apiClient';

export type GetManyReturnType = {
  status: string;
  data: Role[];
};

type GetOneReturnType = {
  status: string;
  data: Role;
};

const getRoles = async () => {
  const response = await apiClient.get<GetManyReturnType>(apiEndpoints.roles);
  return response.data.data;
};

const getRoleById = async (id: string) => {
  const response = await apiClient.get<GetOneReturnType>(
    `${apiEndpoints.roles}/${id}`
  );
  return response.data.data;
};

const createRole = async (newRoleData: Partial<Role>) => {
  const response = await apiClient.post<GetOneReturnType>(
    apiEndpoints.roles,
    newRoleData
  );
  return response.data.data;
};

const updateRole = async (id: string, updateRoleData: Partial<Role>) => {
  const response = await apiClient.patch<GetOneReturnType>(
    `${apiEndpoints.roles}/${id}`,
    updateRoleData
  );
  return response.data.data;
};

const deleteRole = async (id: string) => {
  const response = await apiClient.delete(`${apiEndpoints.roles}/${id}`);
  return response.data.data;
};

const rolesApi = {
  getRoles,
  getRoleById,
  createRole,
  updateRole,
  deleteRole,
};

export default rolesApi;
