import { QueryClient } from '@tanstack/react-query';

const queryErrorHandler = (error: unknown): void => {
  const title =
    error instanceof Error ? error.message : 'error connecting to the server';
  console.log(title);
};

export const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      onError: queryErrorHandler,
      keepPreviousData: false,
    },
  },
});
