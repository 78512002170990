import { useQuery } from '@tanstack/react-query';
import { Operator } from '../modules/MyDashboardsPlusTasks/EUR/utils/types';
import operatorApi from '../services/operators';

export function useGetOperatorsQuery() {
  return useQuery<Operator[]>(['operator'], () => operatorApi.getOperators(), {
    onError(err) {
      console.log(err);
    },
  });
}