import { useNavigate, useParams } from 'react-router';
import { useQuery, useMutation } from '@tanstack/react-query';
import { queryClient } from '../react-query/queryClient';
import { useSnackBar } from '../components/ToastProvider';
import { AxiosError } from 'axios';
import { IOperatorLicenses, IOperatorLicenseForm, IEmailForm } from '../modules/MyDashboardsPlusTasks/OperatorLicenses/OperatorLicensesUtils';
import { Operator } from '../modules/MyDashboardsPlusTasks/EUR/utils/types';
import operatorLicenseApi from '../services/dashboardPlusTasks/operatorLicenses';
import operatorApi from '../services/operators';

export function useGetOperatorLicenses() {
  return useQuery<IOperatorLicenses[]>(['licenses'], () => operatorLicenseApi.getOperatorLicenses(), {
    onError(err) {
      console.log(err);
    }
  }); 
}

export const useGetOperatorLicensesById = () => {
  const { id } = useParams<{ id: string}>();

  return useQuery<IOperatorLicenses>([`licenses-${id}`, id], () => operatorLicenseApi.getOperatorLicensesById(id!));
}

export function useCreateOperatorLicenses() {
  const { showSnackBar } = useSnackBar();
  const navigate = useNavigate();
  
  return useMutation((newOperatorLicensesData: Partial<IOperatorLicenseForm>) => operatorLicenseApi.createOperatorLicenses(newOperatorLicensesData), {
    onSuccess() {
      queryClient.invalidateQueries({ queryKey: ['licenses'] }).then(() => {
        showSnackBar('Successfully Created Operator License', 'success');
        navigate('/operator-licenses', { replace: true });
      });
    },
    onError(err) {
      const errorData = (err as AxiosError<{ error: string }>).response?.data.error;

      if (err && errorData) {
        showSnackBar(errorData, 'error');
      }
      // const errorData = (err as AxiosError<{ error: ApiError }>).response?.data.error;

      // if (err && errorData) {
      //   showSnackBar(errorData.message.split(':').slice(1).join(' '), 'error');
      // }
    },
  });
}

export function useUpdateOperatorLicenses() {
  const { showSnackBar } = useSnackBar();
  const { id } = useParams<{ id: string }>();
  const navigate = useNavigate();

  return useMutation((newOperatorLicensesData: Partial<IOperatorLicenseForm>) => operatorLicenseApi.updateOperatorLicenses(id!, newOperatorLicensesData), {
    onSuccess() {
      queryClient.invalidateQueries({ queryKey: ['licenses'] }).then(() => {
        showSnackBar('Successfully Updated Operator License', 'success');
        navigate('/operator-licenses', { replace: true });
      });
    },
    onError(err) {
      const errorData = (err as AxiosError<{ error: string }>).response?.data.error;

      if (err && errorData) {
        showSnackBar(errorData, 'error');
      }
      // const errorData = (err as AxiosError<{ error: ApiError }>).response?.data.error;

      // if (err && errorData) {
      //   showSnackBar(errorData.message.split(':').slice(1).join(' '), 'error');
      // }
    },
  });
}

export function useGetOperatorById() {
  const { operatorId } = useParams<{ operatorId: string }>();
  return useQuery<Operator>([`licenses-id`, operatorId], () => operatorApi.getOperatorsById(operatorId!));
}

export function useSendEmail() {
  const { showSnackBar } = useSnackBar();
  const navigate = useNavigate();
  
  return useMutation((emailForm: IEmailForm) => operatorLicenseApi.sendEmail(emailForm), {
    onSuccess() {
      queryClient.invalidateQueries({ queryKey: ['licenses'] }).then(() => {
        showSnackBar('Successfully Sent Email', 'success');
        navigate('/operator-licenses', { replace: true });
      });
    },
    onError(err) {
      const errorData = (err as AxiosError<{ error: string }>).response?.data.error;

      if (err && errorData) {
        showSnackBar(errorData, 'error');
      }
      // const errorData = (err as AxiosError<{ error: ApiError }>).response?.data.error;

      // if (err && errorData) {
      //   showSnackBar(errorData.message.split(':').slice(1).join(' '), 'error');
      // }
    },
  });
}