import { useNavigate, useParams } from 'react-router';
import { useMutation, useQuery } from '@tanstack/react-query';
import { queryClient } from '../react-query/queryClient';
import { AxiosError } from 'axios';
import { useSnackBar } from '../components/ToastProvider';
import { IPR } from '../modules/MyDashboardsPlusTasks/MyEquipmentRequests/utils/types';
import prApi from '../services/dashboardPlusTasks/pr';

export const useGetPRQuery = () => {
  return useQuery<IPR[]>(['prs'], () => prApi.getPR(), {
    onError(err) {
      console.log(err);
    }
  });
}

export function useCreatePR() {
  const { showSnackBar } = useSnackBar();
  const { id } = useParams<{ id: string }>();
  const navigate = useNavigate();

  return useMutation(() => prApi.createPR(id!), {
    onSuccess() {
      queryClient.invalidateQueries({ queryKey: ['epfErfs'] }).then(() => {
        showSnackBar('Successfully Created PR', 'success');
        navigate('/my-equipment-requests/my-erf', { replace: true });
      });
    },
    onError(err) {
      const errorData = (err as AxiosError<{ error: string }>).response?.data.error;

      if (err && errorData) {
        showSnackBar(errorData, 'error');
      }
    }
  });
}

export function useClosePR() {
  const { showSnackBar } = useSnackBar();
  const { id } = useParams<{ id: string }>();
  const navigate = useNavigate();

  return useMutation(() => prApi.closePR(id!), {
    onSuccess() {
      queryClient.invalidateQueries({ queryKey: ['prs'] }).then(() => {
        showSnackBar('Successfully Closed PR', 'success');
        navigate('/my-equipment-requests/my-pr ', { replace: true });
      });
    },
    onError(err) {
      const errorData = (err as AxiosError<{ error: string }>).response?.data.error;

      if (err && errorData) {
        showSnackBar(errorData, 'error');
      }
    }
  });
}