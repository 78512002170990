import { Navigate, Outlet } from 'react-router-dom';
import { useAuth } from '../../../components/AuthProvider';
import PageContainer from '../../../components/common/PageContainer';
import { findArrays, usePermission } from '../../../hooks/usePermissions';

const AccountsPage = () => {
  const permission = usePermission('User');
  // const user = useAuth();
  if (permission !== undefined && !permission) return <Navigate to='/home' />;

  // console.log(findArrays(user?.currentUserRole?.permissions!, 'ALL').filteredArrays.map((e) => e));

  return (
    <>
      <PageContainer title='User Management' subTitle='User' showDate>
        <Outlet />
      </PageContainer>
    </>
  );
};

export default AccountsPage;
