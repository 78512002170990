import PageContainer from '../../../../components/common/PageContainer';

function MechanicPage() {
  return (
    <>
      <PageContainer title='Personnel Databases' subTitle='Mechanic Master' showDate>
        Mechanic Master
      </PageContainer>
    </>
  );
}

export default MechanicPage;
