import { createContext, ReactNode, useState, useContext, useCallback } from 'react';

interface SideNavContextValue {
  expandedNavs: string[];
  handleSideNavExpand: (menuLablel: string, hasNested?: boolean) => void;
}

const SideNavContext = createContext<SideNavContextValue | null>(null);

SideNavContext.displayName = 'SideNavContext';

export const SideNavProvider = ({ children }: { children: ReactNode }) => {
  const [expandedNavs, setExpandedNavs] = useState<string[]>([]);

  console.log(expandedNavs);

  const handleSideNavExpand = useCallback((menuLabel: string, hasNested?: boolean) => {
    setExpandedNavs((old) => (old.includes(menuLabel) ? [] : [menuLabel]));
  }, []);

  return (
    <SideNavContext.Provider value={{ expandedNavs, handleSideNavExpand }}>
      {children}
    </SideNavContext.Provider>
  );
};

export function useSideNav() {
  const context = useContext(SideNavContext);

  if (!context) throw new Error('useSideNav can only be used inside a <SideNavProvide />');

  return context;
}
