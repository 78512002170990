import { Outlet } from 'react-router-dom';
import PageTitle from '../../../components/common/primebmd-ui/PageTitle';

function OperatorLicensePage() {
  return (
    <>
      <PageTitle title='Operator License Tracker' />
      <Outlet />
    </>
  );
}

export default OperatorLicensePage;
