import type { ReactNode } from "react";
import { styled } from "@mui/material/styles";
import { useLayout } from "./layoutContext";

interface FooterProps {
  open?: boolean;
  sidebarWidth?: number;
  collapseToWidth?: number;
  children?: ReactNode;
}

const StyledFooter = styled("footer", {
  shouldForwardProp: (prop) =>
    prop !== "open" && prop !== "sidebarWidth" && prop !== "collapseToWidth",
})<FooterProps>(({ theme, open, sidebarWidth, collapseToWidth }) => ({
  position: "fixed",
  bottom: 0,
  flexGrow: 1,
  borderTop: "1px solid #eee",
  transition: theme.transitions.create(["margin", "width"], {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  width: `calc(100% - ${collapseToWidth}px)`,
  marginLeft: `${collapseToWidth}px`,
  ...(open && {
    width: `calc(100% - ${sidebarWidth}px)`,
    marginLeft: `${sidebarWidth}px`,
    transition: theme.transitions.create(["margin", "width"], {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.enteringScreen,
    }),
  }),
}));

function Footer({ children }: Pick<FooterProps, "children">) {
  const { sidebarOpen, sidebarWidth, collapseToWidth } = useLayout();

  return (
    <StyledFooter open={sidebarOpen} sidebarWidth={sidebarWidth} collapseToWidth={collapseToWidth}>
      {children}
    </StyledFooter>
  );
}

export default Footer;
