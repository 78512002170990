import * as XLSX from 'xlsx';
import { format } from 'date-fns';
import { DAIU, IEURColumns, HistoricalRentalsTracker } from '../utils/types';

const daiuMap = {
  'Date': 'date',
  'Month Year' : 'monthYear',
  'Equipment No.' : 'equipmentNo',
  'Product Family' : 'name',
  'Make' : 'make',
  'Model' : 'model',
  'Capacity' : 'capacity',
  'Project Name': 'categoryName',
  'Shift Hours': 'dailyShiftHours',
  'Down Hours': 'downHours',
  'Available Hours': 'availableHours',
  'Operating Hours': 'operatingHour',
  'Idle Time': 'idleTime',
  'Ownership Type': 'ownershipType'
}

const EURMap = {
  'Date': 'date',
  'EUR No.' : 'eurNo',
  'Equipment No.' : 'equipmentNo',
  'Operator' : 'operator',
  'Location' : 'location',
  'Starting SMR' : 'startingReading',
  'Ending SMR' : 'endingReading'
}

const historicalRentalsMap = {
  'Arrival Date': 'arrivalDate',
  'capacity': 'capacity',
  'Requested Pick Up Date': 'eorrRequestedPUD',
  'Equipment Id': 'equipmentId',
  'Equipment Number': 'equipmentNo',
  'ERF Date': 'erfDate',
  'ERF Number': 'erfNo',
  'Id': 'id',
  'Location Id':'locationId',
  'Make': 'make',
  'Model': 'model',
  'Owned or Rented': 'ownershipType',
  'Pick Up Date': 'pickupDate',
  'Product Family': 'productFamily',
  'Product Family Id': 'productFamilyId',
  'Project': 'project'
}

export const exportDAIUToExcel = (data: DAIU[], filename: string) => {
  const headings = [Object.keys(daiuMap)];
  const columnHeaders = [...Object.values(daiuMap)];

  const workbook: XLSX.WorkBook = XLSX.utils.book_new();
  const worksheet: XLSX.WorkSheet = XLSX.utils.json_to_sheet([]);

  XLSX.utils.sheet_add_aoa(worksheet, headings);
  XLSX.utils.sheet_add_json(worksheet, data, {
    origin: 'A2',
    skipHeader: true,
    header: columnHeaders,
  });

  const currentDate = format(Date.now(), 'MM_dd_yyyy');
  const exportedFilename = `${filename}_${currentDate}`;

  XLSX.utils.book_append_sheet(workbook, worksheet, filename);

  const wscols: XLSX.WorkSheet['!cols'] = headings[0].map((h, i) => ({
    wch: 20,
  }));

  worksheet['!cols'] = wscols;

  XLSX.writeFile(workbook, `${exportedFilename}.xlsx`);
};

export const exportEURToExcel = (data: IEURColumns[], filename: string) => {
  const headings = [Object.keys(EURMap)];
  const columnHeaders = [...Object.values(EURMap)];

  const workbook: XLSX.WorkBook = XLSX.utils.book_new();
  const worksheet: XLSX.WorkSheet = XLSX.utils.json_to_sheet([]);

  XLSX.utils.sheet_add_aoa(worksheet, headings);
  XLSX.utils.sheet_add_json(worksheet, data, {
    origin: 'A2',
    skipHeader: true,
    header: columnHeaders,
  });

  const currentDate = format(Date.now(), 'MM_dd_yyyy');
  const exportedFilename = `${filename}_${currentDate}`;

  XLSX.utils.book_append_sheet(workbook, worksheet, filename);

  const wscols: XLSX.WorkSheet['!cols'] = headings[0].map((h, i) => ({
    wch: 20,
  }));

  worksheet['!cols'] = wscols;

  XLSX.writeFile(workbook, `${exportedFilename}.xlsx`);
};


export const exportHistoricalRentalsToExcel = (data: HistoricalRentalsTracker[], filename: string) => {
  const headings = [Object.keys(historicalRentalsMap)];
  const columnHeaders = [...Object.values(historicalRentalsMap)];

  const workbook: XLSX.WorkBook = XLSX.utils.book_new();
  const worksheet: XLSX.WorkSheet = XLSX.utils.json_to_sheet([]);

  XLSX.utils.sheet_add_aoa(worksheet, headings);
  XLSX.utils.sheet_add_json(worksheet, data, {
    origin: 'A2',
    skipHeader: true,
    header: columnHeaders,
  });

  const currentDate = format(Date.now(), 'MM_dd_yyyy');
  const exportedFilename = `${filename}_${currentDate}`;

  XLSX.utils.book_append_sheet(workbook, worksheet, filename);

  const wscols: XLSX.WorkSheet['!cols'] = headings[0].map((h, i) => ({
    wch: 20,
  }));

  worksheet['!cols'] = wscols;

  XLSX.writeFile(workbook, `${exportedFilename}.xlsx`);
};