import { createColumnHelper } from '@tanstack/react-table';
import { IAllocationColumns } from '../utils/types';
import { 
  ModifyActionButton, 
  WithdrawActionButton,
  CreateDeliveryActionButton 
} from '../../../../components/common/primebmd-ui/EditActionButton';
import Stack from '@mui/material/Stack';
import moment from 'moment';

const columnHelper = createColumnHelper<IAllocationColumns>();

const allocationColumns = [
  columnHelper.accessor('allocationNo', {
    id: 'allocationNo',
    header: 'Allocation No.',
    cell: (row) => row.getValue(),
    enableColumnFilter: false,
  }),
  columnHelper.accessor('allocationDate', {
    id: 'allocationDate',
    header: 'Allocation Date',
    cell: (row) => moment(row.getValue()).format('MM/DD/yyyy'),
    enableGlobalFilter: true,
    enableColumnFilter: true,
  }),
  columnHelper.accessor('project', {
    id: 'project',
    header: 'Project',
    cell: (row) => row.renderValue(),
    enableGlobalFilter: true,
    enableColumnFilter: true,
  }),
  columnHelper.accessor('serfNo', {
    id: 'serfNo',
    header: 'SERF No.',
    cell: (row) => row.renderValue(),
    enableGlobalFilter: true,
    enableColumnFilter: true,
  }),
  columnHelper.accessor('productFamily', {
    id: 'productFamily',
    header: 'Product Family',
    cell: (row) => row.renderValue(),
    enableGlobalFilter: true,
    enableColumnFilter: true,
  }),
  columnHelper.accessor('capacity', {
    id: 'capacity',
    header: 'Capacity',
    cell: (row) => row.renderValue(),
    enableGlobalFilter: true,
    enableColumnFilter: true,
  }),
  columnHelper.accessor('equipmentNo', {
    id: 'equipmentNo',
    header: 'Equipment No.',
    cell: (row) => row.renderValue(),
    enableGlobalFilter: true,
    enableColumnFilter: true,
  }),
  columnHelper.accessor('startDate', {
    id: 'startDate',
    header: 'Start Date',
    cell:  (row) => moment(row.getValue()).format('MM/DD/yyyy'),
    enableGlobalFilter: true,
    enableColumnFilter: true,
  }),
  columnHelper.accessor('endDate', {
    id: 'endDate',
    header: 'Requested End Date',
    cell: (row) => moment(row.getValue()).format('MM/DD/yyyy'),
    enableGlobalFilter: true,
    enableColumnFilter: true,
  }),
  columnHelper.display({
    id: 'actions',
    header: 'Action',
    cell: ({ row }) => (
      <Stack direction="column" gap={.75} alignItems="center">
        <ModifyActionButton />
        <WithdrawActionButton route={`/my-equipment-requests/my-allocations/withdraw-allocation/${row.original.id}`} />
        <CreateDeliveryActionButton route={`/my-equipment-requests/my-allocations/create-delivery/${row.original.id}`} />
      </Stack>
    ),
    enableHiding: false,
  }),
];

export default allocationColumns;