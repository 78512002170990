import { HistoricalRentalsTracker } from '../../modules/Reports/EquipmentUseAndPerformanceReports/utils/types';
import { apiClient, apiEndpoints } from '../apiClient';

export type GetManyReturnType = {
  status: string;
  data: HistoricalRentalsTracker[];
};

const getHistoricalRentals = async () => {
  const response = await apiClient.get<GetManyReturnType>(`${apiEndpoints.historicalRentals}`);

  return response.data.data;
};

const historicalRentalsApi = {
    getHistoricalRentals
};

export default historicalRentalsApi;