import moment from 'moment';
import { createColumnHelper } from '@tanstack/react-table';
import { IEURColumns } from '../utils/types';

const columnHelper = createColumnHelper<IEURColumns>();

const EURReportsColumns = [
  columnHelper.accessor('date', {
    id: 'date',
    header: 'Date',
    cell: (row) => moment(row.getValue()).format('MM/DD/yyyy'),
    enableGlobalFilter: true,
    enableColumnFilter: false,
    enableHiding: true
  }),
  columnHelper.accessor('eurNo', {
    id: 'eurNo',
    header: 'EUR No.',
    cell: (row) => row.getValue(),
    enableGlobalFilter: true,
    enableColumnFilter: true,
    enableHiding: true
  }),
  columnHelper.accessor('equipmentNo', {
    id: 'equipmentNo',
    header: 'Equipment No.',
    cell: (row) => row.getValue(),
    enableGlobalFilter: true,
    enableColumnFilter: false,
    enableHiding: true
  }),
  columnHelper.accessor('operator', {
    id: 'operator',
    header: 'Operator',
    cell: (row) => row.getValue(),
    enableGlobalFilter: true,
    enableColumnFilter: false,
    enableHiding: true
  }),
  columnHelper.accessor('location', {
    id: 'location',
    header: 'Location',
    cell: (row) => row.getValue(),
    enableGlobalFilter: true,
    enableColumnFilter: false,
    enableHiding: true
  }),
  columnHelper.accessor('startingReading', {
    id: 'startingReading',
    header: 'Starting SMR',
    cell: (row) => row.getValue(),
    enableGlobalFilter: true,
    enableColumnFilter: false,
    enableHiding: true
  }),
  columnHelper.accessor('endingReading', {
    id: 'endingReading',
    header: 'Ending SMR',
    cell: (row) => row.getValue(),
    enableGlobalFilter: true,
    enableColumnFilter: false,
    enableHiding: true
  })
];

export default EURReportsColumns;