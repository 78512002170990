import { Outlet } from 'react-router-dom';
import OperatorLicenseTable from './OperatorLicenseTable';

const OperatorLicenseList = () => {
  return (
    <>
      <OperatorLicenseTable />
      <Outlet />
    </>
  );
};

export default OperatorLicenseList;