import { Outlet } from 'react-router-dom';
import PageContainer from '../../../components/common/PageContainer';
import OnRentTable from './Table';

const OnRentPage = () => {

  return (
    <>
      <PageContainer
        title='My Dashboard + Tasks' 
        subTitle='My On Rent Equipment' 
        showDate
      >
        <OnRentTable />
      </PageContainer>
      <Outlet />
    </>
  );
};

export default OnRentPage;