import { createColumnHelper } from '@tanstack/react-table';
import { IEXFColumns } from '../utils/types';
import { 
  ApproveActionButton, 
  ApproveWtChangeActionButton, 
  RejectActionButton 
} from '../../../../components/common/primebmd-ui/EditActionButton';
import { appColors } from '../../../../theme';
import Stack from '@mui/material/Stack';
import Chip from '@mui/material/Chip';
import moment from 'moment';

const columnHelper = createColumnHelper<IEXFColumns>();

export const exfPMColumns = [
  columnHelper.accessor('exfNo', {
    id: 'exfNo',
    header: 'EXF No.',
    cell: (row ) => row.renderValue(),
    enableGlobalFilter: true,
    enableColumnFilter: true,
  }),
  columnHelper.accessor('exfDate', {
    id: 'exfDate',
    header: 'EXF Creation Date',
    cell: (row) => moment(row.getValue()).format('MM/DD/yyyy'),
    enableGlobalFilter: true,
    enableColumnFilter: true,
  }),
  columnHelper.accessor('project', {
    id: 'project',
    header: 'Project',
    cell: (row) => row.renderValue(),
    enableGlobalFilter: true,
    enableColumnFilter: true,
  }),
  columnHelper.accessor('equipmentNo', {
    id: 'equipmentNo',
    header: 'Equipment No.',
    cell: (row) => row.renderValue(),
    enableGlobalFilter: true,
    enableColumnFilter: true,
  }),
  columnHelper.accessor('emEndDate', {
    id: 'emEndDate',
    header: 'EM End Date',
    cell: (row) => moment(row.getValue()).format('MM/DD/yyyy'),
    enableGlobalFilter: true,
    enableColumnFilter: true,
  }),
  columnHelper.accessor('exfRequestedEndDate', {
    id: 'requestedEndDate',
    header: 'EXF Requested End Date',
    cell: (row) => moment(row.getValue()).format('MM/DD/yyyy'),
    enableGlobalFilter: true,
    enableColumnFilter: true,
  }),
  columnHelper.accessor('exfStatus', {
    id: 'exfStatus',
    header: 'EXF Status',
    cell: ({ row }) => (
      <Chip 
        label={row.original.exfStatus ? row.original.exfStatus : 'Requested'} 
        sx={
          row.original.exfStatus === 'Approved' 
          ? { background: appColors.successDark, color: "#fff" }
          :  row.original.exfStatus === 'Approved with Change' 
             ? { background: appColors.warningDark, color: "#fff" }
             : row.original.exfStatus === 'Rejected' 
              ? { background: appColors.errorDark, color: "#fff" }
              : { background: appColors.primary, color: "#fff" }
        }
      />
    ),
    enableGlobalFilter: true,
    enableColumnFilter: true,
  }),
  columnHelper.accessor('exfEMEndDate', {
    id: 'exfEMEndDate',
    header: 'EM EXF End Date',
    cell: ({ row }) => (row.original.exfEMEndDate ? moment(row.original.exfEMEndDate).format('MM/DD/yyyy') : '-'),
    enableGlobalFilter: true,
    enableColumnFilter: true,
  }),
  columnHelper.accessor('exfEMRemarks', {
    id: 'remarks',
    header: 'EM Remarks on EXF Status',
    cell: ({ row }) => (row.original.exfEMRemarks ? row.original.exfEMRemarks : '-'),
    enableGlobalFilter: true,
    enableColumnFilter: true,
  }),
];

export const exfEMColumns = [
  columnHelper.accessor('exfNo', {
    id: 'exfNo',
    header: 'EXF No.',
    cell: (row ) => row.renderValue(),
    enableGlobalFilter: true,
    enableColumnFilter: true,
  }),
  columnHelper.accessor('exfDate', {
    id: 'exfDate',
    header: 'EXF Creation Date',
    cell: (row) => moment(row.getValue()).format('MM/DD/yyyy'),
    enableGlobalFilter: true,
    enableColumnFilter: true,
  }),
  columnHelper.accessor('project', {
    id: 'project',
    header: 'Project',
    cell: (row) => row.renderValue(),
    enableGlobalFilter: true,
    enableColumnFilter: true,
  }),
  columnHelper.accessor('equipmentNo', {
    id: 'equipmentNo',
    header: 'Equipment No.',
    cell: (row) => row.renderValue(),
    enableGlobalFilter: true,
    enableColumnFilter: true,
  }),
  columnHelper.accessor('emEndDate', {
    id: 'emEndDate',
    header: 'EM End Date',
    cell: (row) => moment(row.getValue()).format('MM/DD/yyyy'),
    enableGlobalFilter: true,
    enableColumnFilter: true,
  }),
  columnHelper.accessor('exfRequestedEndDate', {
    id: 'requestedEndDate',
    header: 'EXF Requested End Date',
    cell: (row) => moment(row.getValue()).format('MM/DD/yyyy'),
    enableGlobalFilter: true,
    enableColumnFilter: true,
  }),
  columnHelper.accessor('exfStatus', {
    id: 'exfStatus',
    header: 'EXF Status',
    cell: ({ row }) => (
      <Chip 
        label={row.original.exfStatus ? row.original.exfStatus : 'Requested'} 
        sx={
          row.original.exfStatus === 'Approved' 
          ? { background: appColors.successDark, color: "#fff" }
          :  row.original.exfStatus === 'Approved with Change' 
             ? { background: appColors.warningDark, color: "#fff" }
             : row.original.exfStatus === 'Rejected' 
              ? { background: appColors.errorDark, color: "#fff" }
              : { background: appColors.primary, color: "#fff" }
        }
      />
    ),
    enableGlobalFilter: true,
    enableColumnFilter: true,
  }),
  columnHelper.accessor('exfEMEndDate', {
    id: 'exfEMEndDate',
    header: 'EM EXF End Date',
    cell: ({ row }) => (row.original.exfEMEndDate ? moment(row.original.exfEMEndDate).format('MM/DD/yyyy') : '-'),
    enableGlobalFilter: true,
    enableColumnFilter: true,
  }),
  columnHelper.accessor('exfEMRemarks', {
    id: 'remarks',
    header: 'EM Remarks on EXF Status',
    cell: ({ row }) => (row.original.exfEMRemarks ? row.original.exfEMRemarks : '-'),
    enableGlobalFilter: true,
    enableColumnFilter: true,
  }),
  columnHelper.display({
    id: 'action',
    header: 'Action',
    cell: ({ row }) => (
      <Stack direction="column" gap={.75} alignItems="center">
        <ApproveActionButton disabled={row.original.exfStatus !== undefined} route={`/my-equipment-requests/my-exf/approve/${row.original.id}`} />
        <ApproveWtChangeActionButton disabled={row.original.exfStatus !== undefined} route={`/my-equipment-requests/my-exf/approve-with-change/${row.original.id}`} />
        <RejectActionButton disabled={row.original.exfStatus !== undefined} route={`/my-equipment-requests/my-exf/reject/${row.original.id}`} />
      </Stack>
    ),
    enableHiding: false,
  }),
];