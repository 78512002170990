import { Stack } from '@mui/material';
import { Outlet } from 'react-router-dom';
import backgroundImage from '../../assets/images/Image1.jpg';
const AuthPage = () => {
  return (
    <Stack
      sx={{
        zIndex: -1,
        backgroundImage: `url(${backgroundImage})`,
        backgroundSize: '100%',
        backgroundRepeat: `no-repeat`,
      }}
      height='100vh'
      width='100vw'
      direction='column'
      justifyContent='center'
      alignItems='center'
      boxShadow={3}
      spacing={2}
    >
      <Outlet />
    </Stack>
  );
};

export default AuthPage;
