import { 
  IOperatorLicenses, 
  IOperatorLicenseForm, 
  IEmailForm,
  EmailAttachment 
} from '../../modules/MyDashboardsPlusTasks/OperatorLicenses/OperatorLicensesUtils';
import { apiClient, apiEndpoints } from '../apiClient';
import { uploadMedia, uploadMultipleMedia } from '../../utils/upload';

export type GetManyReturnType = {
	status: string;
	data: IOperatorLicenses[];
};

export type GetOneReturnType = {
	status: string;
	data: IOperatorLicenses;
};

const getOperatorLicenses = async () => {
  const response = await apiClient.get<GetManyReturnType>(`${apiEndpoints.operatorLicenses}`);
  return response.data.data;
};

const getOperatorLicensesById = async (id: string) => {
  const response = await apiClient.get<GetOneReturnType>(
    `${apiEndpoints.operatorLicenses}/${id}`
  );
  return response.data.data;
};

const createOperatorLicenses = async (newOperatorLicensesData: Partial<IOperatorLicenseForm>) => {
  let mediaUrl = "";

  if (newOperatorLicensesData 
    && newOperatorLicensesData.media 
    && newOperatorLicensesData.media.length > 0) {
    const uploadRes = await uploadMedia(newOperatorLicensesData.media[0].source, "operator-license");
    mediaUrl = uploadRes.path;
  }

  const newData = {
    ...newOperatorLicensesData,
    media: mediaUrl
  }

  const response = await apiClient.post<GetOneReturnType>(
    apiEndpoints.operatorLicenses,
    newData
  );

  return response.data.data;
};

const updateOperatorLicenses = async (id: string, updateOperatorLicensesData: Partial<IOperatorLicenseForm>) => {
  let mediaUrl = "";

  if (updateOperatorLicensesData 
      && updateOperatorLicensesData.media 
      && updateOperatorLicensesData.media.length > 0
    ) {
    const uploadRes = await uploadMedia(updateOperatorLicensesData.media[0].source, "operator-license");
    mediaUrl = uploadRes.path;
  } else {
    const response = await apiClient.get<GetOneReturnType>(
      `${apiEndpoints.operatorLicenses}/${id}`
    );

    mediaUrl = response.data.data.media;
  }

  const newData = {
    ...updateOperatorLicensesData,
    media: mediaUrl
  }

  const response = await apiClient.patch<GetOneReturnType>(
    `${apiEndpoints.operatorLicenses}/${id}`,
    newData
  );

  return response.data.data;
};

const sendEmail = async (emailData: IEmailForm) => {
  let mediaUrl: EmailAttachment[] = [];
  const mediaFile = emailData.media.map(e => e.source);

  if (emailData && emailData.media.length > 0 ) {
    const uploadRes = await uploadMultipleMedia(mediaFile, "operator-license-email");
    
    mediaUrl = uploadRes.map(u => ({
      "filename": u.filename,
      "path": u.path
    }));
  }

  const newData = {
    to: emailData.toemail,
    cc: emailData.ccemail,
    subject: emailData.subject,
    message: emailData.message,
    attachments: mediaUrl
  }

  const response = await apiClient.post(
    `${apiEndpoints.operatorLicenses}/send-email`,
    newData
  );

  return response.data.data;
}

const operatorLicensesApi = {
  getOperatorLicenses,
  getOperatorLicensesById,
  createOperatorLicenses,
  updateOperatorLicenses,
  sendEmail
};

export default operatorLicensesApi;