import { Outlet } from 'react-router-dom';
import PageContainer from '../../../../components/common/PageContainer';
import DAIUTable from './Table';

const DAUIPage = () => {
  return (
    <>
      <PageContainer 
        title='Reports' 
        subTitle='Equipment Use & Performance Reports'
        submoduleTitle='Down, Availability Utilization, Idle (DAIU) Report'  
        showDate
      >
        <DAIUTable />
      </PageContainer>
      <Outlet />
    </>
  );
}

export default DAUIPage;