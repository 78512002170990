import { forwardRef } from 'react';
import { styled } from '@mui/material/styles';
import Stack from '@mui/material/Stack';
import AttachFileIcon from '@mui/icons-material/AttachFile';
import { Typography } from '@mui/material';
import { appColors } from '../../../theme';
interface MultipleUploadButtonProps
  extends React.DetailedHTMLProps<
    React.InputHTMLAttributes<HTMLInputElement>,
    HTMLInputElement
  > {
  label?: string;
  fileName?: string;
  labelPosition?: "top" | "left";
  labelAlignment?: "center" | "inherit" | "right" | "left" | "justify";
  labelWidth?: string;
  fieldWidth?: string;
  fontWeight?: number;
  mt?: string;
}

const UploadButton = styled('span')(({ theme }) => ({
  alignSelf: 'flex-end',
  color: theme.palette.primary.main,
  fontSize: 12,
  fontWeight: 500,
  cursor: 'pointer',
  transition: 'all 0.3s',

  '&.disabled': {
    pointerEvents: 'none',
    color: '#ccc',

    '& > div': {
      backgroundColor: '#ccc',
    },
  },

  '&:hover': {
    filter: `brightness(85%)`,
  },

  '& > div': {
    width: 38,
    height: 38,
    backgroundColor: theme.palette.primary.main,
    color: 'white',
    borderRadius: '50%',
    alignItems: 'center',
    justifyContent: 'center',
  },
}));

const AppPrimeUploadForm = forwardRef<
  HTMLInputElement,
  MultipleUploadButtonProps
>(({ 
    label, 
    labelPosition,
    labelAlignment, 
    labelWidth, 
    fieldWidth,
    fontWeight,
    mt,
    name, 
    fileName, 
    ...rest 
  }, ref) => {
  return (
    <Stack 
      direction={labelPosition && labelPosition === 'top' ? 'column' : 'row'}
      justifyContent='space-between'
    >
      <Typography
        color={appColors.formText}
        component='label'
        fontSize={labelPosition && labelPosition === 'top' ? 13 : 15}
        fontWeight={fontWeight ? fontWeight : 600}
        mt={mt ? mt : 1.5}
        width={labelWidth ? labelWidth : 130}
        align={labelAlignment ? labelAlignment : 'right'}
        whiteSpace='nowrap'
      >
        {label}
      </Typography>
      <Stack
        width={fieldWidth ? fieldWidth : 400}
        border={`1px ${appColors.primary} solid`}
        borderRadius={1}
        direction='row'
      >
        <Stack
          direction='row'
          justifyContent='space-between'
          width={fieldWidth ? fieldWidth : 400}
          padding={1}
          paddingBottom={0}
        >
          <Typography fontSize={13}>
            {fileName ? fileName : 'File Name'}
          </Typography>
          <label htmlFor={name} style={{ width: 'max-content' }}>
            <input
              ref={ref}
              type='file'
              accept='.doc, .docx, .pdf'
              hidden
              name={name}
              id={name}
              {...rest}
            />
            <UploadButton className={rest.disabled ? 'disabled' : ''}>
              <AttachFileIcon sx={{ fontSize: '20px' }} />
            </UploadButton>
          </label>
        </Stack>
      </Stack>
    </Stack>
  );
});

export default AppPrimeUploadForm;
