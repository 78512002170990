import { useMutation, useQuery } from '@tanstack/react-query';
import { AxiosError } from 'axios';
import { useNavigate, useParams } from 'react-router';
import { useSnackBar } from '../components/ToastProvider';
import { IAccounts } from '../modules/Users/Accounts/accounts-utils';
import userApi from '../services/user';
import { queryClient } from '../react-query/queryClient';

interface ApiError {
  status: number;
  message: string;
}

interface useGetUserWithRole {
  role?: string;
}

export function useGetUserQuery(options?: useGetUserWithRole) {
  return useQuery<IAccounts[]>(['users'], () => userApi.getUser(), {
    onError(err) {
      console.log(err);
    },
    select(users) {
      return options?.role ? users.filter(u => u.roleName === options?.role) : users;
    }
  });
}

export function useGetUserById() {
  const { id } = useParams<{ id: string }>();
  return useQuery([`user-${id}`, id], () => userApi.getUserById(id!));
}

export function useCreateUser() {
  const { showSnackBar } = useSnackBar();
  const navigate = useNavigate();
  return useMutation((newUserData: Partial<IAccounts>) => userApi.createUser(newUserData), {
    onSuccess() {
      queryClient.invalidateQueries({ queryKey: ['users'] }).then(() => {
        showSnackBar('Successfully Created User', 'success');
        navigate('/users', { replace: true });
      });
    },
    onError(err) {
      const errorData = (err as AxiosError<{ error: ApiError }>).response?.data.error;

      if (err && errorData) {
        showSnackBar(errorData.message.split(':').slice(1).join(' '), 'error');
      }
    },
  });
}

export function useUpdateUser() {
  const { showSnackBar } = useSnackBar();
  const { id } = useParams<{ id: string }>();
  const navigate = useNavigate();

  return useMutation((newUserData: Partial<IAccounts>) => userApi.updateUser(id!, newUserData), {
    onSuccess() {
      queryClient.invalidateQueries({ queryKey: ['users'] }).then(() => {
        showSnackBar('Successfully Updated User', 'success');
        navigate('/users', { replace: true });
      });
    },
    onError(err) {
      const errorData = (err as AxiosError<{ error: ApiError }>).response?.data.error;

      if (err && errorData) {
        showSnackBar(errorData.message.split(':').slice(1).join(' '), 'error');
      }
    },
  });
}

export function useDeleteUser() {
  const { showSnackBar } = useSnackBar();
  const navigate = useNavigate();

  return useMutation((id: string) => userApi.deleteUser(id), {
    onSuccess() {
      queryClient.invalidateQueries({ queryKey: ['users'] }).then(() => {
        showSnackBar('Successfully Deleted User', 'success');
        navigate('/users', { replace: true });
      });
    },
    onError(err) {
      const errorData = (err as AxiosError<{ error: ApiError }>).response?.data.error;

      if (err && errorData) {
        showSnackBar(errorData.message.split(':').slice(1).join(' '), 'error');
      }
    },
  });
}
