import React from 'react';
import { Column } from '@tanstack/react-table';

import { Button, Menu, Typography, MenuItem, FormControlLabel, Checkbox } from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { toTitleCase } from '../../../utils/helpers';

interface ColumnFilterMenuProps<T> {
  columns: Column<T, unknown>[];
}

function ColumFilterMenu<T extends object>({ columns }: ColumnFilterMenuProps<T>) {
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);

  const open = Boolean(anchorEl);

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <div>
      <Button
        variant='outlined'
        id='column-filter-button'
        aria-controls={open ? 'column-filter-menu' : undefined}
        aria-haspopup='true'
        aria-expanded={open ? 'true' : undefined}
        onClick={handleClick}
        sx={{ textTransform: 'capitalize' }}
        endIcon={<ExpandMoreIcon />}
      >
        Column Filters
      </Button>
      <Menu
        id='column-filter-menu'
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        MenuListProps={{
          'aria-labelledby': 'column-filter-button',
        }}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'right',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
      >
        <Typography textTransform='uppercase' fontSize={12} px={2} mb={1}>
          Check the columns you want to show
        </Typography>
        {columns
          .filter((c) => c.id !== 'select')
          .map((column) => (
            <MenuItem key={column.id} sx={{ py: '2px', pl: 1 }}>
              <FormControlLabel
                disableTypography
                sx={{ textTransform: 'capitalize', fontSize: 13, width: '100%', margin: 0 }}
                control={
                  <Checkbox
                    size='small'
                    checked={column.getIsVisible()}
                    onChange={column.getToggleVisibilityHandler()}
                  />
                }
                label={toTitleCase(column.id)}
              />
            </MenuItem>
          ))}
      </Menu>
    </div>
  );
}

export default ColumFilterMenu;
