import Footer from "../../components/layout-ui/Footer";
import Typography from "@mui/material/Typography";
import Stack from "@mui/material/Stack";

const AppFooter = () => {
  return (
    <Footer>
      <Stack
        width='100%'
        maxHeight={36}
        direction='row'
        alignItems='center'
        justifyContent='space-between'
        paddingY={2}
        paddingX={4}
        spacing={1}
        mt='auto'
        bgcolor='white'
      >
        <Typography fontSize={13}>Repnotes&copy;2022</Typography>
        <Typography fontSize={13}>Version 1.0.0</Typography>
      </Stack>
    </Footer>
  );
};

export default AppFooter;
