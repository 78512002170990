import { IEJO } from '../../modules/MyDashboardsPlusTasks/EJO-ESR/utils/types';
import { apiClient, apiEndpoints } from '../apiClient';

export type GetManyReturnType = {
  status: string;
  data: IEJO[];
};

const getAllEJO = async (status?: string) => {
  const url = status ? `${apiEndpoints.ejo}?status=${status}` : `${apiEndpoints.ejo}`;
  const response = await apiClient.get<GetManyReturnType>(url);
  return response.data.data;
};

const ejoApi = {
  getAllEJO
};

export default ejoApi;