import { useNavigate } from 'react-router-dom';
import { createColumnHelper } from '@tanstack/react-table';
import { IERFColumns } from '../utils/types';
import { AllocateActionButton, CreatePRActionButton } from '../../../../components/common/primebmd-ui/EditActionButton';
import Switch from '@mui/material/Switch';
import Stack from '@mui/material/Stack';
import moment from 'moment';

export const useCreatePMColumns = () => {
  const navigate = useNavigate();

  const columnHelper = createColumnHelper<IERFColumns>();
  const label = { inputProps: { 'aria-label': 'Arrive on site' } };

  const PMEPFERFColumns = [
    columnHelper.accessor('project', {
      id: 'project',
      header: 'Project',
      cell: (row) => row.getValue(),
      enableGlobalFilter: true,
      enableColumnFilter: true,
    }),
    columnHelper.accessor('erfNo', {
      id: 'erfNo',
      header: 'ERF No.',
      cell: (row) => row.getValue(),
      enableGlobalFilter: true,
      enableColumnFilter: false,
    }),
    columnHelper.accessor('erfDate', {
      id: 'erfDate',
      header: 'ERF Date',
      cell: (row) => moment(row.getValue()).format('MM/DD/yyyy'),
      enableGlobalFilter: true,
      enableColumnFilter: true,
    }),
    columnHelper.accessor('serfNo', {
      id: 'serfNo',
      header: 'SERF No.',
      cell: (row) => row.getValue(),
      enableGlobalFilter: true,
      enableColumnFilter: true,
    }),
    columnHelper.accessor('productFamily', {
      id: 'productFamily',
      header: 'Product Family',
      cell: (row) => row.getValue(),
      enableGlobalFilter: true,
      enableColumnFilter: true,
    }),
    columnHelper.accessor('capacity', {
      id: 'capacity',
      header: 'Capacity',
      cell: (row) => row.getValue(),
      enableGlobalFilter: true,
      enableColumnFilter: true,
    }),
    columnHelper.accessor('startDate', {
      id: 'startDate',
      header: 'Start Date',
      cell: (row) => moment(row.getValue()).format('MM/DD/yyyy'),
      enableGlobalFilter: true,
      enableColumnFilter: true,
    }),
    columnHelper.accessor('endDate', {
      id: 'endDate',
      header: 'End Date',
      cell: (row) => moment(row.getValue()).format('MM/DD/yyyy'),
      enableGlobalFilter: true,
      enableColumnFilter: true,
    }),
    columnHelper.accessor('emEndDate', {
      id: 'emEndDate',
      header: 'EM End Date',
      cell: (row) => row.getValue() ? moment(row.getValue()).format('MM/DD/yyyy') : '-',
      enableGlobalFilter: true,
      enableColumnFilter: true,
    }),
    columnHelper.accessor('allocationNo', {
      id: 'allocationNo',
      header: 'Allocation No',
      cell: (row) => row.getValue(),
      enableGlobalFilter: true,
      enableColumnFilter: true,
    }),
    columnHelper.accessor('equipmentNo', {
      id: 'equipmentNo',
      header: 'Equipment No.',
      cell: ({ row }) => row.original.status === 'Requested' ? '-' : row.original.equipmentNo,
      enableGlobalFilter: true,
      enableColumnFilter: true,
    }),
    columnHelper.accessor('allocationDeliveryNo', {
      id: 'allocationDeliveryNo',
      header: 'Allocation Delivery No.',
      cell: (row) => row.getValue(),
      enableGlobalFilter: true,
      enableColumnFilter: true,
    }),
    columnHelper.accessor('allocationTruckNo', {
      id: 'allocationTruckNo',
      header: 'Allocation Truck No.',
      cell: (row) => row.getValue() ? row.getValue() : '-',
      enableGlobalFilter: true,
      enableColumnFilter: true,
    }),
    columnHelper.accessor('allocationETA', {
      id: 'allocationETA',
      header: 'Allocation ETA at Drop Off',
      cell: (row) => row.getValue() ? moment(row.getValue()).format('MM/DD/yyyy') : '-',
      enableGlobalFilter: true,
      enableColumnFilter: true,
    }),
    columnHelper.display({
      id: 'arrivedOnSite',
      header: 'Arrived on Site',
      cell: ({ row }) => (
        <>
          <Switch 
            {...label} 
            checked={row.original.arrivedOnSite && row.original.arrivedOnSite === true}
            onChange={() => navigate(`/my-equipment-requests/my-erf/arrived-on-site/${row.original.id}`)}
            disabled={row.original.status !== "In Transit"}  
          />
        </>
      ),
      enableHiding: false,
    })
  ];

  return PMEPFERFColumns;
};

export const useCreateEMColumns = () => {
  const columnHelper = createColumnHelper<IERFColumns>();

  const EMEPFERFColumns = [
    columnHelper.accessor('project', {
      id: 'project',
      header: 'Project',
      cell: (row) => row.getValue(),
      enableGlobalFilter: true,
      enableColumnFilter: true,
    }),
    columnHelper.accessor('erfNo', {
      id: 'erfNo',
      header: 'ERF No.',
      cell: (row) => row.getValue(),
      enableGlobalFilter: true,
      enableColumnFilter: false,
    }),
    columnHelper.accessor('erfDate', {
      id: 'erfDate',
      header: 'ERF Date',
      cell: (row) => moment(row.getValue()).format('MM/DD/yyyy'),
      enableGlobalFilter: true,
      enableColumnFilter: true,
    }),
    columnHelper.accessor('serfNo', {
      id: 'serfNo',
      header: 'SERF No.',
      cell: (row) => row.getValue(),
      enableGlobalFilter: true,
      enableColumnFilter: true,
    }),
    columnHelper.accessor('productFamily', {
      id: 'productFamily',
      header: 'Product Family',
      cell: (row) => row.getValue(),
      enableGlobalFilter: true,
      enableColumnFilter: true,
    }),
    columnHelper.accessor('capacity', {
      id: 'capacity',
      header: 'Capacity',
      cell: (row) => row.getValue(),
      enableGlobalFilter: true,
      enableColumnFilter: true,
    }),
    columnHelper.accessor('startDate', {
      id: 'startDate',
      header: 'Start Date',
      cell: (row) => moment(row.getValue()).format('MM/DD/yyyy'),
      enableGlobalFilter: true,
      enableColumnFilter: true,
    }),
    columnHelper.accessor('endDate', {
      id: 'endDate',
      header: 'End Date',
      cell: (row) => moment(row.getValue()).format('MM/DD/yyyy'),
      enableGlobalFilter: true,
      enableColumnFilter: true,
    }),
    columnHelper.display({
      id: 'actions',
      header: 'Action',
      cell: ({ row }) => (
        <Stack direction="row" gap={1} alignItems="center">
          <AllocateActionButton route={`/my-equipment-requests/my-erf/allocate/${row.original.id}`} />
          <CreatePRActionButton route={`/my-equipment-requests/my-erf/create-pr/${row.original.id}`} />
        </Stack>
      ),
      enableHiding: false,
    })
  ];

  return EMEPFERFColumns;
};