import { Navigate, Outlet } from 'react-router-dom';
import { usePermission } from '../../../../hooks/usePermissions';
import PageContainer from '../../../../components/common/PageContainer';
import EPFERFTable from '../MyEPFsAndERFs/Table';

const EPFAndERFPage = () => {
  // const permission = usePermission('Equipment Plan Form & Equipment Request Form');
  // if (permission !== undefined && !permission) return <Navigate to='/home' />;

  return (
    <>
      <PageContainer 
        title='My Dashboard + Tasks' 
        subTitle='My Equipment Requests'
        submoduleTitle='My ERFs'  
        showDate
      >
        <EPFERFTable />
      </PageContainer>
      <Outlet />
    </>
  );
}

export default EPFAndERFPage;