import { createColumnHelper } from '@tanstack/react-table';
import EmailActionButton from '../../../components/common/EmailActionButton';
import { LogActionButton } from '../../../components/common/primebmd-ui/EditActionButton';
import { IOperatorLicenses } from './OperatorLicensesUtils';
import OperatorLicenseStatus from './OperatorLicenseStatus';

const columnHelper = createColumnHelper<IOperatorLicenses>();

const operatorLicenseColumns = [
  columnHelper.accessor('operatorName', {
    id: 'operatorName',
    header: 'Operator Name',
    cell: (row) => row.getValue(),
    enableGlobalFilter: true,
    enableColumnFilter: false,
    enableHiding: true
  }),
  columnHelper.accessor('operatorType', {
    id: 'operatorType',
    header: 'Operator Type',
    cell: (row) => row.getValue(),
    enableGlobalFilter: true,
    enableColumnFilter: false,
    enableHiding: true
  }),
  columnHelper.accessor('licenseType', {
    id: 'licenseType',
    header: 'License Type',
    cell: (row) => row.getValue(),
    enableGlobalFilter: true,
    enableColumnFilter: false,
    enableHiding: true
  }),
  columnHelper.accessor('validityDate', {
    id: 'daysToExpiration',
    header: 'Days To Expiration',
    cell: (row) => row.getValue() ? <OperatorLicenseStatus type={new Date(row.getValue())} /> : '',
    enableGlobalFilter: true,
    enableColumnFilter: true,
    enableHiding: false,
  }),
  columnHelper.display({
    id: 'actions',
    header: 'Action',
    cell: ({ row }) => {
      const url = row.original.id ? `/operator-licenses/edit/${row.original.id}` : `/operator-licenses/new/${row.original.operatorId}`;
      const emailUrl = row.original.id ? `/operator-licenses/email/${row.original.operatorId}` : `/operator-licenses/email/${row.original.operatorId}`

      return (
        <>
          <LogActionButton route={url} />
          <EmailActionButton editRoute={emailUrl} />
        </>
      )
    },
    enableHiding: false,
  }),
];

export default operatorLicenseColumns;