import IconButton, { IconButtonProps } from '@mui/material/IconButton';
import Tooltip from '@mui/material/Tooltip';
import FileUploadIcon from '@mui/icons-material/FileUploadOutlined';
import FileDownloadIcon from '@mui/icons-material/FileDownloadOutlined';

export const ExportButton = (props: IconButtonProps) => {
  return (
    <Tooltip title='Export'>
      <IconButton
        aria-label='Export'
        sx={(theme) => ({
          backgroundColor: theme.palette.primary.main,
          color: theme.palette.common.white,
          borderRadius: 1,

          '&:hover': {
            backgroundColor: theme.palette.primary.main,
            opacity: 0.95,
          },
        })}
        {...props}
      >
        <FileUploadIcon fontSize='small' />
      </IconButton>
    </Tooltip>
  );
};

export const ImportButton = (props: IconButtonProps) => {
  return (
    <Tooltip title='Import'>
      <IconButton
        aria-label='Import'
        sx={(theme) => ({
          backgroundColor: theme.palette.primary.main,
          color: theme.palette.common.white,
          borderRadius: 1,

          '&:hover': {
            backgroundColor: theme.palette.primary.main,
            opacity: 0.95,
          },
        })}
        {...props}
      >
        <FileDownloadIcon fontSize='small' />
      </IconButton>
    </Tooltip>
  );
};
