import { useNavigate } from 'react-router-dom';
import { Controller, useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { SelectOptionType } from '../../../components/common/forms/form-types';
import { IEXFForm } from './utils/types';
import { CreateEXFInitialValues, CreateEXFSchema } from './utils/schema';
import { useGetLocationsQuery } from '../../../hooks/useLocations';
import { useGetEPFERFById } from '../../../hooks/useEPFERF';
import { useCreateEXF } from '../../../hooks/useOnRentEquipments';
import Stack from '@mui/material/Stack';
import Button from '@mui/material/Button';
import AppDatePicker from '../../../components/common/forms/AppDatePicker';
import AppSelect from '../../../components/common/forms/AppSelect';
import AppTextField from '../../../components/common/forms/AppTextField';
import AppDialog from '../../../components/common/AppDialog';
import LoadingIndicator from '../../../components/common/LoadingIndicator';
import moment from 'moment';

const CreateEXFForm = () => {
  const {
    register,
    handleSubmit,
    control,
    formState: { errors, isValid },
  } = useForm<IEXFForm>({
    defaultValues: CreateEXFInitialValues,
    mode: 'onChange',
    resolver: yupResolver(CreateEXFSchema),
  });  

  const navigate = useNavigate();
  const createEXF = useCreateEXF();
  const erfToUpdate = useGetEPFERFById();
  const erfToUpdateData = erfToUpdate.data;
  const locationData = useGetLocationsQuery({type: "Project"});
  const locationOptions : SelectOptionType[] = 
  locationData?.data === undefined ? [] : locationData.data.map(l => {
                                          return {
                                            id: l.id,
                                            label: l.name,
                                            value: l.id
                                          }
                                        });
  
  const onSubmit = (formData: IEXFForm) => {
    createEXF.mutate(formData)
  };

  const handleClose = () => navigate('/my-on-rent-equipment', { replace: true });

  if (erfToUpdate.isLoading || createEXF.isLoading) {
    return <LoadingIndicator show />;
  }

  return (
    <AppDialog open onDialogClose={handleClose} title='New EXF' maxWidth='sm' titleAlignment="center">
      <Stack component='form' height='100%' bgcolor='white' py={3} borderRadius={1}>
        <Stack px={3} height='100%' overflow='auto' gap={5}>
          <Stack direction='column' gap={2} width="100%" justifyContent='center' margin='auto'>
            <AppTextField
              label="EXF Creation Date"
              labelPosition="top"
              labelAlignment="left"
              labelWidth='100%'
              fieldWidth= '100%'
              fontWeight={400}
              value={
                erfToUpdateData?.exfDate 
                ? moment(new Date(erfToUpdateData?.exfDate)).format('MM/DD/yyyy') 
                : moment(new Date()).format('MM/DD/yyyy')
              }
              disabled={true}
            />
            <AppTextField
              label="Equipment No."
              labelPosition="top"
              labelAlignment="left"
              labelWidth='100%'
              fieldWidth= '100%'
              fontWeight={400}
              value={erfToUpdateData?.equipmentNo}
              disabled={true}
            />
            <Controller
              control={control}
              name='locationId'
              render={({ field }) => (
                <AppSelect
                  sx={{ width: "100%" }}
                  label="Project"
                  labelPosition="top"
                  labelAlignment="left"
                  labelWidth='100%'
                  fieldWidth= '100%'
                  fontWeight={400}
                  placeholder="Select here"
                  error={!!errors.locationId}
                  helperText={errors.locationId?.message}
                  options={locationOptions}
                  {...register('locationId')}
                  {...field}
                />
              )}
            />
            <Controller
              control={control}
              name='exfRequestedEndDate'
              render={({ field: { value, onChange } }) => (
                <AppDatePicker
                  name='date'
                  value={value}
                  onChange={onChange}
                  label='EXF Requested End Date'
                  labelPosition="top"
                  labelAlignment="left"
                  labelWidth='100%'
                  fieldWidth= '100%'
                  fontWeight={400}
                  error={!!errors.exfRequestedEndDate}
                  helperText={errors.exfRequestedEndDate?.message}
                />
              )}
            />
            <AppTextField
              label="EXF Remarks by PM"
              labelPosition="top"
              labelAlignment="left"
              labelWidth='100%'
              fieldWidth= '100%'
              fontWeight={400}
              multiline
              rows={3}
              error={!!errors.exfPMRemarks}
              helperText={errors.exfPMRemarks?.message}
              {...register('exfPMRemarks')}
            />
            <Stack mt={2}>
              <Button 
                onClick={handleSubmit(onSubmit)}
                disabled={!isValid} 
                sx={{ width: 200, m: '0 auto' }}
              >
                Submit
              </Button>
            </Stack>
          </Stack>
        </Stack>
      </Stack>
    </AppDialog>
  );
}

export default CreateEXFForm;