import PageContainer from '../../../../components/common/PageContainer';

function HECertificateTypePage() {
  return (
    <>
      <PageContainer title='Equipment Databases' subTitle='HE Certificate Type Master' showDate>
        HE Certificate Type Master
      </PageContainer>
    </>
  );
}

export default HECertificateTypePage;
