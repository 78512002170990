import { flexRender } from '@tanstack/react-table';
import { useNavigate } from 'react-router-dom';

import useDefaultTable from '../../../components/common/app-table/useDefaultTable';

import TablePagination from '@mui/material/TablePagination';
import Box from '@mui/material/Box';

import roleColumns from './roleColumns';

import FormAddButton from '../../../components/common/FormAddButton';
import AppSearchField from '../../../components/common/forms/AppSearchField';

import {
  AppTable,
  AppTableBody,
  AppTableCell,
  AppTableContainer,
  AppTableHead,
  AppTableRow,
  AppTableSort,
} from '../../../components/common/app-table/ui';
import { Role } from './roles-utils';
import { Paper, Stack } from '@mui/material';
import LoadingIndicator from '../../../components/common/LoadingIndicator';
import { useSnackBar } from '../../../components/ToastProvider';
import { useGetRolesQuery } from '../../../hooks/useRoles';

const RoleTable = () => {
  const { showSnackBar } = useSnackBar();

  const rolesData = useGetRolesQuery();
  const data: Role[] = rolesData?.data === undefined ? [] : rolesData.data;

  const { table, dataCount, searchFilter, setSearchFilter, pageIndex, pageSize } =
    useDefaultTable<Role>(data, roleColumns);

  const navigate = useNavigate();

  // const loading = status === 'fetching';

  const emptyText = searchFilter ? 'No roles found' : 'No roles yet';

  const goToRoleForm = () => navigate('/roles/new');

  if (rolesData?.isLoading) return <LoadingIndicator show />;

  return (
    <>
      <Stack direction='row' justifyContent='flex-end' mb={2} gap={2}>
        <AppSearchField
          onSearchKeywordChange={setSearchFilter}
          searchKeyword={searchFilter ?? ''}
        />
        <FormAddButton onClick={goToRoleForm} />
      </Stack>
      <Paper elevation={3}>
        <AppTableContainer>
          <AppTable stickyHeader>
            <AppTableHead>
              {table.getHeaderGroups().map((headerGroup) => (
                <AppTableRow key={headerGroup.id}>
                  {headerGroup.headers.map((header) => (
                    <AppTableCell key={header.id} align='center'>
                      <AppTableSort
                        canSort={header.column.getCanSort()}
                        currentSort={header.column.getIsSorted()}
                        onSort={header.column.getToggleSortingHandler()}
                      >
                        {flexRender(header.column.columnDef.header, header.getContext())}
                      </AppTableSort>
                    </AppTableCell>
                  ))}
                </AppTableRow>
              ))}
            </AppTableHead>
            <AppTableBody
              dataCount={dataCount}
              // loading={loading}
              emptyText={emptyText}
            >
              {table.getRowModel().rows.map((row) => (
                <AppTableRow key={row.id}>
                  {row.getVisibleCells().map((cell) => (
                    <AppTableCell key={cell.id} align='center'>
                      <Stack direction='row' gap={2}>
                        {flexRender(cell.column.columnDef.cell, cell.getContext())}
                      </Stack>
                    </AppTableCell>
                  ))}
                </AppTableRow>
              ))}
            </AppTableBody>
          </AppTable>
        </AppTableContainer>
        <Box position='relative'>
          <TablePagination
            sx={{ bgcolor: 'white', borderTop: '1px solid #eee' }}
            component='div'
            count={dataCount}
            page={pageIndex}
            onPageChange={(e, page) => table.setPageIndex(page)}
            rowsPerPage={pageSize}
            onRowsPerPageChange={(e) => table.setPageSize(Number(e.target.value))}
            rowsPerPageOptions={[10, 20, 50, 100]}
          />
        </Box>
      </Paper>
      {rolesData?.isError && showSnackBar('Network Error', 'error')}
    </>
  );
};

export default RoleTable;
