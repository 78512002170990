import { Operator } from '../modules/MyDashboardsPlusTasks/EUR/utils/types';
import { apiClient, apiEndpoints } from './apiClient';

export type GetManyReturnType = {
  status: string;
  data: Operator[];
};

export type GetOneReturnType = {
  status: string;
  data: Operator;
};

const getOperators = async () => {
  const response = await apiClient.get<GetManyReturnType>(apiEndpoints.operator);
  return response.data.data;
};

const getOperatorsById = async (id: string) => {
  const response = await apiClient.get<GetOneReturnType>(
    `${apiEndpoints.operator}/${id}`
  );
  return response.data.data;
};

export default {
  getOperators,
  getOperatorsById
};