import { useNavigate } from 'react-router-dom';
import { useClosePR } from '../../../../hooks/usePR';
import { useGetEPFERFById } from '../../../../hooks/useEPFERF';
import AppDialog from '../../../../components/common/AppDialog';
import LoadingIndicator from '../../../../components/common/LoadingIndicator';
import Stack from '@mui/material/Stack';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';

const ClosePRMessage = () => {
  const navigate = useNavigate();
  const closePR = useClosePR();
  const erf = useGetEPFERFById();
  const selectedERF = erf.data;

  const handleClose = () => navigate('/my-equipment-requests/my-pr', { replace: true });

  if(closePR.isLoading || erf.isLoading) return <LoadingIndicator show />;

  return (
    <AppDialog open onDialogClose={handleClose} title='Message' maxWidth='sm' titleAlignment="center">
      <Stack component='form' height='100%' bgcolor='white' py={3} borderRadius={1}>
        <Stack px={3} height='100%' overflow='auto' gap={5}>
          <Stack direction='column' width="100%" justifyContent='center' margin='auto' gap={3}>
            <Typography textAlign="center" fontSize={14} fontWeight={500}>
              Don't Forget to:
            </Typography>
            <Typography textAlign="center" fontSize={14}>
              1. Update the Equipment Master <br/>
              2. Allocate the newly acquired equipment to <b>"{selectedERF?.serfNo}"</b> <br/>
              3. Create the Allocation Delivery to <b>"{selectedERF?.serfNo}"</b> <br/>
            </Typography>
            <Stack direction="row" justifyContent="center" gap={1}>
              <Button 
                onClick={() => closePR.mutate()}
                sx={{ width:"100px" }}
              >
                Close
              </Button>
              <Button 
                variant="outlined" 
                sx={{ width:"100px" }}
                onClick={() => handleClose()}
              >
                Cancel
              </Button>
            </Stack>
          </Stack>
        </Stack>
      </Stack>
    </AppDialog>
  );
}

export default ClosePRMessage;