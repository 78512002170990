import { flexRender } from '@tanstack/react-table';
import {
  AppTable,
  AppTableBody,
  AppTableCell,
  AppTableContainer,
  AppTableHead,
  AppTableRow,
  AppTableSort,
} from '../../../../components/common/app-table/ui';
import useDefaultTable from '../../../../components/common/app-table/useDefaultTable';
import Stack from '@mui/material/Stack';
import Box from '@mui/material/Box';
import Paper from '@mui/material/Paper';
import TablePagination from '@mui/material/TablePagination';
import LoadingIndicator from '../../../../components/common/LoadingIndicator';
import AppSearchField from '../../../../components/common/forms/AppSearchField';
import { ExportButton } from '../../../../components/common/primebmd-ui/DataButtons';
import ejoColumns from './Columns';
import { useGetEJOQuery, useFormatGetEJOQuery } from '../../../../hooks/useEJO';
import { useSnackBar } from '../../../../components/ToastProvider';
import { exportEJOToExcel } from '../utils/exporting';
import { type IEJOColumns } from '../utils/types';

const EJOPendingTable = () => {
  const { showSnackBar } = useSnackBar();
  const ejoData = useGetEJOQuery('Unapproved');
  const data = ejoData?.data === undefined ? [] : ejoData.data;
  const formattedEJOData: IEJOColumns[] = useFormatGetEJOQuery(data, 'Unapproved');
  
  const { table, dataCount, searchFilter, setSearchFilter, pageIndex, pageSize } = 
  useDefaultTable<IEJOColumns>(formattedEJOData, ejoColumns);

  const emptyText = searchFilter ? 'No data found' : 'No data yet';

  if (ejoData?.isLoading) return <LoadingIndicator show />;

  const handleExport = () => {
    const selectedRows = table.getSelectedRowModel().rows;

    const hasSelectedRows = selectedRows.length > 0;

    const dataToExport = hasSelectedRows ? selectedRows.map((row) => row.original) : formattedEJOData;

    exportEJOToExcel(dataToExport, 'EJO_Pending_Approval');
  }

  return (
    <>
      <Stack direction='row' justifyContent='flex-end' mb={2} gap={2}>
        <AppSearchField
          onSearchKeywordChange={setSearchFilter}
          searchKeyword={searchFilter ?? ''}
        />
        <ExportButton onClick={handleExport} />
      </Stack>
      <Paper elevation={3}>
        <AppTableContainer>
          <AppTable stickyHeader>
            <AppTableHead>
              {table.getHeaderGroups().map((headerGroup) => (
                <AppTableRow key={headerGroup.id}>
                  {headerGroup.headers.map((header) => (
                    <AppTableCell key={header.id} align='center'>
                      <AppTableSort
                        canSort={header.column.getCanSort()}
                        currentSort={header.column.getIsSorted()}
                        onSort={header.column.getToggleSortingHandler()}
                      >
                        {flexRender(header.column.columnDef.header, header.getContext())}
                      </AppTableSort>
                    </AppTableCell>
                  ))}
                </AppTableRow>
              ))}
            </AppTableHead>
            <AppTableBody dataCount={dataCount} loading={ejoData?.isLoading} emptyText={emptyText}>
              {table.getRowModel().rows.map((row) => (
                <AppTableRow key={row.id} sx={{ height: '45px' }}>
                  {row.getVisibleCells().map((cell) => (
                    <AppTableCell key={cell.id} align='center'>
                      {flexRender(cell.column.columnDef.cell, cell.getContext())}
                    </AppTableCell>
                  ))}
                </AppTableRow>
              ))}
            </AppTableBody>
          </AppTable>
        </AppTableContainer>
        <Box position='relative'>
          <TablePagination
            sx={{ bgcolor: 'white', borderTop: '1px solid #eee' }}
            component='div'
            count={dataCount}
            page={pageIndex}
            onPageChange={(e, page) => table.setPageIndex(page)}
            rowsPerPage={pageSize}
            onRowsPerPageChange={(e) => table.setPageSize(Number(e.target.value))}
            rowsPerPageOptions={[10, 20, 50, 100]}
          />
        </Box>
      </Paper>
      {ejoData?.isError && showSnackBar('Network Error', 'error')}
    </>
  );
};

export default EJOPendingTable;