import { useQuery } from '@tanstack/react-query';
import { Location } from '../modules/MyDashboardsPlusTasks/EUR/utils/types';
import locationApi from '../services/locations';

interface useGetLocationOptions {
  type?: string;
}

export function useGetLocationsQuery(options?: useGetLocationOptions) {
  return useQuery<Location[]>(['locations'], () => locationApi.getLocations(), {
    onError(err) {
      console.log(err);
    },
    select(locations) {
      return options?.type ? locations.filter(l => l.type === options?.type) : locations;
    },
  });
}