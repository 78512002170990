import { createColumnHelper } from '@tanstack/react-table';
import { DAIU } from '../utils/types';

const columnHelper = createColumnHelper<Partial<DAIU>>();

const DAIUColumns = [
  columnHelper.accessor('date', {
    id: 'date',
    header: 'Date',
    cell: (row) => row.getValue(),
    enableGlobalFilter: true,
    enableColumnFilter: true,
    enableHiding: true
  }),
  columnHelper.accessor('monthYear', {
    id: 'monthYear',
    header: 'Month Year',
    cell: (row) => row.getValue(),
    enableGlobalFilter: true,
    enableColumnFilter: false,
    enableHiding: true
  }),
  columnHelper.accessor('equipmentNo', {
    id: 'equipmentNo',
    header: 'Equipment No.',
    cell: (row) => row.getValue(),
    enableGlobalFilter: true,
    enableColumnFilter: false,
    enableHiding: true
  }),
  columnHelper.accessor('categoryName', {
    id: 'categoryName',
    header: 'Product Family',
    cell: (row) => row.getValue(),
    enableGlobalFilter: true,
    enableColumnFilter: false,
    enableHiding: true
  }),
  columnHelper.accessor('make', {
    id: 'make',
    header: 'Make',
    cell: (row) => row.getValue(),
    enableGlobalFilter: true,
    enableColumnFilter: false,
    enableHiding: true
  }),
  columnHelper.accessor('model', {
    id: 'model',
    header: 'Model',
    cell: (row) => row.getValue(),
    enableGlobalFilter: true,
    enableColumnFilter: false,
    enableHiding: true
  }),
  columnHelper.accessor('capacity', {
    id: 'capacity',
    header: 'Capacity',
    cell: (row) => row.getValue(),
    enableGlobalFilter: true,
    enableColumnFilter: false,
    enableHiding: true
  }),
  columnHelper.accessor('name', {
    id: 'name',
    header: 'Project',
    cell: (row) => row.getValue(),
    enableGlobalFilter: true,
    enableColumnFilter: false,
    enableHiding: true
  }),
  columnHelper.accessor('dailyShiftHours', {
    id: 'dailyShiftHours',
    header: 'Shift Hours',
    cell: (row) => row.getValue(),
    enableGlobalFilter: true,
    enableColumnFilter: false,
    enableHiding: true
  }),
  columnHelper.accessor('downHours', {
    id: 'downHours',
    header: 'Down Hours',
    cell: (row) => row.getValue(),
    enableGlobalFilter: true,
    enableColumnFilter: false,
    enableHiding: true
  }),
  columnHelper.accessor('availableHours', {
    id: 'availableHours',
    header: 'Available Hours',
    cell: (row) => row.getValue(),
    enableGlobalFilter: true,
    enableColumnFilter: false,
    enableHiding: true
  }),
  columnHelper.accessor('operatingHour', {
    id: 'operatingHour',
    header: 'Operating Hour',
    cell: (row) => row.getValue(),
    enableGlobalFilter: true,
    enableColumnFilter: false,
    enableHiding: true
  }),
  columnHelper.accessor('idleTime', {
    id: 'idleTime',
    header: 'Idle Time',
    cell: (row) => row.getValue(),
    enableGlobalFilter: true,
    enableColumnFilter: false,
    enableHiding: true
  }),
  columnHelper.accessor('ownershipType', {
    id: 'ownershipType',
    header: 'Ownership Type',
    cell: (row) => row.getValue() === 'Rental' ? 'Rented' : row.getValue(),
    enableGlobalFilter: true,
    enableColumnFilter: false,
    enableHiding: true
  })
];

export default DAIUColumns;