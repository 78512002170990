import { Stack, TextField, Typography } from '@mui/material';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { TimePicker } from '@mui/x-date-pickers/TimePicker';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { appColors } from '../../../theme';

interface AppTimePickerProps {
  error?: boolean;
  helperText?: string;
  label: string;
  name: string;
  required?: boolean;
  value: Date | null;
  onChange: (value: any, keyboardInputValue?: string | undefined) => void;
}

const AppTimePicker = (props: AppTimePickerProps) => {
  const { error, helperText, value, onChange, label, required, name } = props;

  return (
    <LocalizationProvider dateAdapter={AdapterDateFns}>
      <TimePicker
        value={value}
        onChange={onChange}
        renderInput={(params) => (
          <Stack direction='row' gap={1.5} justifyContent='space-between' alignItems='center'>
            <Typography
              htmlFor={name}
              aria-required={required}
              color={appColors.formText}
              component='label'
              // mt={1.5}
              fontWeight='600'
              fontSize={15}
              width={130}
              align='right'
              whiteSpace='nowrap'
            >
              {label}&nbsp;
              {required && (
                <Typography component='small' color='error.main'>
                  *
                </Typography>
              )}
            </Typography>
            <Stack width={400}>
              <TextField
                {...params}
                id={name}
                error={error}
                helperText={helperText}
                size='small'
                sx={{
                  '& ~ fieldset': {
                    borderColor: appColors.primary,
                  },
                  '&:hover ~ fieldset': {
                    borderColor: appColors.primary,
                  },
                  '&.Mui-focused ~ fieldset': {
                    borderColor: appColors.primary,
                  },
                }}
                InputProps={{ sx: { fontSize: '14px' }, ...params.InputProps }}
              />
            </Stack>
          </Stack>
        )}
      />
    </LocalizationProvider>
  );
};

export default AppTimePicker;