import { IPR } from '../../modules/MyDashboardsPlusTasks/MyEquipmentRequests/utils/types';
import { apiClient, apiEndpoints } from '../apiClient';

export type GetManyReturnType = {
  status: string;
  data: IPR[];
};

const getPR = async () => {
  const response = await apiClient.get<GetManyReturnType>(
    `${apiEndpoints.epfErf}/pr`
  );
  return response.data.data;
};

const createPR = async (id: string) => {
  const response = await apiClient.patch(
    `${apiEndpoints.epfErf}/create-pr/${id}`
  );

  return response.data.data;
};

const closePR = async (id: string) => {
  const response = await apiClient.patch(
    `${apiEndpoints.epfErf}/close-pr/${id}`
  );

  return response.data.data;
};

const prApi = {
  getPR,
  createPR,
  closePR
};

export default prApi;