import { Box, Button, Checkbox, Divider, Paper, Stack, Typography } from '@mui/material';
import { SubmitHandler, useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { useNavigate, useParams } from 'react-router-dom';
import PageContainer from '../../../components/common/PageContainer';
import AppTextField from '../../../components/common/forms/AppTextField';
import { appColors, appStyles } from '../../../theme';
import { MODULES, PermissionTypes, RoleFields, roleInitialValues, roleSchema } from './roles-utils';
import { useSnackBar } from '../../../components/ToastProvider';
import { useCreateRole, useGetRoleById, useUpdateRole } from '../../../hooks/useRoles';
import { useEffect } from 'react';
import LoadingIndicator from '../../../components/common/LoadingIndicator';

const RoleForm = () => {
  const navigate = useNavigate();
  const { id } = useParams();
  const { showSnackBar } = useSnackBar();
  const currentRole = useGetRoleById();
  const updateRole = useUpdateRole();
  const createRole = useCreateRole();

  const {
    register,
    handleSubmit,
    setValue,
    watch,
    formState: { errors },
  } = useForm<RoleFields>({
    defaultValues: roleInitialValues,
    mode: 'onChange',
    resolver: yupResolver(roleSchema),
  });

  const permissionValues = watch('permissions');

  useEffect(() => {
    if (!currentRole.data) return;

    Object.keys(currentRole.data).forEach((key) => {
      const _key = key as keyof RoleFields;
      setValue(_key, currentRole.data[_key]);
    });
  }, [currentRole.data, setValue]);

  const onError = (err: typeof errors) => {
    console.log(err);
  };

  const onSubmit: SubmitHandler<RoleFields> = (role) => {
    if (currentRole.data) {
      updateRole.mutate(role);
    }
    if (!currentRole.data) {
      createRole.mutate(role);
    }
  };

  const backTo = () => navigate('/roles');

  if (id !== undefined) {
    if (currentRole.isError) {
      return <h1>Error</h1>;
    }
    if (updateRole.isLoading || currentRole.isLoading) {
      return <LoadingIndicator show />;
    }
  }
  if (createRole.isLoading) {
    return <LoadingIndicator show />;
  }

  return (
    <>
      <PageContainer
        title='User Management'
        subTitle='Roles & Permission'
        controls={
          <Stack direction='row' gap={2}>
            <Button sx={{ backgroundColor: appColors.lightGray, color: 'black' }} onClick={backTo}>
              Cancel
            </Button>
            <Button
              onClick={handleSubmit(onSubmit, onError)}
              disableElevation
              variant='contained'
              sx={{ textTransform: 'capitalize', borderRadius: 1.5 }}
            >
              Save
            </Button>
          </Stack>
        }
      >
        <Divider color='black' />
        <Stack
          component='form'
          height='100%'
          bgcolor='white'
          boxShadow={appStyles.shadow}
          borderRadius={1}
        >
          <Stack px={3} height='100%' overflow='auto' gap={1}>
            <Stack
              direction='column'
              gap={3}
              width={550}
              justifyContent='center'
              margin='auto'
              mt={1.5}
              mb={0}
            >
              <AppTextField
                required
                label='Role'
                placeholder='Role'
                {...register('name')}
                error={!!errors.name}
                helperText={errors.name?.message}
              />
              <AppTextField
                required
                label='Role Description'
                placeholder='Role description'
                multiline
                rows={4}
                {...register('description')}
                error={!!errors.description}
                helperText={errors.description?.message}
              />
            </Stack>
            {/* HERE */}
            <Paper elevation={1}>
              <Stack fontSize={13}>
                {/* TITLE HEADER */}
                <Box
                  display='grid'
                  gridTemplateColumns='200px repeat(12, 1fr)'
                  justifyContent='space-between'
                  bgcolor='primary.main'
                  color='white'
                  p={1}
                  border='0'
                  borderRadius='8px 8px 0 0'
                >
                  <Typography fontSize='inherit' textTransform='capitalize' fontWeight='600'>
                    Modules
                  </Typography>
                  {PermissionTypes.map((p) => (
                    <Typography
                      key={p}
                      fontSize='inherit'
                      textTransform='capitalize'
                      fontWeight='600'
                    >
                      {p.split('_').join(' ').toLowerCase()}
                    </Typography>
                  ))}
                </Box>
                <Stack sx={{ height: 'calc(100vh - 480px)' }} overflow='auto'>
                  {MODULES.map((m) => (
                    <>
                      {m.title === 'User Management' ? null : <Divider color='black' />}
                      <Stack
                        key={m.title}
                        sx={{
                          fontSize: 12,
                        }}
                      >
                        <Stack direction='row' gap={1} ml={0.5} p={1}>
                          {m.icon}
                          <Typography fontSize='inherit' fontWeight='600' mt={0.5}>
                            {m.title}
                          </Typography>
                        </Stack>

                        {m.modules.map((md) => (
                          <>
                            {/* {m.title === 'User Management' ? (
                              <Divider color='black' />
                            ) : null} */}
                            <Box
                              key={md}
                              display='grid'
                              gridTemplateColumns='200px repeat(12, 1fr)'
                              alignItems='center'
                              p={0.5}
                            >
                              <Typography fontSize='inherit' fontWeight='600' ml={15}>
                                {md}
                              </Typography>
                              {PermissionTypes.slice(0, m.isCommon ? 4 : undefined).map((p) => (
                                <Checkbox
                                  key={p}
                                  sx={{ mr: 'auto' }}
                                  onChange={(e, checked) => {
                                    const currentPermission = [md.toUpperCase(), p].join('::');

                                    const permissionArray = checked
                                      ? [...permissionValues, currentPermission]
                                      : permissionValues!.filter((pv) => pv !== currentPermission);
                                    setValue('permissions', permissionArray);
                                  }}
                                  checked={permissionValues.includes(
                                    [md.toUpperCase(), p].join('::')
                                  )}
                                />
                              ))}
                            </Box>
                          </>
                        ))}
                      </Stack>
                    </>
                  ))}
                </Stack>
              </Stack>
            </Paper>
          </Stack>
        </Stack>
      </PageContainer>
      {id !== undefined
        ? (updateRole?.isError || createRole?.isError) && showSnackBar('Network Error', 'error')
        : createRole?.isError && showSnackBar('Network Error', 'error')}
    </>
  );
};

export default RoleForm;
