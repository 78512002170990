import { Outlet } from 'react-router-dom';
import PageContainer from '../../../../components/common/PageContainer';
import EORRTable from './Table';

const EORRPage = () => {
  return (
    <>
      <PageContainer 
        title='My Dashboard + Tasks' 
        subTitle='My Equipment Requests'
        submoduleTitle='My EORRs'  
        showDate
      >
        <EORRTable />
      </PageContainer>
      <Outlet />
    </>
  );
}

export default EORRPage;