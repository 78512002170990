import { useState } from 'react';
import { DateRangePicker } from "materialui-daterange-picker";
import Stack from '@mui/material/Stack';
import AppTextField from '../common/forms/AppTextField';
import { SxProps } from "@mui/system";

interface DateRangeProps {
  id: string;
  startDate: string;
  endDate: string;
  disabled?: boolean;
  labelPosition?: "center" | "inherit" | "right" | "left" | "justify";
  onDateChange: (startDate: string, endDate: string) => void;
}

const style : SxProps = {
  '.dateRangePicker' : {
    position: 'absolute',
    zIndex: 3
  },
}

const AppDateRange = (props: DateRangeProps) => {
  const { id, startDate, endDate, onDateChange, disabled } = props
  const [openDateRange, setOpenDateRange] = useState<boolean>(false);

  const handleClick = () => {
    setOpenDateRange(openDateRange ? false : true);
  };

  return (
    <Stack direction="column" alignItems="flex-start" width="100%" sx={style}>
      <AppTextField
        id={id}
        type="daterange"
        labelWidth='20%'
        fieldWidth= '80%'
        placeholder="Select Here"
        disabled={disabled}
        value={(startDate === '' || endDate === '') ? '' : `${startDate} - ${endDate}`}
        onClick={handleClick}
      />
      <DateRangePicker
        open={openDateRange}
        toggle={handleClick}
        onChange={(range) => {
          onDateChange(range.startDate as unknown as string, range.endDate as unknown as string)
          setOpenDateRange(false)
        }}
        wrapperClassName={'dateRangePicker'}
      />
    </Stack>
  );
}

export default AppDateRange;