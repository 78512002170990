import { useMutation, useQuery } from '@tanstack/react-query';
import { AxiosError } from 'axios';
import { useNavigate, useParams } from 'react-router';
import { useSnackBar } from '../components/ToastProvider';
import { queryClient } from '../react-query/queryClient';
import rolesApi from '../services/roles';
import { Role } from '../modules/Users/Roles/roles-utils';

interface ApiError {
  status: number;
  message: string;
}

export function useGetRolesQuery() {
  return useQuery<Role[]>(['roles'], () => rolesApi.getRoles(), {
    onError(err) {
      console.log(err);
    },
  });
}

export function useGetRoleById() {
  const { id } = useParams<{ id: string }>();
  return useQuery([`roles-${id}`, id], () => rolesApi.getRoleById(id!));
}

export function useCreateRole() {
  const { showSnackBar } = useSnackBar();
  const navigate = useNavigate();
  return useMutation((newRoleData: Partial<Role>) => rolesApi.createRole(newRoleData), {
    onSuccess() {
      queryClient.invalidateQueries({ queryKey: ['roles'] }).then(() => {
        showSnackBar('Successfully Created Role', 'success');
        navigate('/roles', { replace: true });
      });
    },
    onError(err) {
      const errorData = (err as AxiosError<{ error: ApiError }>).response?.data.error;

      if (err && errorData) {
        showSnackBar(errorData.message.split(':').slice(1).join(' '), 'error');
      }
    },
  });
}

export function useUpdateRole() {
  const { showSnackBar } = useSnackBar();
  const { id } = useParams<{ id: string }>();
  const navigate = useNavigate();

  return useMutation((newRoleData: Partial<Role>) => rolesApi.updateRole(id!, newRoleData), {
    onSuccess() {
      queryClient.invalidateQueries({ queryKey: ['roles'] }).then(() => {
        showSnackBar('Successfully Updated Role', 'success');
        navigate('/roles', { replace: true });
      });
    },
    onError(err) {
      const errorData = (err as AxiosError<{ error: ApiError }>).response?.data.error;

      if (err && errorData) {
        showSnackBar(errorData.message.split(':').slice(1).join(' '), 'error');
      }
    },
  });
}

export function useDeleteRole() {
  const { showSnackBar } = useSnackBar();
  const navigate = useNavigate();

  return useMutation((id: string) => rolesApi.deleteRole(id), {
    onSuccess() {
      queryClient.invalidateQueries({ queryKey: ['roles'] }).then(() => {
        showSnackBar('Successfully Deleted Role', 'success');
        navigate('/roles', { replace: true });
      });
    },
    onError(err) {
      const errorData = (err as AxiosError<{ error: ApiError }>).response?.data.error;

      if (err && errorData) {
        showSnackBar(errorData.message.split(':').slice(1).join(' '), 'error');
      }
    },
  });
}
