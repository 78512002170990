import { Outlet } from 'react-router-dom';
import AccountsTable from './AccountsTable';

const AccountsList = () => {
  return (
    <>
      <AccountsTable />
      <Outlet />
    </>
  );
};

export default AccountsList;
