import { useEffect, useState } from 'react';
import { useNavigate, useParams, Outlet } from 'react-router-dom';
import { Controller, useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import Stack from '@mui/material/Stack';
import Button from '@mui/material/Button';
import Divider from '@mui/material/Divider';
import AppTextField from '../../../../components/common/forms/AppTextField';
import AppDatePicker from '../../../../components/common/forms/AppDatePicker';
import AppSelect from '../../../../components/common/forms/AppSelect';
import PageContainer from '../../../../components/common/PageContainer';
import LoadingIndicator from '../../../../components/common/LoadingIndicator';
import { SelectOptionType } from '../../../../components/common/forms/form-types';
import { EURSMRlInitialValues, EURSMRSchema } from '../utils/schema';
import { EURSMRFields } from '../utils/types';
import { useEURView } from '../hooks/useGetLocationOrParams';
import { useGetEquipmentsQuery } from '../../../../hooks/useEquipments';
import { useGetOperatorsQuery } from '../../../../hooks/useOperators';
import { useGetLocationsQuery } from '../../../../hooks/useLocations';
import { 
  useGetEURSMRById,
  useGetEURSMRByEquipmentAndOperator,
  useCreateEURSMR, 
  useUpdateEURSMR
} from '../../../../hooks/useEURSMR';
import { appColors, appStyles } from '../../../../theme';
import EURWarning from './EURWarning';
import moment from 'moment';

const SMRForm = () => {
  const [equipmentId, setEquipmentId] = useState<string>('');
  const [operatorId, setOperatorId] = useState<string>('');
  const [date, setEURDate] = useState<string | Date>('');
  const [openAlert, setAlertToOpen] = useState<boolean>(false);

  const { id } = useParams();
  const navigate = useNavigate();
  const isViewing = useEURView();
  const equipmentData = useGetEquipmentsQuery();
  const operatorData = useGetOperatorsQuery();
  const locationData = useGetLocationsQuery();
  const createEURSMR = useCreateEURSMR();
  const updateEURSMR = useUpdateEURSMR();
  const currentEUR = useGetEURSMRById();
  const { data } = useGetEURSMRByEquipmentAndOperator(equipmentId, operatorId, date);
  
  const equipmentOptions : SelectOptionType[] = 
    equipmentData?.data === undefined ? [] : equipmentData.data.map(e => {
                                              return { 
                                                id: e.id, 
                                                label: e.equipmentNo, 
                                                value: e.id 
                                              }
                                             });
  const operatorOptions : SelectOptionType[] = 
    operatorData?.data === undefined ? [] : operatorData.data.map(o => {
                                            return {
                                              id: o.id,
                                              label: o.operatorName,
                                              value: o.id
                                            }
                                          });
  const locationOptions : SelectOptionType[] = 
    locationData?.data === undefined ? [] : locationData.data.map(l => {
                                              return {
                                                id: l.id,
                                                label: l.name,
                                                value: l.id
                                              }
                                            });

  const {
    register,
    control,
    setValue,
    handleSubmit,
    watch,
    formState: { errors, isValid },
  } = useForm<EURSMRFields>({
    defaultValues: currentEUR.data! ? currentEUR.data : EURSMRlInitialValues,
    mode: 'onChange',
    resolver: yupResolver(EURSMRSchema),
  });

  useEffect(() => {
    if (!currentEUR.data) return;
    
    setValue("equipmentId", currentEUR.data?.equipmentId);
    setValue("locationId", currentEUR.data?.locationId);
    setValue("operatorId", currentEUR.data?.operatorId);
    setValue("date", currentEUR.data?.date);
    setValue("downHours", currentEUR.data?.downHours);
    setValue("startingReading", currentEUR.data?.startingReading);
    setValue("endingReading", currentEUR.data?.endingReading);

  }, [currentEUR.data, setValue]);

  useEffect(() => {
    const equipmentId = watch('equipmentId');
    const operatorId =  watch('operatorId');
    const date = watch('date');

    setEquipmentId(equipmentId);
    setOperatorId(operatorId);
    setEURDate(date);

  }, [watch, equipmentId, operatorData, date]);

  const onError = (err: typeof errors) => {
    console.error(err);
  };

  const onSubmit = (eurSMRData: EURSMRFields) => {
    if (data && data.length > 0) {
      const latestEURData = data[0].date;
      let tomorrow = new Date(latestEURData);
      tomorrow.setDate(tomorrow.getDate() + 1);

      const selectedDate = new Date(date).toDateString();
      const dateTomorrow = tomorrow.toDateString();
  
      if (new Date(selectedDate) > new Date(dateTomorrow)) {
        setAlertToOpen(true);

        return false;
      }
    }

    if (currentEUR.data) {
      updateEURSMR.mutate(eurSMRData);
    }
    else {
      createEURSMR.mutate(eurSMRData);
    }
  };

  const onProceed = (eurSMRData: EURSMRFields) => {
    if (currentEUR.data) {
      updateEURSMR.mutate(eurSMRData);
    }
    else {
      createEURSMR.mutate(eurSMRData);
    }
  };

  if (id !== undefined) {
    if (updateEURSMR.isLoading || currentEUR?.isLoading) {
      return <LoadingIndicator show />;
    }
  }

  if (createEURSMR.isLoading) {
    return <LoadingIndicator show />;
  }

  const backTo = () => navigate('/eur');
  
  return (
    <>
      <PageContainer
        title='My Dashboard + Tasks'
        subTitle='My EURs'
        controls={
          <Stack direction='row' gap={2}>
            <Button sx={{ backgroundColor: appColors.lightGray, color: 'black' }} onClick={backTo}>
              Cancel
            </Button>
            <Button
              onClick={handleSubmit(onSubmit, onError)}
              disabled={!isValid || isViewing}
              disableElevation
              variant='contained'
              sx={{ textTransform: 'capitalize', borderRadius: 1.5 }}
            >
              Save
            </Button>
          </Stack>
        }
      >
        <Divider color='black' />
        <EURWarning 
          openAlert={openAlert} 
          setAlertToOpen={setAlertToOpen}
          handleSubmit={handleSubmit(onProceed)}
        />
        <Stack
          component='form'
          height='100%'
          bgcolor='white'
          py={3}
          boxShadow={appStyles.shadow}
          borderRadius={1}
        >
          <Stack px={3} height='100%' overflow='auto' gap={1}>
          <Stack direction="row" justifyContent="space-between" width="100%" mt={3}>
            <Stack direction="column" gap={1.5} width="45%">
              <Controller
                control={control}
                name='equipmentId'
                render={({ field }) => (
                  <AppSelect
                    label="Equipment No."
                    labelAlignment="left"
                    labelWidth='20%'
                    fieldWidth= '80%'
                    fontWeight={400}
                    placeholder="Select here"
                    disabled={isViewing}
                    error={!!errors.equipmentId}
                    helperText={errors.equipmentId?.message}
                    options={equipmentOptions}
                    {...register('equipmentId')}
                    {...field}
                  />
                )}
              />
              <Controller
                control={control}
                name='operatorId'
                render={({ field }) => (
                  <AppSelect
                    label="Operator"
                    labelAlignment="left"
                    labelWidth='20%'
                    fieldWidth= '80%'
                    fontWeight={400}
                    placeholder="Select here"
                    disabled={isViewing}
                    options={operatorOptions}
                    error={!!errors.operatorId}
                    helperText={errors.operatorId?.message}
                    {...register('operatorId')}
                    {...field}
                  />
                )}
              />
              <Controller
                control={control}
                name='locationId'
                render={({ field }) => (
                  <AppSelect
                    label="Location"
                    labelAlignment="left"
                    labelWidth='20%'
                    fieldWidth= '80%'
                    fontWeight={400}
                    placeholder="Select here"
                    disabled={isViewing}
                    options={locationOptions}
                    error={!!errors.locationId}
                    helperText={errors.locationId?.message}
                    {...register('locationId')}
                    {...field}
                  />
                )}
              />
              <Controller
                control={control}
                name='date'
                render={({ field: { value, onChange } }) => (
                  <AppDatePicker
                    name='date'
                    value={value}
                    onChange={onChange}
                    label='Date'
                    labelAlignment="left"
                    labelWidth='20%'
                    fieldWidth= '80%'
                    fontWeight={400}
                    disabled={isViewing}
                    error={!!errors.date}
                    helperText={errors.date?.message}
                  />
                )}
              />
              <AppTextField
                label="Down Hours"
                labelAlignment="left"
                labelWidth='20%'
                fieldWidth= '80%'
                fontWeight={400}
                placeholder="00"
                disabled={isViewing}
                error={!!errors.downHours}
                helperText={errors.downHours?.message}
                {...register('downHours')}
              />
            </Stack>
            <Stack direction="column" gap={1.5} width="45%">
              <AppTextField
                label="Starting SMR/Mileage"
                labelAlignment="left"
                labelWidth='33%'
                fieldWidth= '67%'
                fontWeight={400}
                placeholder="Type Here"
                disabled={isViewing}
                error={!!errors.startingReading}
                helperText={errors.startingReading?.message}
                {...register('startingReading')}
              />
              <AppTextField
                label="Ending SMR/Mileage"
                labelAlignment="left"
                labelWidth='33%'
                fieldWidth= '67%'
                fontWeight={400}
                placeholder="Type Here"
                disabled={isViewing}
                error={!!errors.endingReading}
                helperText={errors.endingReading?.message}
                {...register('endingReading')}
              />
            </Stack>
          </Stack>
          </Stack>
        </Stack>
      </PageContainer>
      <Outlet />
    </>
  )
}

export default SMRForm;