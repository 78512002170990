import jwtDecode, { InvalidTokenError } from 'jwt-decode';
import {
  createContext,
  ReactNode,
  useContext,
  useEffect,
  useMemo,
  useState,
} from 'react';
import { Role } from '../modules/Users/Roles/roles-utils';
import authApi, { AuthUser } from '../services/auth';
import rolesApi from '../services/roles';

interface AuthProviderValue {
  authUser: AuthUser | null;
  currentUserRole: Role | null;
  logout: () => void;
  setAuthUser: React.Dispatch<React.SetStateAction<AuthUser | null>>;
  setCurrentUserRole: React.Dispatch<React.SetStateAction<Role | null>>;

  isLoading: boolean;
}

const AuthContext = createContext<AuthProviderValue | undefined>(undefined);

AuthContext.displayName = 'AuthContext';

export default function AuthProvider({ children }: { children: ReactNode }) {
  const storedToken = localStorage.getItem('accessToken');
  const token = storedToken ? JSON.parse(storedToken) : null;

  const [authUser, setAuthUser] = useState<AuthUser | null>(null);
  const [currentUserRole, setCurrentUserRole] = useState<Role | null>(null);
  const [isLoading, setIsLoading] = useState(true);

  const authUserId = authUser?.roleId!;
  useEffect(() => {
    if (!token) return;

    const getMe = async () => {
      // decode token
      try {
        setIsLoading(true);
        const decoded: any = jwtDecode(token ?? '');

        const currentUser = await authApi.getLoggedInUser(decoded.id, token);
        setAuthUser(currentUser);
        const userRole = await rolesApi.getRoleById(authUserId);
        setCurrentUserRole(userRole);
      } catch (error) {
        if (error instanceof InvalidTokenError) {
          setAuthUser(null);
          return;
        }
      } finally {
        setIsLoading(false);
      }
    };

    getMe();
  }, [authUserId, token]);

  const logout = async () => {
    await authApi.logout();
    setAuthUser(null);
  };

  const value = useMemo(() => {
    return {
      currentUserRole,
      authUser,
      logout,
      setAuthUser,
      setCurrentUserRole,
      isLoading,
    };
  }, [authUser, currentUserRole, isLoading]);

  return <AuthContext.Provider value={value}>{children}</AuthContext.Provider>;
}

export function useAuth() {
  return useContext(AuthContext);
}
